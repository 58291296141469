import {i18n} from 'i18n';
import {useRouter} from 'next/router';
import Script from 'next/script';
import {useEffect} from 'react';
import ReactGA from 'react-ga4';

import {isInternalEmail} from '~utils/miscUtils';
import {useJuneAnalytics} from '~hooks/juneAnalytics.hooks';
import {ReduxStateUser} from '~redux/types/user';
import {selectUser} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

const includeTelemetry = process.env.DEPLOYMENT_ENV === 'production';

function getLaunchDarklyClientID() {
  switch (process.env.DEPLOYMENT_ENV) {
    case 'production':
      return process.env.LAUNCHDARKLY_CLIENT_ID_PROD;
    case 'staging':
      return process.env.LAUNCHDARKLY_CLIENT_ID_STAGING;
    case 'development':
    default:
      return process.env.LAUNCHDARKLY_CLIENT_ID_DEV;
  }
}

export const launchDarklyClientID = getLaunchDarklyClientID();

function getGoogleAnalyticsMeasurementID() {
  if (!includeTelemetry) {
    return null;
  }

  switch (process.env.DEPLOYMENT_ENV) {
    case 'production':
      return process.env.GA_MEASUREMENT_ID_PROD;
    case 'staging':
      return process.env.GA_MEASUREMENT_ID_STAGING;
    case 'development':
      return process.env.GA_MEASUREMENT_ID_DEV;
    default:
      return null;
  }
}

const googleAnalyticsMeasurementID = getGoogleAnalyticsMeasurementID();

const setupGoogleAnalytics = (user?: ReduxStateUser) => {
  if (!user) return;

  const customer = user.customer;
  const projectID = user.currentProjectId;
  const userID = user.userId;
  const userEmail = user.email;

  if (googleAnalyticsMeasurementID && !isInternalEmail(userEmail)) {
    ReactGA.initialize([{trackingId: googleAnalyticsMeasurementID, gtagOptions: {customer, projectID, userID}}]);
  }
};

const HJID = Number(process.env.HOTJAR_ID);
const HJSV = Number(process.env.HOTJAR_SNIPPET_VERSION);

const hotjarScript = `
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:${HJID},hjsv:${HJSV}};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `;

const googleTagManagerScript = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${process.env.GTM_ID}');
`;

export function ThirdPartyScipts() {
  const user = useAppSelector(selectUser);
  const juneAnalytics = useJuneAnalytics();
  const router = useRouter();

  // initialize June Analytics
  useEffect(() => {
    if (!juneAnalytics || !user) return;

    juneAnalytics.identify(user.userId, {
      email: user.email,
      name: user.name,
      customer: user.email.endsWith('maddox.ai') ? `${user.customer}_internal` : user.customer,
      project: user.currentProjectId,
      language: i18n.language,
    });

    if (user.email.endsWith('maddox.ai')) {
      juneAnalytics.group(`${user.customer}_internal`, {
        name: `${user.customer}_internal`,
        group_type: 'group',
      });
    } else {
      juneAnalytics.group(user.customer, {
        name: user.customer,
        group_type: 'group',
      });
    }
  }, [juneAnalytics, user]);

  // Track page views with June Analytics
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (juneAnalytics) juneAnalytics.page(url);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
    // don't include router as it's not stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [juneAnalytics]);

  useEffect(() => {
    if (googleAnalyticsMeasurementID) setupGoogleAnalytics(user);
  }, [user]);

  return includeTelemetry ? (
    <>
      <Script id='hotjar-script' strategy='afterInteractive'>
        {hotjarScript}
      </Script>
      <Script id='google-tag-manager' strategy='afterInteractive'>
        {googleTagManagerScript}
      </Script>
    </>
  ) : null;
}
