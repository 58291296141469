import {AnyAction} from 'redux';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';

import * as filterApi from '~api/filters';
import {ReduxState} from '~redux/index';

import {selectCurrentProjectId} from '../reducers/userReducer';
import {API_FILTERS_ERROR, SET_API_FILTERS} from '../types';
import {ReduxStateApplicableAPIFilters} from '../types/filters';
import {setError} from './errorActions';

export interface APIFiltersAction extends AnyAction {
  payload: Partial<ReduxStateApplicableAPIFilters>;
}

export const setFilters = (payload: Partial<ReduxStateApplicableAPIFilters>): APIFiltersAction => ({
  type: SET_API_FILTERS,
  payload,
});

export const fetchFilters =
  (): ThunkAction<Promise<void>, ReduxState, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
    try {
      const projectID = selectCurrentProjectId(getState());
      dispatch(setFilters(await filterApi.getFilters(projectID)));
    } catch (error: any) {
      dispatch(setError(API_FILTERS_ERROR, error, API_FILTERS_ERROR));
    }
  };
