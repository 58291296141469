import {Box} from '@mui/material';

import {isProductionEnv} from '~utils/miscUtils';

export function BuildEnvInfo() {
  if (isProductionEnv()) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        fontSize: '10px',
        backgroundColor: 'background.default',
        lineHeight: 1,
        padding: 0.5,
        fontFamily: 'monospace',
      }}
    >
      Env: {process.env.CI_ENVIRONMENT_NAME || process.env.AUTH_API}
    </Box>
  );
}
