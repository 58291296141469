import {CheckCircle, ErrorOutline, Info, WarningAmber} from '@mui/icons-material';
import {Alert, AlertProps, Button, Slide, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'i18n';
import {ReactNode, useMemo} from 'react';
import toast, {Toast, ToastPosition} from 'react-hot-toast';
import {useLocalStorage} from 'usehooks-ts';

import {stringToHash} from '~utils/miscUtils';
import {selectUserEmail} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

import {hotKeys} from '~components/images/annotator/utils/hotKeys';
import {DONT_SHOW_AGAIN_KEYS} from 'src/constants/constants';
import {useHotkeysWhenDialogsAreClosed} from 'src/hooks/misc.hooks';

interface DontShowAgainObject {
  user: string;
  key: string;
}

interface ShowNotificationProps {
  title?: string;
  message: string | ReactNode;
  severity?: AlertProps['severity'];
  position?: ToastPosition;
  duration?: number;
  dontShowAgain?: string;
  id?: string;
  sx?: AlertProps['sx'];
  elevation?: number;
  direction?: 'left' | 'right' | 'up' | 'down';
}

interface NotificationProps extends ShowNotificationProps {
  onClose?: () => void;
  toastData?: Toast;
}

export function Notification({
  title,
  message,
  severity = 'info',
  dontShowAgain,
  onClose,
  toastData,
  sx,
  elevation,
  direction,
}: NotificationProps): JSX.Element | null {
  const {t} = useTranslation('common');
  const theme = useTheme();

  const [localStorageDSA, setLocalStorageDSA] = useLocalStorage<DontShowAgainObject[]>(DONT_SHOW_AGAIN_KEYS, []);
  const userEmail = useAppSelector(selectUserEmail);

  useHotkeysWhenDialogsAreClosed(hotKeys.closePopups.keyCombination, () => onClose && onClose());

  const emailHash = useMemo(() => {
    if (!userEmail || !dontShowAgain) {
      return undefined;
    }
    return stringToHash(userEmail);
  }, [dontShowAgain, userEmail]);

  const highlightColor = () => {
    switch (severity) {
      case 'success':
        return 'success.main';
      case 'info':
        return 'primary.light';
      case 'error':
        return 'error.light';
      case 'warning':
        return 'warning.light';
    }
  };

  const handleDontShowAgain = () => {
    if (dontShowAgain && emailHash) {
      if (
        !localStorageDSA.some(
          (object: DontShowAgainObject) => object.user === emailHash && object.key === dontShowAgain,
        )
      ) {
        if (toastData) toast.dismiss(toastData.id);
        setTimeout(() => {
          // wait for the dismiss transition to be done, then remember dontShowAgain state in localStorage
          setLocalStorageDSA([...localStorageDSA, {user: emailHash, key: dontShowAgain}]);
        }, theme.transitions.duration.leavingScreen);
      }
    }
  };

  const shouldShowNotification = () => {
    if (dontShowAgain && emailHash) {
      return !localStorageDSA.some(
        (object: DontShowAgainObject) => object.user === emailHash && object.key === dontShowAgain,
      );
    } else {
      return true;
    }
  };

  if (!shouldShowNotification()) {
    return null;
  }

  const getSlideDirection = () => {
    if (!toastData) return undefined;
    if (direction) return direction;
    return toastData.visible ? 'down' : 'left';
  };

  return (
    <Slide in={toastData ? toastData.visible : true} direction={getSlideDirection()} mountOnEnter unmountOnExit>
      <Alert
        data-testid='notification-toast'
        sx={{width: '478px', overflow: 'hidden', wordBreak: 'break-word', ...sx}}
        onClose={onClose}
        severity={severity}
        elevation={elevation ?? 6}
        variant='filled'
        closeText={t('close')}
        iconMapping={{
          success: <CheckCircle sx={{color: highlightColor}} />,
          info: <Info sx={{color: highlightColor}} />,
          error: <ErrorOutline sx={{color: highlightColor}} />,
          warning: <WarningAmber sx={{color: highlightColor}} />,
        }}
      >
        {title && (
          <Typography variant='h3' sx={{color: 'maddox.black80', marginBottom: 1}}>
            {title}
          </Typography>
        )}
        <Typography variant='body1' sx={{color: 'maddox.black80'}}>
          {message}
        </Typography>
        {dontShowAgain && dontShowAgain.length > 0 && (
          <Button
            onClick={handleDontShowAgain}
            sx={{
              color: highlightColor,
              padding: 0,
              marginTop: 2,
              '&:hover': {backgroundColor: 'transparent', textDecoration: 'underline'},
            }}
          >
            {t('dontShowAgain')}
          </Button>
        )}
      </Alert>
    </Slide>
  );
}

/**
 * @returns An ID for the shown notification
 */
export const showNotification = ({
  title,
  message,
  severity,
  position,
  duration,
  direction,
  dontShowAgain,
  id,
  sx,
}: ShowNotificationProps): string => {
  if (!duration) {
    if (dontShowAgain !== undefined) {
      duration = 30000;
    } else if (severity === 'success' || severity === undefined) {
      duration = 3000;
    } else {
      duration = 5000;
    }
  }

  return toast.custom(
    (t) => (
      <Notification
        title={title}
        message={message}
        severity={severity}
        dontShowAgain={dontShowAgain}
        onClose={() => toast.dismiss(t.id)}
        toastData={t}
        sx={sx}
        direction={direction}
      />
    ),
    {
      id,
      position: position || 'top-right',
      duration,
    },
  );
};

export const hideNotification = (id: string) => {
  toast.dismiss(id);
};
