import React, {useEffect, useMemo, useState} from 'react';

import {NestedClassList} from '~components/common/toolbar/selects/class-select/NestedClassList';
import {updateItemStates} from '~components/common/toolbar/toolbar.utils';

export type CheckboxLabelItem = {
  id: string;
  alias?: string;
  parentId: string | undefined;
  isLeaf: boolean;
  modelLabel?: string;
};

export enum CheckboxState {
  UNCHECKED,
  CHECKED,
  INDETERMINATE,
}

export type ItemState = {
  id: string;
  state: CheckboxState;
  isLeaf: boolean;
};

interface NestedClassListParentProps {
  items: CheckboxLabelItem[];
  checkedIds: string[];
  onChange: (checkedIds: string[]) => void;
  /**
   * If true, whenever the tree would result in a fully unchecked state, it instead re-checks all items.
   */
  uncheckingAllResetsToAllChecked?: boolean;
}

function mapItemsToItemStates(items: CheckboxLabelItem[]): ItemState[] {
  return items.map((item) => ({
    id: item.id,
    state: CheckboxState.UNCHECKED,
    isLeaf: item.isLeaf,
  }));
}

/**
 * Based on https://betterprogramming.pub/tree-lists-with-indeterminate-checkboxes-in-react-31445784ac90
 */
export function NestedClassListParent({
  items,
  checkedIds,
  onChange,
  uncheckingAllResetsToAllChecked,
}: NestedClassListParentProps) {
  const rootNodeId = useMemo(() => items.find((item) => item.parentId === undefined)?.id, [items]);
  const [itemStates, setItemStates] = useState<ItemState[]>(mapItemsToItemStates(items));

  useEffect(() => {
    setItemStates(updateItemStates(mapItemsToItemStates(items), items, checkedIds));
  }, [items, checkedIds]);

  const handleClick = (event: React.MouseEvent, id: string) => {
    // Stop propagation to prevent menu from closing when a checkbox item was clicked
    event.stopPropagation();

    let newState = updateItemStates(itemStates, items, [id]);

    if (uncheckingAllResetsToAllChecked) {
      const isRootUnchecked = newState.find((item) => item.id === rootNodeId)?.state === CheckboxState.UNCHECKED;
      if (rootNodeId !== undefined && isRootUnchecked) {
        newState = updateItemStates(newState, items, [rootNodeId]);
      }
    }
    onChange(
      newState
        .filter((itemState) => itemState.isLeaf && itemState.state === CheckboxState.CHECKED)
        .map((itemState) => itemState.id),
    );
  };

  return <NestedClassList items={items} itemStates={itemStates} onClick={handleClick} />;
}
