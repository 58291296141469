import {byOldestClassificationOrAnnotationFirst, toConsistentDecimal} from '~utils/miscUtils';
import {
  AnnotationLabel,
  Coordinate,
  ImageData,
  ImageDataAnnotation,
  ImageDataWithAnnotationHistory,
  PolygonAnnotationLabel,
  PolygonOrClassAnnotationLabel,
} from '~redux/types/images';

import {isClassificationLabel} from '~components/images/utils/classification.utils';

/**
 * Returns the latest machine prediction for a given image and model. If a model ID is passed, the return value will be
 * an array with a single machine prediction. If no model is passed, the return value will be an array with all machine predictions.
 */
export function getLatestMachinePredictions({image, modelId}: {image?: ImageData | null; modelId?: string} = {}):
  | ImageDataAnnotation[]
  | null {
  if (!image) {
    return null;
  }

  // if a model is passed, we can simply use the latest machine prediction for that model
  if (modelId) {
    const latestAnnotationForModel = image.annotations[modelId]?.machinePrediction;
    return latestAnnotationForModel ? [latestAnnotationForModel] : null;
  }

  // otherwise we need to retrieve all latest machine predictions and sort them
  const sortedMachinePredictions = Object.values(image.annotations)
    .map((annotation) => annotation.machinePrediction)
    .filter((annotation): annotation is ImageDataAnnotation => !!annotation)
    .sort(byOldestClassificationOrAnnotationFirst);

  return sortedMachinePredictions;
}

/**
 * Returns the latest human annotation for a given image and model. If a model ID is passed, the return value will be
 * an array with a single human annotation. If no model is passed, the return value will be an array with all human annotations.
 */
export function getLatestHumanAnnotations({image, modelId}: {image?: ImageData | null; modelId?: string} = {}):
  | ImageDataAnnotation[]
  | null {
  if (!image) {
    return null;
  }

  // if a model is passed, we can simply use the latest annotation for that model
  if (modelId) {
    const latestAnnotationForModel = image.annotations[modelId]?.latestHumanAnnotation;
    return latestAnnotationForModel ? [latestAnnotationForModel] : null;
  }

  // otherwise we need to retrieve all latest human annotations and sort them
  const sortedHumanAnnotations = Object.values(image.annotations)
    .map((annotation) => annotation.latestHumanAnnotation)
    .filter((annotation): annotation is ImageDataAnnotation => !!annotation)
    .sort(byOldestClassificationOrAnnotationFirst);

  return sortedHumanAnnotations;
}

export function getLatestHumanAnnotationsForLabel({
  image,
  modelId,
  labelId,
}: {
  image?: ImageData | null;
  modelId?: string;
  labelId?: string;
} = {}) {
  const annotations = getLatestHumanAnnotations({image, modelId});
  if (!annotations || !labelId) {
    return null;
  }

  const annotationsForLabel = annotations.filter((annotation) =>
    annotation.labels.find((label) => label.id === labelId),
  );

  if (annotationsForLabel.length === 0) {
    return null;
  }
  return annotationsForLabel;
}

/**
 * Returns the latest human annotations for a given user (identified by their email), image and model.
 */
export function getLatestAnnotationsForUser({
  userEmail,
  image,
  modelId,
}: {
  userEmail: string;
  image: ImageDataWithAnnotationHistory | null;
  modelId?: string;
}): ImageDataAnnotation[] | null {
  if (!image) {
    return null;
  }

  let annotations: ImageDataAnnotation[] = [];

  // Note: in any case when checking for the latest annotation of the given user, we need to check the latestHumanAnnotation first,
  // before looking at the annotation history. This is because when saving an annotation, the latestHumanAnnotations are updated but
  // the annotation history is not. This means that the latestHumanAnnotation might be newer than the latest annotation in the history.

  const latestAnnotationForModel = modelId ? image.annotations[modelId]?.latestHumanAnnotation : null;
  if (modelId && latestAnnotationForModel && isAnnotatedByUser(latestAnnotationForModel, userEmail)) {
    // Check the latestHumanAnnotation for the given model first
    annotations.push(latestAnnotationForModel);
  } else if (modelId && image.humanAnnotationHistory[modelId] !== null) {
    // Check the annotation history for the given model and user
    const latestAnnotationForModelAndUser = image.humanAnnotationHistory[modelId]!.find((annotation) =>
      isAnnotatedByUser(annotation, userEmail),
    );
    if (!latestAnnotationForModelAndUser) {
      return null;
    }
    annotations = [latestAnnotationForModelAndUser];
  } else {
    // In case no model ID is passed, we need to check all models for their latest annotation for the given user
    for (const [modelId, annotationHistory] of Object.entries(image.humanAnnotationHistory)) {
      // check the latestHumanAnnotation first
      const latestAnnotationForModel = image.annotations[modelId]?.latestHumanAnnotation;
      if (latestAnnotationForModel && isAnnotatedByUser(latestAnnotationForModel, userEmail)) {
        annotations.push(latestAnnotationForModel);
      } else if (annotationHistory !== null) {
        // Check the annotation history second
        const latestAnnotationForModelAndUser = annotationHistory.find((annotation) =>
          isAnnotatedByUser(annotation, userEmail),
        );
        if (latestAnnotationForModelAndUser) {
          annotations.push(latestAnnotationForModelAndUser);
        }
      }
    }
  }

  if (annotations.length === 0) {
    return null;
  }

  return annotations;
}

export function isAnnotatedByUser(annotation: ImageDataAnnotation, userEmail: string): boolean {
  return annotation.user.toLowerCase() === userEmail.toLowerCase();
}

/**
 * Returns the latest machine prediction for a given image and model, but only if it is not congruent with the latest human
 * annotation.
 *
 * A polygon is considered congruent to another polygon if all of its coordinates are the same.
 */
export function getLatestMachinePredictionWithoutUserCongruentPolygons(
  image: ImageData | null,
  modelId?: string,
): ImageDataAnnotation | null {
  if (!image) {
    return null;
  }

  const latestMachinePredictions = getLatestMachinePredictions({image, modelId});
  if (!latestMachinePredictions || latestMachinePredictions.length === 0) {
    return null;
  }

  const latestMachinePrediction = latestMachinePredictions[0];
  const latestHumanAnnotations = getLatestHumanAnnotations({image, modelId});
  const latestAnnotation = latestHumanAnnotations?.at(-1);
  const latestHumanCoordinates: {x: number[]; y: number[]} = toFlatCoordinates(latestAnnotation?.labels);

  const nonCongruentLabels: AnnotationLabel[] = latestMachinePrediction.labels.filter((label: AnnotationLabel) => {
    if (isClassificationLabel(label)) {
      return true;
    }
    return !(label as PolygonAnnotationLabel).coordinates.every((coordinate: Coordinate) => {
      return (
        latestHumanCoordinates?.x.includes(toConsistentDecimal(coordinate.x)) &&
        latestHumanCoordinates?.y.includes(toConsistentDecimal(coordinate.y))
      );
    });
  });
  return {...latestMachinePrediction, labels: nonCongruentLabels};
}

function toFlatCoordinates(labels: AnnotationLabel[] | undefined): {x: number[]; y: number[]} {
  return (labels || []).reduce(
    (accumulator: any, label: AnnotationLabel) => ({
      x: [
        ...accumulator.x,
        ...(label.coordinates || []).map((coordinate: Coordinate) => toConsistentDecimal(coordinate.x)),
      ],
      y: [
        ...accumulator.y,
        ...(label.coordinates || []).map((coordinate: Coordinate) => toConsistentDecimal(coordinate.y)),
      ],
    }),
    {x: [], y: []},
  );
}

export function isPolygonAnnotationLabel(label: PolygonOrClassAnnotationLabel): label is PolygonAnnotationLabel {
  return (
    ('type' in label && label.type === 'polygon') ||
    ('coordinates' in label && label.coordinates && label.coordinates.length >= 3)
  );
}
