import {AnyAbility, defineAbility} from '@casl/ability';
import {createContextualCan} from '@casl/react';
import {createContext, useContext} from 'react';

import {
  Abilities,
  Ability,
  ANNOTATOR_ABILITIES,
  DEFAULT_ABILITIES,
  TRAINER_ABILITIES,
  VIEWER_ABILITIES,
} from 'src/constants/abilities';

export enum UserRole {
  VIEWER = 'viewer',
  ANNOTATOR = 'annotator',
  TRAINER = 'trainer',
  ADMIN = 'admin',
}

export enum UserStatus {
  ACTIVE = 'active',
  INVITED = 'invited',
  SUSPENDED = 'suspended',
}

function formatAbilities(abilities: Abilities): Ability[] {
  if (!abilities) {
    return [];
  }

  return Object.values(abilities);
}

// CASL works on a "Give permissions, don't take them away" rule.
// This means that, rather than defining what a user can't do, we define what they can do
// and then remove permissions as necessary.
export const ability = defineAbility((can) => {
  Object.values(DEFAULT_ABILITIES).forEach((ability) => {
    can(ability.action, ability.subject);
  });
});

export const getAbility = () => {
  return ability;
};

export const updateAbility = (userRole: string) => {
  const userRoles = [userRole];

  let newRules = {...DEFAULT_ABILITIES};

  if (userRoles.includes('annotator')) {
    newRules = {
      ...newRules,
      ...ANNOTATOR_ABILITIES,
    };
  }

  if (userRoles.includes('viewer')) {
    newRules = {
      ...newRules,
      ...VIEWER_ABILITIES,
    };
  }

  if (userRoles.includes('trainer')) {
    newRules = {
      ...newRules,
      ...TRAINER_ABILITIES,
    };
  }

  ability.update(formatAbilities(newRules));
};

export const AbilitiesContext = createContext<AnyAbility>({} as AnyAbility);

export function useAbilities(): AnyAbility {
  const abilitiesContext = useContext(AbilitiesContext);
  return abilitiesContext as AnyAbility;
}

export const Can = createContextualCan(AbilitiesContext.Consumer);
