import {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {ImageData, LabelInstance} from '~redux/types/images';

export function useCheckedItems<T extends ImageData | LabelInstance>(
  currentPageItemsData: T[] | undefined,
  imagesTotalCount: number | undefined,
  bustCache: MutableRefObject<boolean>,
): [
  T[],
  {
    isChecked: (id: string) => boolean;
    setChecked: (item: T) => void;
    setAllChecked: (checked: boolean) => void;
    allChecked: boolean;
    setMultipleChecked: (imageList: T[]) => void;
    clearChecked: () => void;
    hasOnlyImagesChecked: boolean;
    resetCache: () => void;
    getItemsCount: () => number;
    /*
     * relevantImages is a list of image/labels that are manually selected by the user
     * if allChecked is true, then this is the list of images that are unselected by the user
     */
    relevantImages: T[];
  },
  bustCache: MutableRefObject<boolean>,
] {
  const [itemCache, setItemCache] = useState<CheckedItemsCache>({});
  const [allItemsChecked, setAllItemsChecked] = useState(false);
  const itemsDataRef = useRef<T[]>([]);
  const checkedItems = itemsDataRef.current.filter((item) => itemCache[item.id]?.checked);

  const [relevantImages, setRelevantImages] = useState<T[]>([]);

  useEffect(() => {
    if (!currentPageItemsData || !currentPageItemsData.length) return;
    if (bustCache.current) {
      bustCache.current = false;
      const newCache = currentPageItemsData.reduce((acc, item) => {
        const key = 'key' in item ? item.key : item.imageId;
        acc[item.id] = {checked: false, hasImage: Boolean(key)};
        return acc;
      }, {} as CheckedItemsCache);
      setItemCache(newCache);
      setAllChecked(false);
      itemsDataRef.current = [...currentPageItemsData];
      return;
    }

    const dataMap = new Map(itemsDataRef.current.map((item) => [item.id, item]));
    currentPageItemsData.forEach((item) => {
      dataMap.set(item.id, {...dataMap.get(item.id), ...item});
    });
    itemsDataRef.current = Array.from(dataMap.values());

    // Update cache with new or updated items while maintaining existing checked states
    const updatedCache = itemsDataRef.current.reduce((cache, item) => {
      const key = 'key' in item ? item.key : item.imageId;
      cache[item.id] = itemCache[item.id]
        ? {...itemCache[item.id], hasImage: Boolean(key)}
        : {checked: false, hasImage: Boolean(key)};
      return cache;
    }, {} as CheckedItemsCache);
    setItemCache(updatedCache);

    // we do not want to update again itemCache if it itself changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bustCache.current, currentPageItemsData]);

  const isChecked = useCallback(
    (id: string) => {
      if (allItemsChecked) {
        return !relevantImages.some((item) => item.id === id);
      } else {
        return relevantImages.some((item) => item.id === id);
      }
    },
    [relevantImages, allItemsChecked],
  );

  const setAllChecked = useCallback((checked: boolean) => {
    setAllItemsChecked(checked);
    setRelevantImages([]);
  }, []);

  const setChecked = useCallback(
    (item: T) => {
      const alreadyInList = relevantImages.some((itemInList) => itemInList.id === item.id);
      const newRelevantImages = !alreadyInList
        ? [...relevantImages, item]
        : relevantImages.filter((itemInList) => itemInList.id !== item.id);

      if (newRelevantImages.length === imagesTotalCount) {
        setAllChecked(!allItemsChecked);
      } else {
        setRelevantImages(newRelevantImages);
      }
    },
    [imagesTotalCount, relevantImages, setAllChecked, allItemsChecked],
  );

  const setMultipleChecked = useCallback(
    (imageList: T[]) => {
      const imageIds = imageList.map((image) => image.id);
      let newRelevantImages = [];

      const areAllInList = imageIds.every((id) => relevantImages.some((item) => item.id === id));

      if (areAllInList) {
        newRelevantImages = relevantImages.filter((item) => !imageIds.includes(item.id));
      } else {
        newRelevantImages = relevantImages.concat(
          imageList.filter((item2) => !relevantImages.some((item1) => item1.id === item2.id)),
        );
      }

      if (newRelevantImages.length === imagesTotalCount) {
        setAllChecked(true);
      } else {
        setRelevantImages(newRelevantImages);
      }
    },
    [relevantImages, imagesTotalCount, setAllChecked],
  );

  const clearChecked = useCallback(() => {
    setItemCache((prev) => {
      const newCache = {...prev};
      Object.keys(newCache).forEach((key) => {
        newCache[key] = {...newCache[key], checked: false};
      });
      return newCache;
    });
    setRelevantImages([]);
  }, []);

  const getItemsCount = useCallback(() => {
    if (!imagesTotalCount) return 0;
    if (!allItemsChecked) {
      return relevantImages.length;
    } else {
      return imagesTotalCount - relevantImages.length;
    }
  }, [relevantImages, allItemsChecked, imagesTotalCount]);

  const resetCache = useCallback(() => {
    bustCache.current = true;
  }, [bustCache]);

  const hasOnlyImagesChecked = useMemo(() => {
    return Object.values(relevantImages).every((item) => {
      const key = 'key' in item ? item.key : item.imageId;
      return Boolean(key);
    });
  }, [relevantImages]);

  return [
    checkedItems,
    {
      isChecked,
      setChecked,
      setAllChecked,
      allChecked: allItemsChecked,
      setMultipleChecked,
      hasOnlyImagesChecked,
      clearChecked,
      resetCache,
      getItemsCount,
      relevantImages,
    },
    bustCache,
  ];
}
