import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';

import {IMAGES_FETCH_ERROR, RESET} from '~redux/types';
import {Session} from '~redux/types/session';
import {selectCurrentModel, selectCurrentModelId} from '~redux/reducers/annotatorReducer';
import {
  getImageAnnotatorMode,
  getRegularTeachSessionImages,
  getSmartTeachSessionImages,
} from '~redux/reducers/imageReducer.utils';
import {selectUserEmail} from '~redux/reducers/userReducer';
import {ErrorAction} from '~redux/actions/errorActions';
import {ReduxState} from '~redux/index';

import {
  getLatestHumanAnnotations,
  getLatestHumanAnnotationsForLabel,
  getLatestMachinePredictions,
} from '~components/images/utils/annotation.utils';
import {ImageAnnotatorMode} from '~components/images/utils/image-mode-mixins';
import {
  ImageData,
  ImageDataWithAnnotationHistory,
  ImageMeta,
  ImagesData,
  ImageUploadData,
  UpdateImagesDataResponse,
} from '../types/images';

export interface ImageDataState {
  imageIndex: number | null;
  images: ImagesData | null;
  currentSession: Session | null;
  fetchError: string | null;
  overviewPageNumber: number;
  userUploads: ImageUploadData | null;
}

export const imageDataInitialState: ImageDataState = {
  imageIndex: null,
  images: null,
  currentSession: null,
  fetchError: null,
  overviewPageNumber: 1,
  userUploads: null,
};

const reducerName = 'images';

const imageDataSlice = createSlice({
  name: reducerName,
  initialState: imageDataInitialState,
  reducers: {
    setImages(state, action: PayloadAction<ImagesData>) {
      state.images = action.payload;
    },
    resetImages(state) {
      state.images = null;
    },
    updateImageAnnotations(state, action: PayloadAction<UpdateImagesDataResponse>) {
      if (state.images === null) {
        return state;
      }
      action.payload.forEach((item) => {
        const {imageId, annotations, humanAnnotationHistory} = item;
        const images = state.images!.data;
        const itemIndex = images?.findIndex((image) => image.id === imageId);

        if (itemIndex !== undefined) {
          Object.entries(annotations).forEach(([modelId, annotation]) => {
            if (images[itemIndex])
              images[itemIndex].annotations[modelId].latestHumanAnnotation = annotation.latestHumanAnnotation;
          });

          if (humanAnnotationHistory) {
            images[itemIndex].humanAnnotationHistory = humanAnnotationHistory;
          }
        }
      });
    },
    deleteImages(state, action: PayloadAction<string[]>) {
      if (state.images === null) {
        return state;
      }
      const images = state.images.data;
      for (const imageId of action.payload) {
        const itemIndex = images?.findIndex((image) => image.id === imageId);

        if (itemIndex !== undefined) {
          images.splice(itemIndex, 1);
        }
      }

      // update image index if the deleted images were the last ones
      if (state.imageIndex !== null && state.imageIndex >= images.length) {
        state.imageIndex = images.length > 0 ? images.length - 1 : null;
      }
    },
    addTag(state, action: PayloadAction<{imageIds: string[]; tag: string | string[]}>) {
      if (state.images === null) {
        return state;
      }
      const {imageIds, tag} = action.payload;

      const tagsToAdd = Array.isArray(tag) ? tag : [tag];
      state.images.data.forEach((image) => {
        if (imageIds.includes(image.id)) {
          image.tags = Array.from(new Set(image.tags.concat(tagsToAdd)));
        }
      });
    },
    removeTag(state, action: PayloadAction<{imageIds: string[]; tag: string | string[]}>) {
      if (state.images === null) {
        return state;
      }
      const {imageIds, tag} = action.payload;

      const tagsToRemove = Array.isArray(tag) ? tag : [tag];

      state.images.data.forEach((image) => {
        if (imageIds.includes(image.id)) {
          image.tags = image.tags.filter((t) => !tagsToRemove.includes(t));
        }
      });
    },
    addMeta(state, action: PayloadAction<ImageMeta[]>) {
      if (state.images === null) {
        return state;
      }
      action.payload.forEach((meta) => {
        const image = state.images!.data.find((image) => image.id === meta.imageId);
        if (image) {
          image.meta = {...image.meta, ...meta.meta};
        }
      });
    },
    setImageIndex(state, action: PayloadAction<number>) {
      state.imageIndex = action.payload;
    },
    resetImageIndex(state) {
      state.imageIndex = null;
    },
    setOverviewPageNumber(state, action: PayloadAction<number>) {
      state.overviewPageNumber = action.payload;
    },
    resetOverviewPageNumber(state) {
      state.overviewPageNumber = 1;
    },
    setCurrentSession(state, action: PayloadAction<Session | null>) {
      state.currentSession = action.payload;
    },
    uploadImages(state, action: PayloadAction<ImageUploadData>) {
      state.userUploads = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase<any>(IMAGES_FETCH_ERROR, (state, action: ErrorAction) => {
      state.fetchError = action.payload;
    });
    builder.addCase(RESET, () => imageDataInitialState);
  },
});

export const imageDataActions = imageDataSlice.actions;
export default imageDataSlice;

export const selectImagesData = (state: ReduxState) => state[reducerName].images;
export const selectIndex = (state: ReduxState) => state[reducerName].imageIndex;
export const selectPageNumber = (state: ReduxState) => state[reducerName].overviewPageNumber;
export const selectCurrentSession = (state: ReduxState) => state[reducerName].currentSession;
export const selectImagesFetchError = (state: ReduxState) => state[reducerName].fetchError;
export const selectImageById = (state: ReduxState, imageId: string) =>
  state[reducerName].images?.data.find((image) => image.id === imageId);

export const selectHasImageFetchError = createSelector(
  [selectImagesFetchError],
  (fetchError): boolean => fetchError !== null && fetchError !== '',
);
export const selectImagesTotalCount = createSelector([selectImagesData], (images) => images?.total || 0);
export const selectPaginationActions = createSelector([selectImagesData], (images) => images?.actions);
export const selectIsSmartTeachSession = createSelector([selectCurrentSession], (session): boolean => {
  return getImageAnnotatorMode(session) === ImageAnnotatorMode.smartTeachSession;
});

/**
 * Select ImagesData if available, otherwise return null.
 * If a smart teach session is active, the ImagesData is preprocessed as needed.
 */
export const selectImages = createSelector(
  [selectImagesData, selectCurrentSession, selectUserEmail],
  (imagesData, session, userEmail): ImageData[] => {
    const annotatorMode = getImageAnnotatorMode(session);
    if (!imagesData) {
      return [];
    } else if (annotatorMode === ImageAnnotatorMode.smartTeachSession) {
      // In a smart teach session, images are split up into multiple "synthetic" images, one for each smart session annotation.
      const result = getSmartTeachSessionImages(session!.meta!, imagesData.data);
      return result;
    } else if (annotatorMode === ImageAnnotatorMode.regularTeachSession) {
      // In a regular teach session, a user can only see their _own_ latest annotations, not those of other users. Therefore, we update
      // the latestHumanAnnotation field of each image with the latest annotation of the current user.
      return getRegularTeachSessionImages(userEmail!, imagesData.data as ImageDataWithAnnotationHistory[]);
    } else {
      return imagesData.data;
    }
  },
);

/**
 * Selects the current image index from the state. If there are no images, the index is 0.
 * If the index is out of bounds, it is clamped to the number of images.
 */
export const selectImageIndex = createSelector([selectIndex, selectImages], (index, images): number | null => {
  if (images === null || index === null) {
    return null;
  }

  // Clamp the index to the number of (preprocessed) images
  return Math.max(0, Math.min(index, images.length - 1));
});

/**
 * Selects an image at the given index.
 */
export const selectImageAtIndex = createSelector(
  [selectImages, (_state: ReduxState, index: number) => index],
  (images, index) => images[index] ?? null,
);

/**
 * Selects the ImageData for the current state.imageIndex. Returns null if there is no image data for the given image index.
 */
export const selectCurrentImage = createSelector([selectImageIndex, selectImages], (currentIndex, images) => {
  if (images === null || currentIndex == null) {
    return null;
  }
  return images[currentIndex] ?? null;
});

export const selectCurrentLatestMachinePredictions = createSelector(
  [selectCurrentImage, selectCurrentModel],
  (image, model) => {
    return getLatestMachinePredictions({image, modelId: model?.id});
  },
);

export const selectCurrentLatestMachinePrediction = createSelector(
  [selectCurrentLatestMachinePredictions],
  (predictions) => {
    if (!predictions) {
      return null;
    }
    const newestPrediction = predictions.at(-1);
    return newestPrediction ?? null;
  },
);

/**
 * Allows selecting the latest human annotations for a given image index and image annotator mode.
 */
export const selectLatestHumanAnnotationsForImageAtIndex = createSelector(
  [
    selectImages,
    selectCurrentModelId,
    selectCurrentSession,
    (_state: ReduxState, options: {imageIndex: number; annotatorMode: ImageAnnotatorMode}) => options,
  ],
  (images, currentModelId, session, options) => {
    const {annotatorMode, imageIndex} = options;
    const image = images[imageIndex];

    if (!image) {
      return null;
    } else if (
      annotatorMode === ImageAnnotatorMode.smartTeachSession &&
      getImageAnnotatorMode(session) === ImageAnnotatorMode.smartTeachSession
    ) {
      return getLatestHumanAnnotationsForLabel({
        image,
        modelId: currentModelId,
        labelId: session!.meta?.label,
      });
    }

    // Note: for regular teach sessions, images are already preprocessed in selectImages and the latestHumanAnnotations are already
    // updated to the latest annotations of the current user. Therefore, we can just return the latestHumanAnnotations here.
    return getLatestHumanAnnotations({
      image,
      modelId: currentModelId,
    });
  },
);
