import * as Sentry from '@sentry/nextjs';
import {produce} from 'immer';

import {AnnotationLabel, ImageData, ImageDataAnnotation, ImageDataWithAnnotationHistory} from '~redux/types/images';
import {Session, SessionMeta} from '~redux/types/session';

import {getLatestAnnotationsForUser, getLatestHumanAnnotations} from '~components/images/utils/annotation.utils';
import {ImageAnnotatorMode} from '~components/images/utils/image-mode-mixins';

export const getImageAnnotatorMode = (session: Session | null | undefined): ImageAnnotatorMode | undefined => {
  if (!session) {
    return ImageAnnotatorMode.default;
  } else if (session.type === 'error') {
    return ImageAnnotatorMode.smartTeachSession;
  } else if (session.type === 'image') {
    return ImageAnnotatorMode.regularTeachSession;
  } else if (session.type === 'anomaly') {
    return ImageAnnotatorMode.anomalyTeachSession;
  } else {
    Sentry.captureException(new Error(`Found unknown session type ${session.type}, session tag ${session.tag}`));
    return undefined;
  }
};

function hideLabelsIrrelevantForSmartSession(
  annotation: ImageDataAnnotation,
  relevantLabel: SessionMeta['label'],
): ImageDataAnnotation {
  return {
    ...annotation,
    labels: annotation.labels.map((label) => {
      if (label.id === relevantLabel) {
        return label;
      } else {
        return {
          ...label,
          hiddenForSmartSession: true,
        };
      }
    }),
  };
}

/**
 * Prepares the images for a smart teach session. This is done in the following steps:
 * 1. Filter out images which do not have any annotations from the session creator
 * 2. For each image, get the latest human annotation (if available) and remove all labels which do not match the label of
 *   the session
 * 3. For each image, get the latest annotation created by the session creator and hide all labels which do not match the
 *  label of the session
 * 4. For each label of the session creator annotation, create a new synthesized image that includes the latest human
 *  annotation (if available) and a synthesized annotation with a *single* label of the session creator
 * 5. Update the images to include the synthesized images
 *
 * @param meta The session meta data
 * @param imagesData The images relevant for the session
 * @returns The images for the session (including synthesized images)
 */
export function getSmartTeachSessionImages(meta: SessionMeta, images: ImageData[]): ImageData[] {
  // session creator refers to the ML model that created the annotations for the session
  const {user: sessionCreator, label: sessionLabel, modelId} = meta;

  if (modelId === null) {
    throw new Error(`modelId is null in smart teach session with label ${sessionLabel}, user ${sessionCreator}`);
  }

  const sessionImages: ImageData[] = [];

  // Each image in a smart teach session should show all labels from the latest human annotation (if available) as well as
  // a single label from the session creator.
  for (const imageData of images) {
    // 1. Get the latest human annotation if available
    // Note that annotations by the session creator are always model annotations, i.e. have the model flag set to true
    const latestUserAnnotations = getLatestHumanAnnotations({image: imageData, modelId});

    // hide all labels which do not match the label of the session
    const relevantUserAnnotation =
      latestUserAnnotations !== null && latestUserAnnotations[0]
        ? hideLabelsIrrelevantForSmartSession(latestUserAnnotations[0], sessionLabel)
        : null;

    // 2. Get the latest annotation created by the session creator
    if (!imageData.smartSessionAnnotations) {
      continue;
    }
    const latestSessionCreatorAnnotation = imageData.smartSessionAnnotations[modelId];
    const sessionLabels = latestSessionCreatorAnnotation.labels;

    // If there are no session creator annotations for the session label, we can ignore the image
    if (sessionLabels.length === 0) {
      continue;
    }

    // For each session label, create a synthetic copy of the current image that includes the latest relevant human annotation (if available) and
    // an annotation with a single label from the session creator. This way, we can show the latest human annotation and the session creator annotation for
    // each label separately in the smart teach session UI.
    const synthesizedSessionImages: ImageData[] = sessionLabels.map((label: AnnotationLabel): ImageData => {
      // Create a new annotation from the latest session creator annotation, but with only a single label
      const synthesizedAnnotation: ImageDataAnnotation = {
        ...latestSessionCreatorAnnotation,
        type: 'machine', // synthesized annotations are treated as machine annotations
        labels: [label],
      };

      // Create a new image including the latest human annotation and the synthesized annotation
      // using produce allows us to modify the image in place, which keeps the code easier to read
      return produce(imageData, (draftImage) => {
        draftImage.annotations[modelId] = {
          latestHumanAnnotation: relevantUserAnnotation,
          machinePrediction: synthesizedAnnotation,
        };
      });
    });

    sessionImages.push(...synthesizedSessionImages);
  }

  return sessionImages;
}

/**
 * Prepares the images for a regular teach session. This is done by updating the latestHumanAnnotation field for each
 * model to the latest annotation for the given user. If the given user has no annotations for a model, the latestHumanAnnotation
 * field is set to null. Model predictions are not changed.
 */
export function getRegularTeachSessionImages(
  userEmail: string,
  images: ImageDataWithAnnotationHistory[],
): ImageDataWithAnnotationHistory[] {
  const sessionImages = images.map((image) => {
    // Using produce allows us to modify the image in place, which keeps the code easier to read
    return produce(image, (draftImage) => {
      if (!draftImage.humanAnnotationHistory) {
        throw new Error(`humanAnnotationHistory is undefined for image ${draftImage.id}`);
      }

      // Get all latest annotations for the given user
      const latestAnnotationsForUser = getLatestAnnotationsForUser({
        image: image as ImageDataWithAnnotationHistory,
        userEmail,
      });

      // Reset the latestHumanAnnotation field for all models
      Object.keys(draftImage.annotations).forEach((modelId) => {
        draftImage.annotations[modelId].latestHumanAnnotation = null;
      });

      // Set latestHumanAnnotation for the given user for every model that has an annotation for the user
      if (latestAnnotationsForUser) {
        latestAnnotationsForUser.forEach((annotation) => {
          draftImage.annotations[annotation.modelId].latestHumanAnnotation = annotation;
        });
      }
    });
  });
  return sessionImages;
}
