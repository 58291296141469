import {AnyAction} from 'redux';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';

import {AuthService} from '~api/auth.service';
import * as api from '~api/users';
import {replaceRoute, Route} from '~utils/routeUtil';
import {UserData} from '~redux/types/user';
import {ReduxState} from '~redux/index';

import {queryClient} from '~pages/_app';
import {FETCH_USER, USER_ERROR, USER_PROJECT} from '../types';
import {PayloadAction} from '../types/main';
import {setError} from './errorActions';

// NOTE: this is a fix to improve loading times during development. The user takes ~5 seconds to load within the dev API,
// because it includes a lot of data. This data rarely changes/isn't that relevant for development.
export async function fetchUser() {
  return queryClient.fetchQuery(['user'], () => api.getUser(), {
    staleTime: process.env.NODE_ENV === 'development' ? Infinity : 0,
  });
}

export const setUserData = (userData: UserData): PayloadAction => {
  return {type: FETCH_USER, payload: userData};
};

export const fetchUserData =
  () =>
  async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>): Promise<UserData | void> => {
    try {
      const userData = await fetchUser();
      dispatch(setUserData(userData));
      return userData;
    } catch (error: any) {
      dispatch(setError(USER_ERROR, error, USER_ERROR));
    }
  };

/**
 * @throws {Error} if the login fails
 */
export const loginAndFetchUser =
  (email: string, password: string): ThunkAction<Promise<void>, ReduxState, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>): Promise<void> => {
    await AuthService.login(email, password);
    await dispatch(fetchUserData());
    const userData = await fetchUser();
    if (userData?.landingPage) {
      await replaceRoute(`/${userData.landingPage?.toLowerCase()}`);
    } else {
      await replaceRoute(Route.monitor);
    }
  };

export const setCurrentProjectId = (projectID: string): PayloadAction => ({type: USER_PROJECT, payload: projectID});
