/* eslint-disable no-restricted-imports */
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';

export type AvailableLocales = 'de' | 'de-DE' | 'en';

const localeMap: {[key: string]: Locale} = {
  en: enLocale,
  de: deLocale,
  'de-DE': deLocale,
};

const apexChartLocales: ApexLocale[] = [
  {
    name: 'de',
    options: {
      days: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
      shortDays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
      months: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
      shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    },
  },
  {
    name: 'en',
    options: {
      days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      shortDays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
  },
];

const i18nInstance = i18n.use(Backend).use(initReactI18next).use(LanguageDetector);

if (process.env.NODE_ENV === 'development') {
  import('i18next-hmr/plugin').then(({HMRPlugin}) => {
    i18nInstance.use(
      new HMRPlugin({
        webpack: {
          client: true,
        },
      }),
    );
  });
}

i18nInstance.init({
  // options for Backend
  backend: {
    loadPath: '/static/locales/{{lng}}/{{ns}}.json',
  },
  // options for LanguageDetector
  detection: {
    order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'],
  },
  fallbackNS: 'common',
  defaultNS: 'common',
  ns: ['common'],
  supportedLngs: ['de-DE', 'en'],
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  react: {
    useSuspense: false, // TODO we should instead use Suspense throughout the app
  },
});

export {i18n, localeMap, apexChartLocales};

// reexport functions & types from react-i18next
export {useTranslation, Trans, I18nextProvider} from 'react-i18next';
export type {TFunction} from 'i18next';
