import {ClassAnnotationLabel, PolygonAnnotationLabel, PolygonOrClassAnnotationLabel} from '~redux/types/images';

export function isPolygonLabel(labelData: PolygonOrClassAnnotationLabel): labelData is PolygonAnnotationLabel {
  return 'coordinates' in labelData && labelData.coordinates !== null;
}

export function isClassificationLabel(labelData: PolygonOrClassAnnotationLabel): labelData is ClassAnnotationLabel {
  return !('coordinates' in labelData) || labelData.coordinates === null;
}

export function getClassifications(dataObjects?: PolygonOrClassAnnotationLabel[]): string[] | null {
  if (!dataObjects) {
    return null;
  }
  return dataObjects.filter(isClassificationLabel).map((labelData) => labelData.id);
}

export function hasClassifications(dataObjects?: PolygonOrClassAnnotationLabel[]): boolean {
  const classifications = getClassifications(dataObjects);
  return classifications !== null && classifications.length > 0;
}
