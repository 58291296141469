import {i18n} from 'i18n';
import {KeyboardEvent} from 'react';

import {theme} from '~constants/theme';
import {formatNumber} from '~utils/localeUtil';
import {ImageDataAnnotation, ImageOrderBy, ImageSortBy, ImageSortOrder} from '~redux/types/images';
import {ClassificationItem} from '~redux/reducers/annotatorReducer';

import {DEFAULT_SHORT_LABEL_CHAR_LENGTH, IMAGE_SORT_ORDER_DELIMITER} from 'src/constants/constants';

export const createDebouncer = (
  wait = 250,
  leadingEdge = false,
): ((func: any) => (...args: any[]) => Promise<void>) => {
  let isRunning = false;
  return (func: any): ((...args: any[]) => Promise<void>) => {
    return async (...args: any[]): Promise<void> => {
      if (isRunning) {
        return;
      }

      isRunning = true;
      if (leadingEdge) {
        await func(...args);
        await new Promise((resolve) => setTimeout(resolve, wait));
      } else {
        await new Promise((resolve) => setTimeout(resolve, wait));
        await func(...args);
      }

      isRunning = false;
    };
  };
};

/**
 * Removes the 'px' from a CSS size and returns the bare number value
 * @param cssSize a px-based CSS size, e.g. '16px'
 * @returns The number value of the string, e.g. 16
 */
export const numValueOfSize = (cssSize: string): number => {
  return Number.parseInt(cssSize.replace('px', ''));
};

/**
 * Download a file, defined by the provided url and save it to disk.
 * If filename is omitted, the file saved to disk will have the filename defined by the url
 *
 * @param url the URL pointing to the file to save
 * @param fileName an optional file name for the file to save
 */
export const downloadUrl = (url: string, fileName?: string): void => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = fileName || '';
  document.body.appendChild(a);
  a.click();
  a.remove();
};

/**
 * Shortens a string to a given maximum length of characters by adding an ellipsis "…" in the middle.
 */
export const textShortener = (text: string, maxChars = DEFAULT_SHORT_LABEL_CHAR_LENGTH): string => {
  maxChars = maxChars % 2 === 0 ? maxChars : maxChars + 1;

  if (text && text.length > maxChars) {
    const midOffset = Math.floor(maxChars / 2);
    return `${text.substring(0, midOffset)}…${text.substring(text.length - midOffset)}`;
  }
  return text;
};

/**
 * Returns a number as a string formatted to the current locale.
 *
 * @example
 * formatNumberToCurrentLocale(1000.52)
 * // => "1.000,52" for locale 'de-DE'
 * // => "1,000.52" for locale 'en-GB'
 */
function formatNumberToCurrentLocale(val: number, opts?: Intl.NumberFormatOptions): string {
  return new Intl.NumberFormat(i18n.language, opts).format(val);
}

/**
 * Transforms a number to a shortened human readable and internationalized string.
 *
 * @example
 * shortenNumber(873)
 * // => "873"
 * shortenNumber(1_245)
 * // => "1,2k"
 * shortenNumber(4_500_000)
 * // => "4,5M"
 * shortenNumber(Infinity)
 * // => "∞"
 */
export function shortenNumber(val: number): string {
  if (val === Infinity) return '∞';
  if (val >= 1_000_000) return `${formatNumberToCurrentLocale(val / 1_000_000, {maximumFractionDigits: 1})}M`;
  if (val >= 1_000) return `${formatNumberToCurrentLocale(val / 1_000, {maximumFractionDigits: 1})}k`;
  return formatNumberToCurrentLocale(val);
}

export interface AnyLabelledItem {
  id: string;
  [any: string]: any;
}

/**
 * Takes a label and tries to find and return an alias for it from the given items list. If
 * no alias exists, the label itself is returned.
 */
export const getAliasFromItems = (label: string, items: AnyLabelledItem[]): string => {
  if (items.length === 0) {
    return label;
  }
  const item = items.find((item: AnyLabelledItem) => item.id === label);
  return item?.alias ?? label;
};

/**
 * Takes a label and tries to find and return an alias for it from the given items list. If
 * no alias exists, the label itself is returned.
 */
export const getColorFromItems = (label: string, items: AnyLabelledItem[]): string => {
  if (items.length === 0) {
    return theme.palette.maddox.unlabeled;
  }
  const item = items.find((item) => item.id === label);
  return item?.colorCode ?? theme.palette.maddox.unlabeled;
};

export const useAliasAndColorFromItems = (
  items: AnyLabelledItem[],
  label?: string,
): {label?: string; color: string} => {
  if (items.length === 0) {
    return {label, color: theme.palette.maddox.unlabeled};
  }

  const item = items.find((item) => item.id === label);
  return {
    label: item?.alias ?? label,
    color: item?.colorCode ?? theme.palette.maddox.unlabeled,
  };
};

/**
 * Takes a string representation of a shift and transforms it to a Shift object. If the
 * string could not be parsed, `undefined` is returned.
 *
 * @param shiftString The string must be of type "aaaa-bbbb" where "aaaa" is the shift start
 *                    and "bbbb" is the shift end in 24 hour system with omitted colon.
 * @example
 * shiftFromString('1430-2230')
 * // => {shiftFrom: '1430', shiftTo: '2230'}
 * shiftFromString('gibberish')
 * // => undefined
 */
export function shiftFromString(shiftString: string): Shift | undefined {
  if (shiftString.length === 9 && shiftString.includes('-')) {
    const [from, to] = shiftString.split('-');
    return {from, to};
  }
  return undefined;
}

export function shiftsFromString(shiftsString: string): Shift[] | undefined {
  if (!shiftsString) {
    return undefined;
  }
  return shiftsString
    .split(',')
    .map((shiftString) => shiftFromString(shiftString))
    .filter((shift) => !!shift) as Shift[];
}

/**
 * Takes a Shift object and returns a string representation.
 * The returned string is intended to be used as select option values, in URL query parameters etc.
 *
 * @example
 * stringFromShift({shiftFrom: '1430', shiftTo: '2230'})
 * // => '1430-2230'
 */
export function stringFromShift(shift: Shift): string {
  return `${shift.from}-${shift.to}`;
}

/**
 * Takes the email of a user and returns whether that user is an administrator or not
 * Valid admin emails are
 * - somename@maddox.ai
 * - somename@somecustomer.maddox.ai
 * Non-admin emails look like:
 * - somename@somecustomer.de
 * @param email the email of the user
 */
export function isInternalEmail(email?: string): boolean {
  return email ? new RegExp('@.*maddox.ai$').test(email) : false;
}

/**
 * Non-cryptographic hash function for strings.
 * @see https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 */
export function stringToHash(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; ++i) {
    hash = 31 * hash + str.charCodeAt(i);
  }
  return '#' + (hash | 0);
}

/**
 * Prevents a form to be submitted when pressing the Enter key. Needs to be set as a callback for the onKeyDown event.
 */
export function preventSubmitOnEnter(event: KeyboardEvent) {
  if (event.code === 'Enter') {
    event.preventDefault();
  }
}

/**
 * Compares two arrays and returns true if their elements are equal (ignoring element order).
 * If any of the arrays is undefined, false is returned.
 */
export function isEqualArrays(a: any[], b: any[]): boolean {
  return a.length === b.length && a.every((elementOfA) => b.includes(elementOfA));
}

/**
 * Takes an ImageOrderBy enum value as input and returns the encoded sort field and sort direction.
 */
export function splitImageOrderBy(value?: ImageOrderBy):
  | undefined
  | {
      sortBy: ImageSortBy | undefined;
      sortOrder: ImageSortOrder | undefined;
    } {
  if (!value) {
    return undefined;
  }

  const [sortBy, sortOrder] = value.split(IMAGE_SORT_ORDER_DELIMITER) as [ImageSortBy, ImageSortOrder];
  return {sortBy, sortOrder};
}

/**
 * Takes a sort type and sort direction and returns the corresponding ImageOrderBy enum value.
 */
export function getImageOrderBy(sortBy: ImageSortBy, sortOrder: ImageSortOrder): ImageOrderBy {
  return (sortBy + IMAGE_SORT_ORDER_DELIMITER + sortOrder) as ImageOrderBy;
}

/**
 * lowercase a string or an array of strings
 */
export function lowerCaseArray(value?: string[]): string[] | undefined {
  if (!value) {
    return undefined;
  }
  return value.map((v) => v.toLowerCase());
}

/**
 * Rounds a given value to the given number of decimals (default 0).
 */
export function roundTo(value: number, decimals = 0): number {
  return Math.round(value * 10 ** decimals) / 10 ** decimals;
}

/**
 * Formats a given value as a percentage of the given total.
 * The percentage sign is not included and the value is rounded to one decimal place.
 *
 * @example
 * formatPercentage(33, 100)
 * // => "33"
 * formatPercentage(3, 57)
 * // => "3.6"
 */
export function formatPercentage(value: number, total: number): string {
  const percent = (value / total) * 100;
  // round to 1 decimal place. If the value is 0 only 0 will be displayed
  // (this is why we don't use toFixed here)
  const roundedPercent = formatNumber(roundTo(percent, 1));
  return roundedPercent.toString();
}

/**
 * Returns true if the current environment is a production environment based on the
 * NODE_ENV and DEPLOYMENT_ENV environment variables. A production environment is
 * defined as a deployment environment that starts with "prod".
 */
export function isProductionEnv(): boolean {
  const isTestOrDevEnv = process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development';
  const isProdEnv = process.env.DEPLOYMENT_ENV?.startsWith('prod');

  if (isTestOrDevEnv) {
    return false;
  }

  return isProdEnv ?? true;
}

export function isDevelopmentEnv(): boolean {
  return !!(
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test' ||
    process.env.DEPLOYMENT_ENV?.startsWith('dev') ||
    process.env.DEPLOYMENT_ENV?.startsWith('preview')
  );
}

export function byUpdatedAtOldestFirst(a: number, b: number) {
  return a - b;
}

export function byAlphabet(a: string, b: string) {
  return a.localeCompare(b);
}

/**
 * Sorter function to sort classifications by oldest first
 */
export function byOldestClassificationOrAnnotationFirst(
  a: ClassificationItem | ImageDataAnnotation,
  b: ClassificationItem | ImageDataAnnotation,
) {
  return byUpdatedAtOldestFirst(a.updatedAt, b.updatedAt);
}

/**
 * This truncates a number to a given number of decimals.
 */
export function toConsistentDecimal(value: number, numberOfDecimals = 11): number {
  return Number(value.toFixed(numberOfDecimals));
}

/**
 * This function takes a comma separated list of strings and returns an array of strings.
 */
export function commaListToArray(commaList?: string): string[] | undefined {
  return commaList
    ?.trim()
    .split(',')
    .map((_: string) => _.trim());
}

export const hasOpenedDialogs = () => document.querySelectorAll('[role="dialog"]').length > 0;

export function getUUID(prefix: string = '') {
  return prefix + window.crypto.randomUUID();
}

export function stopEventPropagate(callback: () => void) {
  return (e: {stopPropagation: () => void; preventDefault: () => void}) => {
    e.stopPropagation();
    e.preventDefault();
    callback();
  };
}
/**
 * Get Image meta, by providing a URL
 */

export const getImageMeta = async (url: string) => {
  const img = new Image();
  img.src = url;
  await img.decode();
  return img;
};
