const castValue = (value: string | number | null, valueType: string) => {
  switch (valueType) {
    case 'int':
      if (value === null) return null;
      return parseInt(value?.toString(), 10);
    case 'str':
      return String(value);
    case 'float':
      if (value === null) return null;
      return parseFloat(value?.toString());
    default:
      return value;
  }
};

export const parseMetadata = (meta: MetadataFilters, metadataFilters?: MetadataFiltersPayload) => {
  const parsedFilters: MetadataFiltersPayload = {};
  for (const key in metadataFilters) {
    if (Object.prototype.hasOwnProperty.call(metadataFilters, key) && Object.prototype.hasOwnProperty.call(meta, key)) {
      const valueType = meta[key].type;
      parsedFilters[key] = metadataFilters[key]?.map((value) => castValue(value, valueType));
    }
  }

  return parsedFilters;
};
