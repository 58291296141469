import {useCallback, useEffect, useMemo, useRef} from 'react';

import {ImageSize} from '~utils/imageSizeCache';
import {getColorFromItems} from '~utils/miscUtils';
import {QueryStringObject, useQueryParams} from '~utils/routeUtil';
import {useConfusionCellFromQueryParams, useEvaluationCellFromQueryParams} from '~hooks/imagesOverview';
import {
  AnnotationLabel,
  ImageData,
  ImageDataAnnotation,
  ImageFilters,
  LabelItem,
  LatestAnnotations,
  PaginationActionWithTargetPage,
} from '~redux/types/images';
import {AnnotationItem} from '~redux/reducers/annotatorReducer';
import {selectMetadata} from '~redux/reducers/filtersReducer';
import {imageDataActions} from '~redux/reducers/imageReducer';
import {selectCurrentProject} from '~redux/reducers/userReducer';
import {updateImagesData} from '~redux/actions/imageActions';
import {useAppDispatch, useAppSelector} from '~redux/index';

import {isPolygonAnnotationLabel} from '~components/images/utils/annotation.utils';
import {ImageOverviewMode} from '~components/images/utils/image-mode-mixins';
import {useLabelItems} from '~components/models/model.hooks';
import {CLASS_EMPTY, CLASS_OK, THUMBNAIL_BORDER_WIDTH, THUMBNAIL_MAX_HEIGHT, UNLABELED} from 'src/constants/constants';
import {useLabelInstances} from 'src/contexts/LabelInstancesContext';
import {useCurrentModel} from 'src/contexts/ModelContext';
import {parseMetadata} from './metadata.utils';

/**
 * Get and update the filters applied to the image list based on the model-specific filter classes and the
 * URL query parameters. The returned update function updates the query parameters and the Redux state and reloads
 * the image list.
 *
 * @return A tuple containing:
 *         - the current imageFilter state
 *         - an update function that takes a (partial) imageFilter state,
 *           updates the query parameters and the Redux state and reloads the image list.
 */
export function useImageFilters(
  mode: ImageOverviewMode,
  initialLabels: LabelItem[] | null,
): [
  ImageFilters | undefined,
  (newFilters: Partial<ImageFilters>, paginationActionTargetPage?: PaginationActionWithTargetPage | undefined) => void,
] {
  const dispatch = useAppDispatch();
  const [queryParamFilters, setQueryParams] = useImageFilterQueryParams();
  const {activeViewMode, isPageDirty: isLabelInstancesPageDirty} = useLabelInstances();
  const wasRefreshCalled = useRef(false);
  const confusionCell = useConfusionCellFromQueryParams();
  const meta = useAppSelector(selectMetadata);

  const initialLabelIds = useMemo((): string[] | null => {
    if (!initialLabels || initialLabels.length === 0) {
      return null;
    } else {
      return initialLabels.map((labelItem) => labelItem.id);
    }
  }, [initialLabels]);

  const updateFiltersForMode = useCallback(
    (filters: ImageFilters): ImageFilters => {
      const updatedFilters = {...filters};
      const parsedMeta = parseMetadata(meta, updatedFilters.meta);
      if (mode === 'model-defect-book') {
        // We need to filter by the model's label IDs if the user hasn't set any filter classes.
        // This ensures that we show only images belonging to the model.
        const isClassFilterEmpty = !filters['humanAnnotationLabels'] || filters['humanAnnotationLabels'].length === 0;
        if (initialLabelIds && isClassFilterEmpty) {
          updatedFilters['humanAnnotationLabels'] = [...initialLabelIds];
        }
      }

      if (filters.confusionMatrixCellID) {
        updatedFilters.confusionCell = confusionCell;
      }

      if (filters.meta) {
        updatedFilters.meta = parsedMeta;
      }
      return updatedFilters;
    },
    [confusionCell, initialLabelIds, meta, mode],
  );

  const currentFilters = useMemo(
    (): ImageFilters => updateFiltersForMode(queryParamFilters),
    [updateFiltersForMode, queryParamFilters],
  );

  const updateFilters = useCallback(
    (imageFilters?: Partial<ImageFilters>, paginationActionWithTargetPage?: PaginationActionWithTargetPage) => {
      const joinedFilters = {...currentFilters, ...imageFilters};

      setQueryParams(joinedFilters);
      const filtersUpdatedForMode = updateFiltersForMode(joinedFilters);

      const refreshImages = async () => {
        await dispatch(
          updateImagesData({
            withGlobalLoading: true,
            imageFilters: filtersUpdatedForMode,
            paginationActionWithTargetPage,
          }),
        );
        dispatch(imageDataActions.resetImageIndex());
      };

      if (activeViewMode !== 'instance') {
        refreshImages();
      } else {
        wasRefreshCalled.current = true;
      }
    },
    [currentFilters, setQueryParams, updateFiltersForMode, activeViewMode, dispatch],
  );

  useEffect(() => {
    if (isLabelInstancesPageDirty || (activeViewMode === 'image' && wasRefreshCalled.current)) {
      wasRefreshCalled.current = false;
      updateFilters();
    }
  }, [activeViewMode, isLabelInstancesPageDirty, updateFilters]);

  return [currentFilters, updateFilters];
}

// This defines the keys of the ImageFilter object that are used in the URL query parameters.
// We are omitting some legacy keys that are not used anymore or should not appear in the URL.
type ImageFilterQueryKeys = keyof Required<
  Omit<ImageFilters, 'isLabeledByHuman' | 'isPredictedOnlyByModel' | 'classes' | 'classesToExclude' | 'confusionCell'>
>;

// This ensures that all ImageFilter keys are present in the returned object, while allowing the values to be undefined.
type ImageFiltersWithRequiredKeysButOptionalValues = {
  [key in ImageFilterQueryKeys]: ImageFilters[key] | undefined;
};

// This object is used to enforce that all desired ImageFilter keys will be present in observedImageFilterKeys
// TypeScript will complain if a key is missing. To exclude a key, remove it from the type definition above.
const observedImageFilters: ImageFiltersWithRequiredKeysButOptionalValues = {
  automlPipelineId: undefined,
  objectId: undefined,
  objectIds: undefined,
  objectIdsToExclude: undefined,
  probability: undefined,
  tags: undefined,
  humanAnnotationLabels: undefined,
  excludeHumanAnnotationLabels: undefined,
  machinePredictionLabels: undefined,
  timeframe: undefined,
  productType: undefined,
  serialNumber: undefined,
  withNoImages: undefined,
  trainingTag: undefined,
  perspective: undefined,
  devices: undefined,
  latestLabelers: undefined,
  orderBy: undefined,
  withoutMachineDefectPredictions: undefined,
  withoutMachineObjectPredictions: undefined,
  confusionMatrixCellID: undefined,
  similaritySearch: undefined,
  evaluationCell: undefined,
  meta: undefined,
  modelId: undefined,
  humanPolygonAreaSize: undefined,
};

const observedImageFilterKeys: string[] = Object.keys(observedImageFilters);

function serializeMetadataFiltersPayload(payload: MetadataFiltersPayload): string | undefined {
  const queryParams: string[] = [];

  for (const key in payload) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      const values = payload[key];
      values?.forEach((value) => {
        if (value !== null) {
          queryParams.push(`meta[${encodeURIComponent(key)}]=${encodeURIComponent(String(value))}`);
        }
      });
    }
  }

  if (queryParams.length === 0) return undefined;

  return queryParams.join('&');
}

const serializeEvaluationCell = (evaluationCell?: EvaluationCell): string | undefined => {
  if (!evaluationCell) return undefined;

  return `${evaluationCell.areaSize}_${evaluationCell.confidence}_${evaluationCell.pipelineId}_${evaluationCell.predictedClass}`;
};

export const serializeSimSearchFilter = (simSearchFilter?: SimSearchFilter): string | undefined => {
  if (!simSearchFilter) return undefined;

  return `${simSearchFilter.imageId}_${simSearchFilter.searchCoordinate.x}_${simSearchFilter.searchCoordinate.y}${simSearchFilter.count ? `_${simSearchFilter.count}` : ''}`;
};

// This ensures that all ImageFilter keys are present in the returned object, but that the values are of the type defined in QueryStringObject.
type ImageFiltersWithQueryStringObjectValues = {
  [key in ImageFilterQueryKeys]: QueryStringObject[0];
};
function queryStringObjectFromImageFilters(filters: Partial<ImageFilters>): ImageFiltersWithQueryStringObjectValues {
  return {
    automlPipelineId: filters.automlPipelineId,
    humanAnnotationLabels: filters.humanAnnotationLabels,
    excludeHumanAnnotationLabels: filters.excludeHumanAnnotationLabels,
    machinePredictionLabels: filters.machinePredictionLabels,
    devices: filters.devices,
    latestLabelers: filters.latestLabelers,
    objectId: filters.objectId,
    objectIds: filters.objectIds,
    objectIdsToExclude: filters.objectIdsToExclude,
    probability: filters.probability,
    serialNumber: filters.serialNumber,
    tags: filters.tags,
    timeframe: filters.timeframe,
    trainingTag: filters.trainingTag,
    withNoImages: filters.withNoImages,
    productType: filters.productType,
    perspective: filters.perspective ? filters.perspective.map((p) => p.toString()) : undefined,
    orderBy: filters.orderBy,
    withoutMachineDefectPredictions: filters.withoutMachineDefectPredictions,
    withoutMachineObjectPredictions: filters.withoutMachineObjectPredictions,
    confusionMatrixCellID: filters.confusionMatrixCellID,
    similaritySearch: serializeSimSearchFilter(filters.similaritySearch),
    evaluationCell: serializeEvaluationCell(filters.evaluationCell),
    meta: serializeMetadataFiltersPayload(filters.meta ?? {}),
    modelId: filters.modelId,
    humanPolygonAreaSize: filters.humanPolygonAreaSize
      ? `${filters.humanPolygonAreaSize.minArea}-${filters.humanPolygonAreaSize.maxArea}`
      : undefined,
  };
}

type FilterQueryStringObject = QueryStringObject & {
  [key in ImageFilterQueryKeys]?: QueryStringObject[0];
};

const parseMetadataFiltersPayload = (queryString: string): MetadataFiltersPayload | undefined => {
  const params = new URLSearchParams(queryString);
  const payload: MetadataFiltersPayload = {};

  params.forEach((value, key) => {
    const metaMatch = key.match(/^meta\[(.+)\]$/);
    if (metaMatch) {
      const metaKey = metaMatch[1];
      if (!payload[metaKey]) {
        payload[metaKey] = [];
      }
      payload[metaKey]?.push(value);
    }
  });

  if (Object.keys(payload).length === 0) return undefined;

  return payload;
};

const parseEvaluationCell = (evaluationCell: string): EvaluationCell | undefined => {
  if (!evaluationCell) return undefined;

  const [areaSize, confidence, pipelineId, ...predictedClass] = evaluationCell.split('_');
  return {
    areaSize: Number(areaSize),
    confidence: Number(confidence),
    pipelineId,
    predictedClass: predictedClass.join('_'),
  };
};

export const parseSimSearchFilter = (simSearchFilter: string): SimSearchFilter | undefined => {
  if (!simSearchFilter) return undefined;

  const [imageId, searchCoordinateX, searchCoordinateY, count] = simSearchFilter.split('_');
  return {
    imageId,
    searchCoordinate: {
      x: Number(searchCoordinateX),
      y: Number(searchCoordinateY),
    },
    count: count ? Number(count) : undefined,
  };
};

function parseHumanPolygonAreaSize(value: string | null): {minArea: number; maxArea: number} | undefined {
  if (!value) return undefined;

  const [minArea, maxArea] = value.split('-').map(Number);
  if (isNaN(minArea) || isNaN(maxArea)) return undefined;

  return {minArea, maxArea};
}

function imageFiltersFromQueryStringObject(filters: FilterQueryStringObject): Partial<ImageFilters> {
  const hasPerspective = filters.perspective !== undefined && filters.perspective !== null;

  let perspective: number[] | undefined = undefined;
  if (hasPerspective) {
    if (Array.isArray(filters.perspective)) {
      perspective = filters.perspective.reduce((acc: number[], curr: string | null) => {
        if (typeof curr === 'string') {
          acc.push(Number.parseInt(curr));
        }
        return acc;
      }, []);
    } else if (typeof filters.perspective === 'string') {
      perspective = [Number.parseInt(filters.perspective as string)];
    }
  }

  const imageFilters = {
    ...filters,
    objectIds: typeof filters.objectIds === 'string' ? [filters.objectIds as string] : (filters.objectIds as string[]),
    humanAnnotationLabels:
      typeof filters.humanAnnotationLabels === 'string'
        ? [filters.humanAnnotationLabels as string]
        : (filters.humanAnnotationLabels as string[]),
    excludeHumanAnnotationLabels:
      typeof filters.excludeHumanAnnotationLabels === 'string'
        ? [filters.excludeHumanAnnotationLabels as string]
        : (filters.excludeHumanAnnotationLabels as string[]),
    machinePredictionLabels:
      typeof filters.machinePredictionLabels === 'string'
        ? [filters.machinePredictionLabels as string]
        : (filters.machinePredictionLabels as string[]),
    serialNumber:
      typeof filters.serialNumber === 'string' ? [filters.serialNumber as string] : (filters.serialNumber as string[]),
    tags: typeof filters.tags === 'string' ? [filters.tags as string] : (filters.tags as string[]),
    latestLabelers:
      typeof filters.latestLabelers === 'string'
        ? [filters.latestLabelers as string]
        : (filters.latestLabelers as string[]),
    devices: typeof filters.devices === 'string' ? [filters.devices as string] : (filters.devices as string[]),
    productType:
      typeof filters.productType === 'string' ? [filters.productType as string] : (filters.productType as string[]),
    perspective,
    meta: parseMetadataFiltersPayload(filters.meta as string),
    evaluationCell: parseEvaluationCell(filters.evaluationCell as string),
    similaritySearch: parseSimSearchFilter(filters.similaritySearch as string),
    humanPolygonAreaSize:
      typeof filters.humanPolygonAreaSize === 'string'
        ? parseHumanPolygonAreaSize(filters.humanPolygonAreaSize)
        : undefined,
  } as ImageFiltersWithRequiredKeysButOptionalValues;

  // Remove undefined properties
  type ImageFilterKey = keyof typeof imageFilters;
  for (const key in imageFilters) {
    if (
      imageFilters[key as ImageFilterKey] === undefined ||
      (key === 'meta' && Object.keys(imageFilters[key] ?? {}).length === 0)
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete imageFilters[key];
    }
  }
  return imageFilters;
}

export function useImageFilterQueryParams(): [
  Partial<ImageFilters>,
  (filters: Partial<ImageFilters> | undefined) => void,
] {
  const [queryParams, setQueryParams] = useQueryParams(observedImageFilterKeys);
  const evaluationCell = useEvaluationCellFromQueryParams();

  if (evaluationCell) {
    queryParams.evaluationCell = serializeEvaluationCell(evaluationCell);
  }

  const appliedFilters = useMemo(() => {
    return imageFiltersFromQueryStringObject(queryParams);
  }, [queryParams]);

  const updateQueryStringFilters = useCallback(
    (filters: Partial<ImageFilters> | undefined) => {
      setQueryParams(filters ? queryStringObjectFromImageFilters(filters) : {});
    },
    [setQueryParams],
  );

  return [appliedFilters, updateQueryStringFilters];
}

export function useThumbnailSize(size?: ImageSize): ImageSize {
  const currentProject = useAppSelector(selectCurrentProject);
  const dimensions = currentProject?.image?.dimensions;

  const height = size?.height || dimensions?.height || THUMBNAIL_MAX_HEIGHT;
  const width = size?.width || dimensions?.width || THUMBNAIL_MAX_HEIGHT;
  const aspectRatio = THUMBNAIL_MAX_HEIGHT / height;

  return {
    width: width * aspectRatio + THUMBNAIL_BORDER_WIDTH + THUMBNAIL_BORDER_WIDTH,
    height: THUMBNAIL_MAX_HEIGHT + THUMBNAIL_BORDER_WIDTH + THUMBNAIL_BORDER_WIDTH,
  };
}

function sortByPolygonLabelsFirst(a: AnnotationLabel, b: AnnotationLabel): number {
  if (isPolygonAnnotationLabel(a) && !isPolygonAnnotationLabel(b)) {
    return -1;
  } else if (!isPolygonAnnotationLabel(a) && isPolygonAnnotationLabel(b)) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Sorts classification labels so that OK-prefixed classes are before EMPTY-prefixed classes.
 */
function sortOKClassBeforeEMPTYClass(a: AnnotationLabel | AnnotationItem, b: AnnotationLabel | AnnotationItem): number {
  if (isPolygonAnnotationLabel(a) || isPolygonAnnotationLabel(b)) {
    return 0;
  }

  const aIsOk = a.id.startsWith(CLASS_OK);
  const bIsOk = b.id.startsWith(CLASS_OK);
  const aIsEmpty = a.id.startsWith(CLASS_EMPTY);
  const bIsEmpty = b.id.startsWith(CLASS_EMPTY);

  if (aIsOk && bIsEmpty) {
    return -1;
  } else if (aIsEmpty && bIsOk) {
    return 1;
  } else {
    return 0;
  }
}

function sortByNewestFirst(a: ImageDataAnnotation, b: ImageDataAnnotation): number {
  return b.createdAt - a.createdAt;
}

/**
 * Returns the label ID of the first label of the latest annotation for the model or the latest user annotation.
 * From the latest annotation, the annotation to get the labels from is determined as follows:
 * - Human annotations are always preferred over machine predictions, if available. If there are multiple human
 *   annotations, the newest one is used. The labels are then sorted so that OK-prefixed classes are before
 *  EMPTY-prefixed classes, and the first label ID is returned.
 * - If there are only machine predictions, we first check if they all have the same timestamp. If so, we use *all*
 *   annotations and assemble a list of labels that is then sorted so that polygon labels are sorted before class labels
 *  (polygons definitively indicate a defect or detected object). Then we sort the labels so that OK-prefixed classes are
 * before EMPTY-prefixed classes, and the first label ID is returned.
 * - If there are multiple machine predictions with different timestamps, we use the newest one and sort the labels
 * so that OK-prefixed classes are before EMPTY-prefixed classes, and the first label ID is returned.
 * - If there are no annotations, we returns null.
 */
function getThumbnailColorLabelId(annotations: LatestAnnotations, modelId?: string): string | null {
  if (modelId) {
    const relevantAnnotation = annotations[modelId]?.latestHumanAnnotation || annotations[modelId]?.machinePrediction;
    if (!relevantAnnotation?.labels) {
      return null;
    }
    return [...relevantAnnotation.labels].sort(sortOKClassBeforeEMPTYClass)[0].id;
  }

  const humanAnnotations: ImageDataAnnotation[] = [];
  const machinePredictions: ImageDataAnnotation[] = [];

  Object.values(annotations).forEach((annotation) => {
    if (annotation.latestHumanAnnotation) {
      humanAnnotations.push(annotation.latestHumanAnnotation);
    }
    if (annotation.machinePrediction) {
      machinePredictions.push(annotation.machinePrediction);
    }
  });

  if (humanAnnotations.length > 0) {
    humanAnnotations.sort(sortByNewestFirst);
    const labels = [...humanAnnotations[0].labels].sort(sortOKClassBeforeEMPTYClass);
    return labels[0].id;
  } else if (machinePredictions.length > 0) {
    machinePredictions.sort(sortByNewestFirst);

    // check if all machinePredictions have the same timestamp
    const allTimestampsEqual = machinePredictions.every(
      (prediction) => prediction.createdAt === machinePredictions[0].createdAt,
    );
    if (allTimestampsEqual) {
      // use labels from all models
      const allLabels = machinePredictions.flatMap((prediction) => prediction.labels);
      const labels = allLabels.sort(sortByPolygonLabelsFirst).sort(sortOKClassBeforeEMPTYClass);
      return labels[0].id;
    } else {
      // use labels from the model with the newest prediction
      const labels = [...machinePredictions[0].labels].sort(sortByPolygonLabelsFirst).sort(sortOKClassBeforeEMPTYClass);
      return labels[0].id;
    }
  }

  return null;
}

/**
 * Returns the color for the thumbnail outline based on the latest annotation for the model or the latest user annotation.
 * If no annotation is found, returns a fallback color.
 */
export function useThumbnailOutlineColor(image: ImageData): string {
  const {currentModel} = useCurrentModel();
  const projectLabels = useLabelItems({from: 'project'});

  return useMemo((): string => {
    const relevantLabelId = getThumbnailColorLabelId(image.annotations, currentModel?.id);
    return getColorFromItems(relevantLabelId ?? UNLABELED, currentModel?.labels ?? projectLabels);
  }, [currentModel, image, projectLabels]);
}

/**
 * Returns the number of **human** annotations of an image for the current model. If there is no current model, returns 0.
 */
export function useHumanAnnotationCount(image: ImageData): number {
  const {currentModel} = useCurrentModel();
  if (!currentModel) {
    return 0;
  }
  return image.annotations[currentModel.id]?.latestHumanAnnotation?.labels.length || 0;
}
