import {Box} from '@mui/material';

import {getAliasFromItems} from '~utils/miscUtils';
import {selectActiveHumanClassifications} from '~redux/reducers/annotatorReducer';
import {useAppSelector} from '~redux/index';

import {useLabelItems} from '~components/models/model.hooks';
import {CircleColorIcon} from '~components/settings/CircleColorIconButton';

export function CurrentClassificationIndicator(): JSX.Element | null {
  const classLabelList = useLabelItems({from: 'current-model-or-project', type: 'class'});
  const currentClassifications = useAppSelector(selectActiveHumanClassifications);
  const latestClassification = currentClassifications.at(-1);

  if (currentClassifications.length === 0 && classLabelList.length > 1) {
    return null;
  }

  const colorCode = latestClassification?.color ?? classLabelList[0].colorCode;
  const label = latestClassification ? latestClassification.labelId : classLabelList[0].id;
  return (
    <>
      <CircleColorIcon color={colorCode} circleSize={16} />
      <Box>{getAliasFromItems(label, classLabelList)}</Box>
    </>
  );
}
