import {AnnotationLabel, ImageData, LabelItem} from '~redux/types/images';
import {Model} from '~redux/types/models';

export const TOTAL_PREFIX = ' (Σ=';

export const getDisplayXLabels = (yLabels: string[], labelCounts: LabelCounts, productTypes: string[]): string[] => {
  if (productTypes.length < 1) {
    return productTypes;
  }
  return yLabels
    .reduce(
      (totalCounts: number[], currentLabel: string) =>
        labelCounts[currentLabel]?.productTypeCounts.map((count: number, index: number) => count + totalCounts[index]),
      zeros(productTypes.length),
    )
    .map((totalCount: number, index: number) => `${productTypes[index]}${TOTAL_PREFIX}${totalCount})`);
};

export const zeros = (length: number): number[] => Array.from(new Array(length), () => 0);
export const arraySum = (array: number[]): number =>
  array.reduce((total: number, currentValue: number) => total + currentValue, 0);

export type LabelCounts = {
  [label: string]: {
    displayName: string;
    condition: string;
    productTypeCounts: number[];
  };
};

export const getEmptyLabelCounts = (labels: LabelItem[], numProductTypes: number): LabelCounts =>
  labels.reduce(
    (accumulator: LabelCounts, label: LabelItem) => ({
      ...accumulator,
      [label.id]: {
        displayName: label.alias || label.id,
        condition: label.condition,
        productTypeCounts: zeros(numProductTypes),
      },
    }),
    {},
  );

export type LabelDistributionCounts = {
  imageCounts: LabelCounts;
  instanceCounts: LabelCounts;
};

type ProductTypeIndexMapping = {
  [productType: string]: number;
};

const buildProductTypeIndexMap = (productTypes: string[]): ProductTypeIndexMapping => {
  return productTypes.reduce(
    (accumulator: ProductTypeIndexMapping, productType: string, index: number) => ({
      ...accumulator,
      [productType]: index,
    }),
    {},
  );
};

export const getLabelDistributionCounts = ({
  currentModel,
  productTypes,
  imageData,
}: {
  currentModel: Model;
  productTypes: string[];
  imageData: ImageData[];
}): LabelDistributionCounts => {
  const {labels} = currentModel;

  if (labels.length < 1 || imageData.length < 1) {
    return {imageCounts: {}, instanceCounts: {}};
  }

  const productTypeIndexMapping = buildProductTypeIndexMap(productTypes);
  const labelCountsImage: LabelCounts = getEmptyLabelCounts(labels || [], Math.max(1, productTypes.length));
  const labelCountsInstance: LabelCounts = getEmptyLabelCounts(labels || [], Math.max(1, productTypes.length));

  imageData.forEach((image: ImageData) => {
    const productType = image.meta.productType;
    // If no product type is set on an image, assign it to the last product type which is the "noProductType" column
    const productIndex = productType ? productTypeIndexMapping[productType] : productTypes.length - 1;

    const {latestHumanAnnotation} = image.annotations[currentModel.id];
    const seenLabelsWithinItem = new Set<string>();
    latestHumanAnnotation?.labels.forEach((label: AnnotationLabel) => {
      if (!labelCountsInstance[label.id]) {
        return;
      }
      labelCountsInstance[label.id].productTypeCounts[productIndex] += 1;
      if (!seenLabelsWithinItem.has(label.id)) {
        labelCountsImage[label.id].productTypeCounts[productIndex] += 1;
        seenLabelsWithinItem.add(label.id);
      }
    });
  });
  return {imageCounts: labelCountsImage, instanceCounts: labelCountsInstance};
};

export const mapLabelCountsToApexSeries = (
  yLabels: string[],
  labelCounts: LabelCounts,
  isWithTotalCount = false,
): ApexAxisChartSeries =>
  [...yLabels]
    .sort(
      (labelA: string, labelB: string) =>
        arraySum(labelCounts[labelA]?.productTypeCounts) - arraySum(labelCounts[labelB]?.productTypeCounts),
    )
    .map((label: string) => ({
      // embed total value across all product types in display label
      name: isWithTotalCount
        ? `${labelCounts[label]?.displayName}${TOTAL_PREFIX}${arraySum(labelCounts[label]?.productTypeCounts)})`
        : labelCounts[label]?.displayName,
      data: labelCounts[label]?.productTypeCounts,
    }));
