import {DialogContent, DialogTitle, Divider, InputAdornment, TextField, Typography} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {useTranslation} from 'i18n';
import React, {useMemo, useState} from 'react';

import {getAliasFromItems} from '~utils/miscUtils';
import {LabelItem, LabelType} from '~redux/types/images';
import {Model} from '~redux/types/models';

import {LabelButton} from '~components/images/toolbar/LabelButton';
import {useLabelIds} from '~components/models/model.hooks';
import {SearchIcon} from 'src/icons';

interface ChooseLabelDialogContentProps {
  models: Model[];
  type: LabelType;
  onLabelSelect: (labelItem: LabelItem, modelId: string) => void;
}

export function ChooseLabelDialogContent({models, type, onLabelSelect}: ChooseLabelDialogContentProps) {
  const {t} = useTranslation('review');
  const labelIds = useLabelIds({from: 'current-model-or-project'});

  const [labelFilter, setLabelFilter] = useState('');
  const filteredLabels = useMemo(
    () =>
      models
        .map((model: Model) => {
          return {
            ...model,
            labels: model.labels
              .filter((labelItem: LabelItem): boolean => labelItem.type.toLowerCase() === type)
              .filter((labelItem: LabelItem): boolean => {
                const lowerCaseItemText = getAliasFromItems(labelItem.id, model.labels).toLowerCase();
                return lowerCaseItemText.includes(labelFilter.toLowerCase());
              })
              .filter((labelItem: LabelItem): boolean => labelIds.includes(labelItem.id)),
          };
        })
        .filter((model: Model) => model.labels.length > 0),
    [labelFilter, labelIds, models, type],
  );

  return (
    <>
      <DialogTitle sx={{pb: 1}}>{t('changeClassDialog.title')}</DialogTitle>
      <Typography sx={{px: 5, pb: 4, pt: 1}} variant='body1'>
        {t('changeClassDialog.description')}
      </Typography>
      <DialogContent dividers={true}>
        <List
          data-testid='choose-label-dialog-content-list'
          sx={{
            padding: 0,
            '& .MuiListItem-root': {padding: 0},
            '& .MuiListItem-root + .MuiListItem-root': {marginTop: 1},
          }}
        >
          <ListItem>
            <TextField
              size='small'
              fullWidth={true}
              onChange={(e): void => setLabelFilter(e.target.value)}
              data-testid='choose-label-search'
              placeholder={t('search')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>
          {filteredLabels.map((model: Model) => {
            return (
              <React.Fragment key={model.id}>
                <Divider sx={{paddingY: 3}} textAlign='left' data-testid='choose-label-model-name'>
                  {model.name}
                </Divider>
                {model.labels.map((labelItem: LabelItem) => {
                  return (
                    <ListItem key={labelItem.id} data-testid='choose-label-item'>
                      <LabelButton item={labelItem} onClick={() => onLabelSelect(labelItem, model.id)} />
                    </ListItem>
                  );
                })}
              </React.Fragment>
            );
          })}
        </List>
      </DialogContent>
    </>
  );
}
