import ky from 'ky';

import {ChangePasswordResponse, UserData} from '~redux/types/user';

import {getOptions} from './api';

export function getUser(): Promise<UserData> {
  return ky.get(`${process.env.AUTH_API}/users/me`, getOptions({signalId: 'getUser'})).json();
}

export function changePassword(oldPassword: string, newPassword: string): Promise<ChangePasswordResponse> {
  const options = getOptions({signalId: 'changePassword', data: {old: oldPassword, new: newPassword}});
  return ky.put(`${process.env.AUTH_API}/users/change-password`, options).json();
}

export function setDefaultProject(projectId: string): Promise<UserData> {
  const options = getOptions({signalId: 'setDefaultProject', data: {project: projectId}});
  return ky.put(`${process.env.AUTH_API}/users/default-project`, options).json();
}

export function setUserPreferences(landingPage: string): Promise<UserData> {
  const options = getOptions({signalId: 'setUserPreferences', data: {landingPage}});
  return ky.put(`${process.env.AUTH_API}/users/preference`, options).json();
}
