import {Box, Checkbox, CheckboxProps, ListItemText, MenuItem, MenuItemProps} from '@mui/material';
import {useId} from 'react';

import {styled} from 'src/constants/theme';
import {Chip} from '../Chip';

interface CheckboxMenuItemProps extends MenuItemProps, Pick<CheckboxProps, 'checked' | 'indeterminate'> {
  title: string;
  modelLabel?: string;
  isLeaf?: boolean;
  /**
   * The browser-native tooltip to show. Uses the title by default.
   */
  tooltip?: string;
  /**
   * Visually indent the MenuItem by `theme.spacing(<factor> * indent)`
   */
  indent?: number;
  CheckboxProps?: Omit<CheckboxProps, 'checked' | 'indeterminate'>;
}

const StyledMenuItem = styled(MenuItem, {shouldForwardProp: (prop) => prop !== 'indent'})<
  Omit<CheckboxMenuItemProps, 'title' | 'checkboxProps'>
>(({theme, indent}) => ({
  paddingLeft: indent === undefined ? theme.spacing(2) : theme.spacing(5 * indent),
  '&.Mui-selected': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
}));

/**
 * Customized MUI MenuItem that has a MUI Checkbox.
 * This component is intended to be used inside a MUI Select with `multiple={true}`.
 */
export function CheckboxMenuItem({
  title,
  modelLabel,
  isLeaf,
  tooltip,
  checked,
  indeterminate,
  CheckboxProps,
  sx,
  ...rest
}: CheckboxMenuItemProps) {
  const id = useId();

  return (
    <StyledMenuItem title={tooltip} sx={[{paddingLeft: 2}, ...(Array.isArray(sx) ? sx : [sx])]} {...rest}>
      <Checkbox
        inputProps={{'aria-labelledby': id}}
        sx={{paddingY: 0}}
        checked={checked}
        indeterminate={indeterminate}
        {...CheckboxProps}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ListItemText
          id={id}
          primary={title}
          primaryTypographyProps={{
            style: {
              maxWidth: '200px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '14px',
            },
          }}
          disableTypography={!isLeaf}
        />
        {modelLabel && (
          <Chip
            sx={{
              '&.MuiChip-root': {
                textTransform: 'uppercase',
                maxWidth: '160px',
                ml: 1,
                borderRadius: 1,
                height: '18px',
                fontSize: '10px',
              },
            }}
            label={modelLabel}
            variant={'filled'}
            title={modelLabel}
          />
        )}
      </Box>
    </StyledMenuItem>
  );
}
