import ky from 'ky';

import {InvitedUser, Project, ProjectCreationResponse, Tag, User} from '~redux/types/user';

import {UserRole} from 'src/contexts/AbilitiesContext';
import {getOptions} from './api';

export const createProject = ({name, description}: {name: string; description: string}) =>
  ky
    .post(`${process.env.AUTH_API}/projects/create`, getOptions({signalId: 'createProject', data: {name, description}}))
    .json<ProjectCreationResponse>();

export const getProjectData = (projectId: string) =>
  ky.get(`${process.env.AUTH_API}/projects/${projectId}`, getOptions({signalId: 'getProjectData'})).json<Project>();

export const addTag = (projectId: string, tag: string) =>
  ky
    .put(`${process.env.AUTH_API}/projects/${projectId}/tags`, getOptions({signalId: 'addTag', data: {tag}}))
    .json<Tag>();

export const deleteTag = (projectId: string, tag: string) =>
  ky.delete(`${process.env.AUTH_API}/projects/${projectId}/tags/${tag}`, getOptions({signalId: 'deleteTag'}));

export const removeUser = ({projectId, userId}: {projectId: string; userId: string}) =>
  ky
    .delete(`${process.env.AUTH_API}/projects/${projectId}/users/${userId}`, getOptions({signalId: 'removeUser'}))
    .json<Project>();

export const inviteUsers = (projectId: string, users: User[]) =>
  ky
    .post(
      `${process.env.AUTH_API}/projects/${projectId}/users/invite`,
      getOptions({signalId: 'removeUser', data: {users}}),
    )
    .json<InvitedUser[]>();

export const setUserRole = ({projectId, userId, role}: {projectId: string; userId: string; role: UserRole}) =>
  ky
    .put(
      `${process.env.AUTH_API}/projects/${projectId}/role`,
      getOptions({signalId: 'setUserRole', data: {userId, role}}),
    )
    .json<Project>();
