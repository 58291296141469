export const mapStringNullToNull = (payload: MetadataFiltersPayload): MetadataFiltersPayload => {
  const mappedPayload: MetadataFiltersPayload = {};

  for (const key in payload) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      mappedPayload[key] = payload[key]?.map((value) => (value === 'null' ? null : value));
    }
  }

  return mappedPayload;
};
