import {createContext, Dispatch, SetStateAction, useContext} from 'react';

interface ImagesCacheContextValue {
  loadImagesFromCache: boolean;
  setCachedUrl: Dispatch<SetStateAction<string | undefined>>;
}

const defaultContextValue: ImagesCacheContextValue = {
  loadImagesFromCache: false,
  setCachedUrl: () => undefined,
};

/**
 * @private Do not use this context directly. Use the `useImagesCache` hook instead.
 * This context is used to determine whether the image overview should load images from the cache or not.
 */
export const ImagesCacheContext = createContext<ImagesCacheContextValue>(defaultContextValue);

export function useImagesCache(): ImagesCacheContextValue {
  const imagesCacheContext = useContext(ImagesCacheContext);
  return imagesCacheContext as ImagesCacheContextValue;
}
