export type BinSize = 'hours' | 'days' | 'weeks' | 'months';

export enum RelativeTimeframe {
  hours6 = '6h',
  hours12 = '12h',
  hours24 = '24h',
  days7 = '7d',
  days14 = '14d',
  days30 = '30d',
}

export interface MonitorToolbarFilters {
  isOkHidden: boolean;
  format: 'absolute' | 'percentage';
  timeframe: RelativeTimeframe | string;
  shifts?: Shift[];
  productTypes?: string[]; // undefined selects all product types
  devices?: string[];
  meta?: Record<string, string[] | undefined>;
  binSize?: BinSize;
}

export interface Perspective {
  id: number;
  name: string;
  alias: string | null;
}

export interface AutomlPipeline {
  automlPipelineId: string;
  timestamp: number;
  version: string;
  modelId: string;
}

export interface PolygonAreaSize {
  minArea: number;
  maxArea: number;
}

export interface ModelWithPolygonAreaSize {
  [modelId: string]: PolygonAreaSize;
}
