import {ReactNode} from 'react';

import {AbilitiesContext, getAbility} from 'src/contexts/AbilitiesContext';

interface AbilitiesContextProps {
  children?: ReactNode;
}

export function AbilitiesContextProvider({children}: AbilitiesContextProps) {
  const value = getAbility();
  return <AbilitiesContext.Provider value={value}>{children}</AbilitiesContext.Provider>;
}
