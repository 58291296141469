import React from 'react';

import {CheckboxMenuItem} from '~components/common/menu-items/CheckboxMenuItem';
import {
  CheckboxLabelItem,
  CheckboxState,
  ItemState,
} from '~components/common/toolbar/selects/class-select/NestedClassListParent';

interface NestedClassListProps {
  items: CheckboxLabelItem[];
  itemStates: ItemState[];
  idsToRender?: string[];
  indentLevel?: number;
  onClick: (event: React.MouseEvent, itemId: string) => void;
}

export function NestedClassList({items, itemStates, onClick, idsToRender = [], indentLevel = 0}: NestedClassListProps) {
  if (idsToRender.length === 0) {
    // Select all root nodes
    idsToRender = items.filter((item) => !item.parentId).map((item) => item.id);
  }

  const getItemState = (id: string) => {
    return itemStates.find((i) => i.id === id)!.state;
  };

  const getChildNodes = (parentId: string) => {
    const childNodes = items.filter((i) => i.parentId === parentId);
    if (childNodes.length === 0) {
      return null;
    } else {
      return (
        <NestedClassList
          items={items}
          idsToRender={childNodes.map((i) => i.id)}
          indentLevel={indentLevel + 1}
          onClick={onClick}
          itemStates={itemStates}
        />
      );
    }
  };

  return (
    <>
      {idsToRender.map((id) => {
        const item = items.find((i) => i.id === id)!;
        const state = getItemState(id);
        const hasChildren = !item.isLeaf && item.parentId !== undefined;
        return (
          <React.Fragment key={item.id}>
            <CheckboxMenuItem
              value={item.id}
              title={item.alias || item.id}
              modelLabel={item.modelLabel}
              isLeaf={item.isLeaf}
              checked={state === CheckboxState.CHECKED}
              indeterminate={state === CheckboxState.INDETERMINATE}
              sx={{
                paddingLeft: 2 + indentLevel * 3.5,
                paddingRight: 8,
                marginY: hasChildren ? 0.5 : 0,
              }}
              onClick={(event) => onClick(event, item.id)}
            />
            {getChildNodes(id)}
          </React.Fragment>
        );
      })}
    </>
  );
}
