import {createAction} from '@reduxjs/toolkit';

import {GLOBAL_LOADING, RESET} from '~redux/types';
import {PayloadAction} from '~redux/types/main';
import {GlobalLoadingPayload} from '~redux/reducers/globalReducer';

export const increaseGlobalLoadingIndicator = (message?: string): PayloadAction => ({
  type: GLOBAL_LOADING,
  payload: {counter: 1, message: message} as GlobalLoadingPayload,
});
export const decreaseGlobalLoadingIndicator = (): PayloadAction => ({type: GLOBAL_LOADING, payload: {counter: -1}});

export const resetToInitialState = createAction(RESET);
