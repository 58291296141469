import {AnyAction} from 'redux';

import {ReduxState} from '~redux/index';

import {
  CONFIG_ALERTS,
  CONFIG_ALERTS_ERROR,
  CONFIG_DEVICES,
  CONFIG_DEVICES_ERROR,
  CONFIG_LABELS,
  CONFIG_LABELS_ERROR,
  CONFIG_REPORTS,
  CONFIG_REPORTS_ERROR,
  CONFIG_SHIFTS,
  CONFIG_SHIFTS_ERROR,
  RESET,
} from '../types';
import {CreatedAlertConfig, CreatedReportConfig, ReduxStateReportConfigs} from '../types/configs';
import {LabelItem} from '../types/images';
import {Device} from '../types/user';

export const initialState: ReduxStateReportConfigs = {
  reportsConfigs: [],
  alertsConfigs: [],
  labelsConfigs: [],
  shiftsConfigs: [],
  devicesConfigs: [],
  error: '',
};

export const reducerName = 'configs';

const configsReducer = (
  state: ReduxStateReportConfigs = initialState,
  action: ReportConfigAction | AlertConfigAction | LabelsConfigAction | ShiftsConfigAction | DevicesConfigAction,
): ReduxStateReportConfigs => {
  switch (action.type) {
    case RESET:
      return initialState;
    case CONFIG_REPORTS:
      return {...state, reportsConfigs: action.payload as CreatedReportConfig[]};
    case CONFIG_REPORTS_ERROR:
    case CONFIG_SHIFTS_ERROR:
    case CONFIG_ALERTS_ERROR:
    case CONFIG_LABELS_ERROR:
    case CONFIG_DEVICES_ERROR:
      return {...state, error: action.payload as string};
    case CONFIG_ALERTS:
      return {...state, alertsConfigs: action.payload as CreatedAlertConfig[]};
    case CONFIG_LABELS:
      return {...state, labelsConfigs: action.payload as LabelItem[]};
    case CONFIG_SHIFTS:
      return {...state, shiftsConfigs: action.payload as ShiftStringBased[]};
    case CONFIG_DEVICES:
      return {...state, devicesConfigs: action.payload as Device[]};
    default:
      return state;
  }
};

export default configsReducer;

// ### REPORTS CONFIGS

export interface ReportConfigAction extends AnyAction {
  payload: CreatedReportConfig[] | string;
}

export const getConfigStateReportConfigs = (state: ReduxState): CreatedReportConfig[] =>
  state[reducerName].reportsConfigs;
export const getReportsConfigStateError = (state: ReduxState): string => state[reducerName].error;

// ### ALERTS CONFIGS

export interface AlertConfigAction extends AnyAction {
  payload: CreatedAlertConfig[] | string;
}

export const getConfigStateAlertConfigs = (state: ReduxState): CreatedAlertConfig[] => state[reducerName].alertsConfigs;
export const getAlertsConfigStateError = (state: ReduxState): string => state[reducerName].error;

// ### LABELS CONFIGS

export interface LabelsConfigAction extends AnyAction {
  payload: LabelItem[] | string;
}

export const getConfigStateLabelConfigs = (state: ReduxState): LabelItem[] => state[reducerName].labelsConfigs;
export const getLabelsConfigStateError = (state: ReduxState): string => state[reducerName].error;

// ### SHIFTS CONFIGS

export interface ShiftsConfigAction extends AnyAction {
  payload: ShiftStringBased[] | string;
}

export const getConfigStateShiftConfigs = (state: ReduxState): ShiftStringBased[] => state[reducerName].shiftsConfigs;
export const getShiftsConfigStateError = (state: ReduxState): string => state[reducerName].error;

// ### DEVICES CONFIGS

export interface DevicesConfigAction extends AnyAction {
  payload: Device[] | string;
}

export const getConfigStateDeviceConfigs = (state: ReduxState): Device[] => state[reducerName].devicesConfigs;
