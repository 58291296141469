import {CancelledError} from '@tanstack/query-core';
import {i18n} from 'i18n';
import {AnyAction, Dispatch} from 'redux';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';

import * as API from '~api/images';
import {ImageQueryKeys} from '~api/images.queries';
import {getModels} from '~api/models';
import {ModelsQueryKeys} from '~api/models.queries';
import {splitImageOrderBy} from '~utils/miscUtils';
import {IMAGES_FETCH_ERROR} from '~redux/types';
import {Model} from '~redux/types/models';
import {Session} from '~redux/types/session';
import {annotatorActions} from '~redux/reducers/annotatorReducer';
import {setError} from '~redux/actions/errorActions';
import {AppDispatch, ReduxState} from '~redux/index';

import {queryClient} from '~pages/_app';
import {showNotification} from '~components/common/Notification';
import {CLASS_OK, IMAGE_OVERVIEW_ITEMS_PER_PAGE} from 'src/constants/constants';
import {shuffle} from 'src/utils/shuffle';
import {getUtilStateIsLoading} from '../reducers/globalReducer';
import {imageDataActions, selectImagesData, selectImagesTotalCount} from '../reducers/imageReducer';
import {selectCurrentProjectId, selectUserEmail} from '../reducers/userReducer';
import {
  ImageData,
  ImageFilters,
  ImageLabelPayloadByModel,
  ImageMeta,
  ImageMetadata,
  ImageOrderBy,
  ImagesData,
  LabelItem,
  PaginationActionWithTargetPage,
  UpdateImagesDataPayload,
  UpdateImagesDataPayloadItem,
} from '../types/images';
import {fetchFilters} from './filtersActions';
import {decreaseGlobalLoadingIndicator, increaseGlobalLoadingIndicator} from './globalActions';
import {fetchUserData} from './userActions';

export const setImageIndexWhenNotLoading =
  (imageIndex: number) =>
  (dispatch: Dispatch, getState: () => ReduxState): void => {
    if (getUtilStateIsLoading(getState())) {
      return;
    }

    dispatch(imageDataActions.setImageIndex(imageIndex));
  };

/**
 * Use react-query to fetch images data based on all provided arguments. See explanation in {@link fetchFilteredImagesData}.
 */
export async function fetchImagesData({
  projectId,
  payload,
  targetPage,
  bustCache = false,
}: {
  projectId: string;
  payload: API.ImagesDataPayload;
  targetPage?: number;
  bustCache?: boolean;
}): Promise<ImagesData> {
  // Results of cursor based pagination can be cached if
  // a) a cursor exists AND
  // b) images are sorted by time
  const {sortBy, sortOrder, count, action} = payload.pagination ?? {};
  const hasPaginationCursor = Boolean(action?.cursor);
  const isSortedByTime = Boolean(!sortBy || sortBy.includes('TIMESTAMP'));
  const canBeCached = !bustCache && hasPaginationCursor && isSortedByTime;

  // Set the default cache key to page 1. By doing so the response data from the initial request on the image overview page
  // will be written to the cache of page 1. This is done to enable caching when navigating back from second to first page.
  const pageCacheKey = targetPage ?? 1;
  const queryKey = ImageQueryKeys.imagesDataFilterPaginated(
    projectId,
    payload.filters,
    {sortBy, sortOrder, count},
    pageCacheKey,
  );

  if (bustCache && targetPage) {
    // When busting the cache, invalidate all pages that are greater or equal to the current page.
    const currentQueryKeyWithoutPage = queryKey.slice(0, queryKey.length - 1);
    const currentQueryKeyPage = queryKey.at(-1)!;

    await queryClient.invalidateQueries({
      predicate: (query) => {
        const queryKeyToCheck = query.queryKey as string[];
        const queryKeyWithoutPage = queryKeyToCheck.slice(0, queryKeyToCheck.length - 1);
        const queryKeyPage = queryKeyToCheck.at(-1)!;
        return (
          JSON.stringify(queryKeyWithoutPage) === JSON.stringify(currentQueryKeyWithoutPage) &&
          queryKeyPage >= currentQueryKeyPage
        );
      },
    });
  } else if (!hasPaginationCursor) {
    // In case images are requested without a given reference cursor, clear all cache data that matches the requested filters.
    // This is done to ensure that data is always fresh when navigating away and back to the archive or defect-book.
    // If a cursor is specified, the cache is still valid as we have a save reference to use for pagination.
    await queryClient.invalidateQueries({queryKey: ImageQueryKeys.imagesDataFilter(projectId, payload.filters)});
  }

  return await queryClient.fetchQuery(queryKey, ({signal}) => API.getImagesData(projectId, payload, signal), {
    staleTime: canBeCached ? 1000 * 60 * 10 : 0,
  });
}

/**
 * This fetches all data and sets up the Redux store to show a single image (fetched by object ID) in the image annotator
 */
export const fetchImagesDataByImageID =
  (objectId: string, similaritySearch?: SimSearchFilter): ThunkAction<Promise<void>, ReduxState, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
    try {
      const state = getState();
      const projectId = selectCurrentProjectId(state);

      const [newImagesData] = await Promise.all([
        fetchImagesData({
          projectId,
          payload: {filters: {objectId, ...(similaritySearch && {similaritySearch})}, pagination: {count: 1}},
        }),
        dispatch(fetchFilters()), // Required for product types
        dispatch(fetchUserData()), // Required for tags and training tags (dataset versions))
      ]);
      await queryClient.fetchQuery({
        queryKey: ModelsQueryKeys.allModels(projectId),
        queryFn: ({signal}) => getModels(projectId, signal),
      }); // Required for models & label items

      dispatch(imageDataActions.setImages(newImagesData));
      dispatch(imageDataActions.setImageIndex(0));
    } catch (error: any) {
      dispatch(setError(IMAGES_FETCH_ERROR, error));
    }
  };

async function fetchFilteredImagesData({
  state,
  imageFilters,
  count,
  session,
  paginationActionWithTargetPage,
  bustCache,
}: FetchImagesArgs & {state: ReduxState; imageFilters: ImageFilters}): Promise<ImagesData> {
  const userEmail = selectUserEmail(state);
  const projectId = selectCurrentProjectId(state);
  const {orderBy: orderByArg, confusionMatrixCellID: _confusionMatrixCellID, ...filters} = imageFilters;

  const orderBy = window.location.href.includes('model/samples')
    ? orderByArg ?? ImageOrderBy.ANNOTATION_TIMESTAMP_DESC
    : orderByArg;

  /**
   * NOTE:
   *
   * Use react-query for fetching the images data so that the response will be cached based on the query key.
   * Generally, Redux caches the returned images data already, but the cache is shared between archive and defect-book
   * and we don't keep track of the query args in Redux. Therefor we never know if the images data stored in Redux belongs
   * to archive or defect-book.
   *
   * By using react-query as an additional caching layer THAT KNOWS if the data belongs to archive or defect-book by
   * the arguments being used to query the API, we don't need to refactor the Redux state. Also it provides a foundation
   * for fully switching to react-query for API data caching.
   */
  const result = await fetchImagesData({
    projectId,
    bustCache,
    payload: {
      filters: {
        ...filters,
        includeAnnotationHistory: session?.type === 'image' ? true : undefined,
        sessionTag: session?.tag,
      },
      pagination: {
        ...splitImageOrderBy(orderBy),
        action: paginationActionWithTargetPage?.action,
        count,
      },
    },
    targetPage: paginationActionWithTargetPage?.targetPage,
  });

  if (session && userEmail) {
    // we have a session, so we need to shuffle the data based on the users email
    result.data = shuffle(result.data, userEmail);
  }

  return result;
}

export interface FetchImagesArgs {
  withGlobalLoading?: boolean;
  withDiffCheck?: boolean;
  imageFilters?: ImageFilters;
  count?: number;
  session?: Session;
  paginationActionWithTargetPage?: PaginationActionWithTargetPage;
  bustCache?: boolean;
}

export const updateImagesData =
  ({
    withGlobalLoading = false,
    withDiffCheck = false,
    imageFilters = {},
    count = IMAGE_OVERVIEW_ITEMS_PER_PAGE,
    paginationActionWithTargetPage = undefined,
    session = undefined,
    bustCache,
  }: FetchImagesArgs = {}): ThunkAction<Promise<void>, ReduxState, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
    try {
      if (withGlobalLoading) {
        dispatch(increaseGlobalLoadingIndicator());
      }

      const state = getState();
      const newImagesData = await fetchFilteredImagesData({
        state,
        imageFilters,
        count,
        paginationActionWithTargetPage,
        session,
        bustCache,
      });
      // Total is returned only if no cursor is provided to the backend, i.e. on initial load. Thus, use the previous
      // total response if no total is contained in the response.
      if (newImagesData.total === undefined) {
        newImagesData.total = selectImagesTotalCount(state);
      }

      if (!withDiffCheck || (withDiffCheck && !isEqual(selectImagesData(state), newImagesData))) {
        dispatch(imageDataActions.setImages(newImagesData));
      }
    } catch (error: any) {
      dispatch(setError(IMAGES_FETCH_ERROR, error));
    } finally {
      if (withGlobalLoading) {
        dispatch(decreaseGlobalLoadingIndicator());
      }
    }
  };

function isEqual(oldImagesData: ImagesData | null, newImagesData: ImagesData) {
  const oldData = (oldImagesData?.data || []).map(removeImageURLQueryParams);
  const newData = (newImagesData.data || []).map(removeImageURLQueryParams);
  return JSON.stringify(oldData) === JSON.stringify(newData);
}

function removeImageURLQueryParams(imageData: ImageData): ImageData {
  const dataData = imageData?.data || '';
  const dataThumbData = imageData.thumbData || '';

  const data = dataData.split('?')[0];
  const thumbData = dataThumbData.split('?')[0];

  return {...imageData, data, thumbData};
}

const fetchAllImagesData =
  (fetchArgs: FetchImagesArgs = {}): ThunkAction<Promise<void>, ReduxState, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
    const projectId = selectCurrentProjectId(getState());

    try {
      // Fetch new data
      await Promise.all([
        dispatch(fetchFilters()), // gets product types
        queryClient.fetchQuery({
          queryKey: ModelsQueryKeys.allModels(projectId),
          queryFn: ({signal}) => getModels(projectId, signal),
        }), // Required for models & label items
        dispatch(fetchUserData()), // Tags and training tags (dataset versions) are retrieved from here
      ]);
      await dispatch(updateImagesData(fetchArgs));
    } catch (error: any) {
      // in case the error is a CancelledError proceed silently. This happens e.g. when
      // the react query fetch is cancelled because of deduping of requests.
      if (error instanceof CancelledError) {
        await dispatch(updateImagesData(fetchArgs));
        return;
      }
      dispatch(setError(IMAGES_FETCH_ERROR, error));
    } finally {
      dispatch(annotatorActions.setImageReadOnly(false));
    }
  };

export const resetAndFetchAllImagesData =
  (fetchArgs: FetchImagesArgs = {}): ThunkAction<Promise<void>, ReduxState, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, _getState: () => ReduxState): Promise<void> => {
    dispatch(imageDataActions.resetImages());
    await dispatch(fetchAllImagesData(fetchArgs));
  };

export const classifyImages = ({
  images,
  classLabelItem,
  modelId,
}: {
  images: ImageData[];
  classLabelItem: LabelItem;
  modelId: string;
}) => {
  return async (dispatch: AppDispatch, getState: () => ReduxState): Promise<void> => {
    try {
      const payload: UpdateImagesDataPayload = images.map((imageData): UpdateImagesDataPayloadItem => {
        return {
          imageId: imageData.id,
          labelsByModel: {
            [modelId]: [{id: classLabelItem.id, type: classLabelItem.type}],
          },
        };
      });

      dispatch(increaseGlobalLoadingIndicator(i18n.t('predictionIsSaved')));

      const projectID = selectCurrentProjectId(getState());
      const updatedLabels = await API.putImagesData(projectID, payload);
      dispatch(imageDataActions.updateImageAnnotations(updatedLabels));
      showNotification({
        severity: 'success',
        message: i18n.t('classAssignedSuccessfully', {
          name: classLabelItem.alias ?? classLabelItem.id,
          count: payload.length,
          ns: 'review',
        }),
      });
    } catch (error: any) {
      showNotification({severity: 'error', message: i18n.t('genericError', {ns: 'error'})});
    } finally {
      dispatch(decreaseGlobalLoadingIndicator());
    }
  };
};

export const bulkClassifyImages = ({
  filters,
  labelsByModel,
}: {
  filters: ImageFilters;
  labelsByModel: ImageLabelPayloadByModel;
}) => {
  return async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
    try {
      dispatch(increaseGlobalLoadingIndicator(i18n.t('predictionIsSaved')));

      const projectID = selectCurrentProjectId(getState());
      await API.bulkAssignClass(projectID, {filters, labelsByModel});
      dispatch(imageDataActions.resetImages());
    } catch (error: any) {
      showNotification({severity: 'error', message: i18n.t('genericError', {ns: 'error'})});
    } finally {
      dispatch(decreaseGlobalLoadingIndicator());
    }
  };
};

export const classifyImageWithDefaultMachineLabel = ({image, models}: {image: ImageData; models?: Model[]}) => {
  return async (dispatch: AppDispatch, getState: () => ReduxState): Promise<void> => {
    try {
      const payload: UpdateImagesDataPayload = [
        {
          imageId: image.id,
          labelsByModel: models?.reduce((aggLabels, model) => {
            return {
              ...aggLabels,
              [model.id]: [{id: model.defaultMachineLabel, type: 'class'}],
            };
          }, {}),
        },
      ];

      dispatch(increaseGlobalLoadingIndicator(i18n.t('predictionIsSaved')));

      const projectID = selectCurrentProjectId(getState());
      const updatedLabels = await API.putImagesData(projectID, payload);
      dispatch(imageDataActions.updateImageAnnotations(updatedLabels));
      showNotification({
        severity: 'success',
        message: i18n.t('classAssignedSuccessfully', {
          name: CLASS_OK,
          count: payload.length,
          ns: 'review',
        }),
      });
    } catch (error: any) {
      showNotification({severity: 'error', message: i18n.t('genericError', {ns: 'error'})});
    } finally {
      dispatch(decreaseGlobalLoadingIndicator());
    }
  };
};

export const deleteImages = (itemIDs: string[] = []): ThunkAction<Promise<void>, ReduxState, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
    try {
      dispatch(increaseGlobalLoadingIndicator());
      const projectID = selectCurrentProjectId(getState());

      await API.deleteImages(projectID, itemIDs);

      // delete images from cached images data
      dispatch(imageDataActions.deleteImages(itemIDs));
    } catch (error: any) {
      dispatch(setError(IMAGES_FETCH_ERROR, error));
    } finally {
      dispatch(decreaseGlobalLoadingIndicator());
    }
  };
};

export const tagImages = ({imageIds, tag}: {imageIds: string[]; tag: string | string[]}) => {
  return async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
    try {
      const state = getState();
      const projectID = selectCurrentProjectId(state);
      const tags = Array.isArray(tag) ? tag : [tag];
      await API.tagImages(projectID, {ids: imageIds, tags});
      dispatch(imageDataActions.addTag({imageIds, tag: tags}));
    } catch (error: any) {
      showNotification({severity: 'error', message: i18n.t('genericError', {ns: 'error'})});
    }
  };
};

export const untagImages = (imageIds: string[], tag: string | string[]) => {
  return async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
    try {
      const state = getState();

      const projectID = selectCurrentProjectId(state);
      const tags = Array.isArray(tag) ? tag : [tag];

      await API.untagImages(projectID, {ids: imageIds, tags});
      dispatch(imageDataActions.removeTag({imageIds, tag: tags}));
    } catch (error: any) {
      showNotification({severity: 'error', message: i18n.t('genericError', {ns: 'error'})});
    }
  };
};

export const bulkTagImages = ({filters, tag}: {filters: ImageFilters; tag: string | string[]}) => {
  return async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
    try {
      const state = getState();
      const projectID = selectCurrentProjectId(state);
      const tags = Array.isArray(tag) ? tag : [tag];

      await API.bulkTagImages(projectID, {filters, tags});
      dispatch(imageDataActions.resetImages());
    } catch (error: any) {
      showNotification({severity: 'error', message: i18n.t('genericError', {ns: 'error'})});
    }
  };
};

export const bulkUntagImages = ({filters, tag}: {filters: ImageFilters; tag: string | string[]}) => {
  return async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
    try {
      const state = getState();
      const projectID = selectCurrentProjectId(state);
      const tags = Array.isArray(tag) ? tag : [tag];

      await API.bulkUntagImages(projectID, {filters, tags});
      dispatch(imageDataActions.resetImages());
    } catch (error: any) {
      showNotification({severity: 'error', message: i18n.t('genericError', {ns: 'error'})});
    }
  };
};

export const updateMeta = (imagesMeta: ImageMeta[]) => {
  return async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
    try {
      dispatch(increaseGlobalLoadingIndicator());
      const state = getState();
      const projectID = selectCurrentProjectId(state);
      const isOnlyProductTypeChanges = imagesMeta.every((imageMeta) => imageMeta.meta?.productType);
      await API.updateMeta(projectID, imagesMeta);

      dispatch(imageDataActions.addMeta(imagesMeta));
      await dispatch(fetchFilters() as any);

      if (isOnlyProductTypeChanges) {
        showNotification({
          severity: 'success',
          message: i18n.t('productTypeAssignedSuccessfully', {
            name: imagesMeta[0].meta?.productType,
            count: imagesMeta.length,
            ns: 'review',
          }),
        });
      }
    } catch (error: any) {
      showNotification({severity: 'error', message: i18n.t('genericError', {ns: 'error'})});
    } finally {
      dispatch(decreaseGlobalLoadingIndicator());
    }
  };
};

export const bulkUpdateMeta = ({filters, meta}: {filters: ImageFilters; meta: ImageMetadata}) => {
  return async (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
    try {
      dispatch(increaseGlobalLoadingIndicator());
      const state = getState();
      const projectID = selectCurrentProjectId(state);
      await API.bulkUpdateMeta(projectID, {filters, meta});
      await dispatch(fetchFilters() as any);
    } catch (error: any) {
      showNotification({severity: 'error', message: i18n.t('genericError', {ns: 'error'})});
    } finally {
      dispatch(decreaseGlobalLoadingIndicator());
    }
  };
};
