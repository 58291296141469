import {Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps} from '@mui/material';
import React from 'react';

export interface TooltipProps extends Omit<MuiTooltipProps, 'title'> {
  children: React.ReactElement<any, any>;
  title: MuiTooltipProps['title'];
  isWidthUnconstrained?: boolean;
}

export function Tooltip({children, isWidthUnconstrained, title, sx, ...rest}: TooltipProps): JSX.Element {
  return (
    <MuiTooltip
      title={title}
      PopperProps={{
        sx: [
          {
            '.MuiTooltip-tooltip': {
              maxWidth: isWidthUnconstrained ? 'none' : undefined,
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ],
      }}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
}
