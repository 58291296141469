import {createSelector} from '@reduxjs/toolkit';

import {ReduxState} from '~redux/index';

import {APIFiltersAction} from '../actions/filtersActions';
import {API_FILTERS_ERROR, MONITOR_STATE, RESET, SET_API_FILTERS, UPDATE_PERSPECTIVE} from '../types';
import {ReduxStateApplicableAPIFilters} from '../types/filters';
import {ErrorAction} from '../types/main';

export const initialState: ReduxStateApplicableAPIFilters = {
  automlPipelines: [],
  labelers: [],
  format: [],
  shifts: [],
  timeframe: [],
  productTypes: [],
  perspectives: [],
  serialNumber: false,
  devices: [],
  meta: {},
  errors: {},
  humanPolygonAreaSizes: {},
};

const filtersReducer = (
  state: ReduxStateApplicableAPIFilters = initialState,
  action: APIFiltersAction,
): ReduxStateApplicableAPIFilters => {
  switch (action.type) {
    case RESET:
      return initialState;
    case SET_API_FILTERS:
      return {...state, ...(action.payload as ReduxStateApplicableAPIFilters)};
    case API_FILTERS_ERROR: {
      const errorAction = action as ErrorAction;

      const errors: any = {...state.errors};
      errors[errorAction.subtype] = action.payload;

      return {...state, errors: errors};
    }
    case UPDATE_PERSPECTIVE: {
      return {...state, ...action.payload};
    }
    default:
      return state;
  }
};

export default filtersReducer;

export const reducerName = 'filters';

export const getFiltersState = (state: ReduxState): ReduxStateApplicableAPIFilters => state[reducerName];
export const selectLabelers = createSelector(getFiltersState, (state) => state.labelers);
export const selectDevices = createSelector(getFiltersState, (state) => state.devices);
export const selectShifts = createSelector(getFiltersState, (state) => {
  return [...state.shifts].sort(fromEarlyToLate);
});
export const selectPerspectives = createSelector([getFiltersState], (filters) => filters?.perspectives);

export const selectProductTypes = createSelector(getFiltersState, (state) => state.productTypes);

export const selectAutomlPipelines = createSelector(getFiltersState, (state) => state.automlPipelines);

export const selectMetadata = createSelector(getFiltersState, (state) => state.meta);

export const selectHumanPolygonAreaSizes = createSelector(getFiltersState, (state) => state.humanPolygonAreaSizes);

const getHasError = (state: ReduxState, type: string): string => state[reducerName].errors[type] || '';
export const getFiltersStateError = (state: ReduxState): string => getHasError(state, MONITOR_STATE);

function fromEarlyToLate(a: ShiftWithName, b: ShiftWithName): number {
  const aMinutes = Number.parseInt(a.from.slice(0, 2)) + 60 * Number.parseInt(a.from.slice(2, 4));
  const bMinutes = Number.parseInt(b.from.slice(0, 2)) + 60 * Number.parseInt(b.from.slice(2, 4));

  return aMinutes < bMinutes || a.from === a.to || b.from === b.to ? -1 : 1;
}
