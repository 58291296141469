import {Box, Stack} from '@mui/material';
import {useTranslation} from 'i18n';
import React, {useState} from 'react';

import {LabelItem} from '~redux/types/images';
import * as annotatorReducer from '~redux/reducers/annotatorReducer';
import {annotatorActions} from '~redux/reducers/annotatorReducer';
import {selectUserEmail} from '~redux/reducers/userReducer';
import {useAppDispatch, useAppSelector} from '~redux/index';

import {ToolbarElementProps} from '~components/common/toolbar/toolbar.utils';
import {ToolbarButton} from '~components/common/toolbar/ToolbarButton';
import {ChooseLabelDialog} from '~components/images/toolbar/ChooseLabelDialog';
import {CurrentClassificationIndicator} from '~components/images/toolbar/classified-toolbar-button/CurrentClassificationIndicator';
import {useLabelItems} from '~components/models/model.hooks';
import {useCurrentModel} from 'src/contexts/ModelContext';
import {ClassIcon} from 'src/icons';

export function ClassifyToolbarButton(props: ToolbarElementProps) {
  const {t} = useTranslation('review');
  const dispatch = useAppDispatch();

  const [isClassLabelingModalOpen, setIsClassLabelingModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const userEmail = useAppSelector(selectUserEmail) || '';
  const isClassified = useAppSelector(annotatorReducer.selectActiveHumanClassifications).length > 0;
  const classLabels = useLabelItems({from: 'current-model-or-project', type: 'class'});
  const hasSingleClassLabel = classLabels.length === 1;
  const {currentModel} = useCurrentModel();

  const handleClassLabelChange = (labelItem: LabelItem) => {
    dispatch(annotatorActions.addClassificationItem({labelItem, userEmail, modelId: currentModel!.id}));
    setIsClassLabelingModalOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (hasSingleClassLabel) {
      handleClassLabelChange(classLabels[0]);
      return;
    }

    dispatch(annotatorReducer.annotatorActions.setIsDrawingMode(false));
    setIsClassLabelingModalOpen(true);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <ChooseLabelDialog
        open={isClassLabelingModalOpen}
        onClose={() => setIsClassLabelingModalOpen(false)}
        onLabelSelect={handleClassLabelChange}
        anchorEl={anchorEl}
        type='class'
      />
      <ToolbarButton
        data-testid='classify-button'
        icon={!isClassified && !hasSingleClassLabel && <ClassIcon />}
        disabled={isClassified && hasSingleClassLabel}
        sx={{
          color: isClassified && hasSingleClassLabel ? 'currentColor' : 'initial',
        }}
        onClick={handleClick}
        {...props}
      >
        <Stack direction='row' spacing={1} alignItems='center'>
          <Box>{t(isClassified ? 'addedClass' : 'addClass')}</Box>
          <CurrentClassificationIndicator />
        </Stack>
      </ToolbarButton>
    </>
  );
}
