import {ClickAwayListener, Grow, Paper, Popper, PopperProps} from '@mui/material';
import {ReactNode} from 'react';

interface ToolbarPopperProps extends PopperProps {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  children: ReactNode;
  offsetTop?: number;
}

export function ToolbarPopper({open, onClose, anchorEl, offsetTop = 0, children, ...rest}: ToolbarPopperProps) {
  return (
    <Popper
      data-testid='toolbar-select-popper'
      anchorEl={anchorEl}
      open={open}
      sx={(theme) => ({zIndex: theme.zIndex.modal})}
      placement='bottom-start'
      transition
      {...rest}
    >
      {({TransitionProps}) => (
        <ClickAwayListener onClickAway={onClose}>
          <Grow {...TransitionProps} style={{transformOrigin: '0 0 0'}}>
            <Paper elevation={6} sx={{marginTop: 2 + offsetTop}}>
              {children}
            </Paper>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  );
}
