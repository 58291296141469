import {ParsedUrlQueryInput} from 'querystring';
import Router, {useRouter} from 'next/router';
import queryString from 'query-string';
import {useCallback, useEffect, useMemo, useState} from 'react';
import type {ParseOptions} from 'query-string';

/**
 * All routes in the application. When adding new pages, add the route here.
 */
export enum Route {
  archive = '/archive',
  archiveImage = '/archive/image',
  simsearch = '/simsearch',
  simsearchImage = '/simsearch/image',
  index = '/',
  heatmap = '/heatmap',
  login = '/login',
  modelAddSamples = '/model/add-samples',
  modelAddSamplesImage = '/model/add-samples/image',
  modelFinetune = '/model/finetune',
  modelFinetuneImage = '/model/finetune/image',
  modelLabelDistribution = '/model/statistics/label-distribution',
  modelLabelNoise = '/model/statistics/label-noise',
  modelNew = '/models/new',
  modelTraining = '/model/training',
  models = '/models',
  modelSamples = '/model/samples',
  modelSamplesImage = '/model/samples/image',
  modelSettings = '/model/settings',
  modelStatistics = '/model/statistics',
  monitor = '/monitor',
  notFound = '/404',
  projects = '/projects',
  settingsAutomaticReports = '/settings/automaticReports',
  settingsChangelog = '/settings/changelog',
  settingsDevices = '/settings/devices',
  settingsNotifications = '/settings/notifications',
  settingsShifts = '/settings/shifts',
  settingsUsers = '/settings/users',
  tasks = '/tasks',
  tasksLiveSession = '/tasks/live-session',
  tasksSession = '/tasks/session',
  apiUnavailable = '/503',
  noProjects = '/no-projects',
}

export enum LandingPageRoutes {
  Monitor = 'MONITOR',
  Tasks = 'TASKS',
  Archive = 'ARCHIVE',
  Models = 'MODELS',
}

export const projectIdQueryParam = 'projectID';
export const modelIdQueryParam = 'modelID';
export const imageIdQueryParam = 'imageID';
export const sessionTagQueryParam = 'sessionTag';
export const loginStatusQueryParam = 'status';
export const loginRedirectQueryParam = 'redirect';
export const confusionMatrixCellIdQueryParam = 'confusionMatrixCellID';
export const originalModelIdQueryParam = 'originalModelID';
export const imageBrowserHumanLabelQueryParam = 'humanLabel';
export const imageBrowserMachineLabelQueryParam = 'machineLabel';
export const imageBrowserAreaSizeQueryParam = 'areaSize';
export const imageBrowserConfidenceThresholdQueryParam = 'confidenceThreshold';
export const imageBrowserConfidenceThresholdNotRoundedQueryParam = 'confidenceThresholdNotRounded';
export const imageBrowserDatasetIdQueryParam = 'datasetID';
export const imageBrowserActiveViewQueryParam = 'imageBrowserActiveView';
export const imagesOverviewViewModeQueryParam = 'imagesOverviewViewMode';
export const showTrainingHistoryQueryParam = 'showTrainingHistory';
export const finetunePageActiveTabQueryParam = 'finetunePageActiveTab';
export const finetuneMetricsInterpretationQueryParam = 'finetuneMetricsInterpretation';
export const evaluationPipelineIdQueryParam = 'evaluationPipelineID';
export const evaluationCellPredictedClassQueryParam = 'evaluationCellPredictedClass';
export const similaritySearchQueryParam = 'similaritySearch';
export const modelsOverviewActiveTabQueryParam = 'modelsOverviewActiveTab';

export type RouteOptions = {
  query?: ParsedUrlQueryInput;
  keepProjectIdQueryParam?: boolean;
  keepModelIdQueryParam?: boolean;
};

const defaultRouteOptions: RouteOptions = {
  keepProjectIdQueryParam: true,
  keepModelIdQueryParam: true,
};

/**
 * Takes a list of query string keys to retrieve and returns an
 * object of the keys and their values found in the URL query string.
 *
 * - Keys not found in the URL query string are omitted from the returned object
 * - If a key occurs more than once in the URL query string, the first value is used.
 */
export function getQueryParams(paramsToGet: string[]): Record<string, string> {
  const queryString = Router.asPath.split('?')[1];
  const searchParams = new URLSearchParams(queryString);
  const allParams = Object.fromEntries(searchParams.entries());
  if (paramsToGet) {
    return Object.fromEntries(
      Object.entries(allParams).filter(([key, value]) => paramsToGet.includes(key) && value !== undefined),
    );
  }
  return allParams;
}

/**
 * Get all query params as configured by the provided {@link RouteOptions}.
 */
function getQuery(options?: RouteOptions): ParsedUrlQueryInput {
  const routeOptions = {
    ...defaultRouteOptions,
    ...options,
  };

  const globalQueryParams = getQueryParams([projectIdQueryParam, modelIdQueryParam]);
  const query: ParsedUrlQueryInput = {...globalQueryParams, ...routeOptions.query};

  if (query[modelIdQueryParam] && !routeOptions.keepModelIdQueryParam) {
    delete query[modelIdQueryParam];
  }

  if (query[projectIdQueryParam] && !routeOptions.keepProjectIdQueryParam) {
    delete query[projectIdQueryParam];
  }

  // Remove null or undefined values
  for (const key in query) {
    const value = query[key];
    if (value === null || value === undefined) {
      delete query[key];
    }
  }

  return query;
}
export function getRoute(route: Route, options?: RouteOptions) {
  const urlSearchParams = new URLSearchParams();
  const query = getQuery(options);

  for (const key in query) {
    const value = query[key];
    if (typeof value === 'string') {
      urlSearchParams.set(key, value);
    }
  }

  if (urlSearchParams.toString() === '') {
    return route;
  }
  return `${route}?${urlSearchParams.toString()}`;
}

export async function pushRoute(pathname: Route | string, options?: RouteOptions): Promise<void> {
  await Router.push({pathname, query: getQuery(options)});
}

export async function replaceRoute(pathname: Route | string, options?: RouteOptions): Promise<void> {
  await Router.replace({pathname, query: getQuery(options)});
}

export async function popPathname(method: 'push' | 'replace', options?: RouteOptions): Promise<void> {
  const pathnameParts = Router.pathname.split('/');
  if (pathnameParts.length <= 1) {
    return;
  }

  const pathname = pathnameParts.slice(0, pathnameParts.length - 1).join('/');
  if (method === 'push') {
    await Router.push({pathname, query: getQuery(options)});
  } else if (method === 'replace') {
    await Router.replace({pathname, query: getQuery(options)});
  }
}

const queryStringParseOptions: ParseOptions = {
  parseBooleans: true,
  arrayFormat: 'separator',
  arrayFormatSeparator: '|',
};

type QueryStringValue = undefined | string | boolean | null | Array<string | null>;

export function useQueryParam(
  param: string,
  defaultValue?: QueryStringValue,
): [value: QueryStringValue, updateValue: (value: QueryStringValue, usePush?: boolean) => void] {
  const router = useRouter();
  const {query} = queryString.parseUrl(router.asPath, queryStringParseOptions);
  const [value, setValue] = useState(() => query[param] || defaultValue);

  const updateValue = useCallback(
    (newValue: QueryStringValue, usePush?: boolean) => {
      if (value === newValue) {
        return;
      }

      const updatedQueryParams = {
        ...query,
        [param]: newValue,
      };

      const newQueryString = queryString.stringify(updatedQueryParams, queryStringParseOptions);
      const url = router.pathname + '?' + newQueryString;

      if (usePush) {
        router.push(url);
      } else {
        router.replace(url);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [param, value, router.pathname, query],
  );

  useEffect(() => {
    if (query[param] === undefined && defaultValue !== undefined) {
      // Set the default value if the Router query param is not present
      setValue(defaultValue);
    } else if (router.isReady && query[param] !== value) {
      // Update the local state value whenever the relevant Router query param is updated
      setValue(query[param]);
    }
  }, [defaultValue, param, query, router.asPath, router.isReady, value]);

  return [value, updateValue];
}

export type QueryStringObject = Record<string, QueryStringValue>;
export function useQueryParams(observedParams: string[]): [QueryStringObject, (params: QueryStringObject) => void] {
  const router = useRouter();
  const queryParams = useMemo(() => {
    const {query} = queryString.parseUrl(router.asPath, queryStringParseOptions);
    for (const key in query) {
      if (!observedParams.includes(key)) {
        delete query[key];
      }
    }
    return query;
  }, [observedParams, router.asPath]);

  const updateQueryParams = useCallback((params: QueryStringObject) => {
    const {query} = queryString.parseUrl(router.asPath, queryStringParseOptions);
    const newQueryParams = queryString.stringify({...query, ...params}, queryStringParseOptions);
    const url = router.pathname + '?' + newQueryParams;
    router.replace(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [queryParams, updateQueryParams];
}
