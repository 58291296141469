import * as Sentry from '@sentry/nextjs';
import {i18n} from 'i18n';
import {AfterResponseHook, BeforeRequestHook, HTTPError, Options} from 'ky';
import Router from 'next/router';

import {replaceRoute, Route} from '~utils/routeUtil';

import {queryClient} from '~pages/_app';
import {showNotification} from '~components/common/Notification';
import {API_RETRY_COUNT, API_RETRY_STATUS_CODES} from 'src/constants/constants';
import {StatusCodes} from 'src/constants/http-status-codes';
import {AuthService} from './auth.service';

const abortControllers: {[name: string]: AbortController} = {};

export const abortAllRequests = async (): Promise<void> => {
  await queryClient.cancelQueries();
  Object.keys(abortControllers).forEach((key: string) => {
    abortControllers[key].abort();
    delete abortControllers[key];
  });
};

export const toQueryString = <T extends Record<string, string | string[] | number | boolean | undefined>>(
  params: T,
): string => {
  const urlParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined) return;
    if (Array.isArray(value)) {
      value.forEach((v) => {
        urlParams.append(key, v);
      });
    } else {
      urlParams.set(key, value.toString());
    }
  });
  return urlParams.toString();
};

interface APIOptionParams<TPayload> {
  /**
   * An identifier for an AbortSignal. It is used to abort ongoing requests with identifier
   * whenever a new request with the same identifier is started.
   * **Note:** use reactQuerySignal instead if you want to use React Query's query cancellation.
   */
  signalId: string;
  /**
   * To make use of [React Query's query cancellation](https://react-query.tanstack.com/guides/query-cancellation), the
   * AbortSignal provided by React Query can be passed.
   *
   * **Important:** If this is set, query cancellation and AbortController creation is considered to be handled by React Query and
   * the provided signalId is ignored.
   */
  reactQuerySignal?: AbortSignal;
  /**
   * A JSON payload.
   */
  data?: TPayload;
  /**
   * Checks if the user is authorized and if not forces a logout and redirects the user to the Login page.
   *
   * @default true
   */
  checkAuth?: boolean;
  /**
   * @default 1
   */
  apiVersion?: number;

  body?: BodyInit | null;

  customHeaders?: Record<string, string>;
}

export const getOptions = <TPayload>({
  signalId,
  reactQuerySignal,
  data,
  body,
  checkAuth = true,
  apiVersion = 1,
  customHeaders,
}: APIOptionParams<TPayload>): Options => {
  let fullSignalId: string = signalId;
  if (!reactQuerySignal) {
    const serializedData = JSON.stringify(data);
    fullSignalId = signalId + (serializedData ? `-${serializedData}` : '');
    if (abortControllers[fullSignalId]) {
      abortControllers[fullSignalId].abort();
    }
    abortControllers[fullSignalId] = new AbortController();
  }

  const afterResponseHooks: AfterResponseHook[] = [];
  const beforeRequestHook: BeforeRequestHook = (request) => {
    if (signalId === 'uploadImagesToBlobStorage') {
      request.headers.delete('Authorization');
    }
  };
  const headers = new Headers();

  afterResponseHooks.push(async (_request, _options, response: Response) => {
    if (response.status === StatusCodes.SERVICE_UNAVAILABLE && !window.location.href.includes('503')) {
      try {
        await response.json();
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        await replaceRoute(Route.apiUnavailable);
      }
    }
  });

  if (checkAuth) {
    afterResponseHooks.push((_request, _options, response: Response): void => {
      if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        AuthService.logout({loginPageStatus: response.status.toString(), redirectUrl: Router.asPath});
      }
    });
  }

  if (AuthService.isLoggedIn() && signalId !== 'uploadImagesToBlobStorage') {
    headers.append('Authorization', `Bearer ${AuthService.getToken()}`);
  }

  if (data) {
    headers.append('Content-Type', 'application/json');
  }

  if (apiVersion > 1) {
    headers.append('Accept', `application/vnd.maddox.v${apiVersion}+json`);
  }

  if (signalId === 'uploadImagesToBlobStorage') {
    headers.append('x-ms-blob-type', 'BlockBlob');
  }

  if (customHeaders) {
    Object.entries(customHeaders).forEach(([key, value]) => {
      headers.append(key, value);
    });
  }

  return {
    retry: {
      limit: API_RETRY_COUNT,
      statusCodes: API_RETRY_STATUS_CODES,
    },
    hooks: {afterResponse: afterResponseHooks, beforeRequest: [beforeRequestHook]},
    signal: reactQuerySignal || abortControllers[fullSignalId].signal,
    json: data,
    body: body,
    headers: headers,
    timeout: 120 * 1000,
  };
};

/**
 * Type guard to check if an error is a ky.HTTPError.
 */
export function isKyHTTPError(error: unknown): error is HTTPError {
  return error instanceof Error && 'response' in error;
}

/**
 * Shows a localized error notification for an API error. Falls back to English if the current language is not available.
 */
export async function showApiErrorNotification(error: HTTPError) {
  const errorResponse: ApiErrorResponse = await error.response?.json();
  if (!errorResponse) return;
  const currentLanguageId = i18n.language.substring(0, 2);
  const message = errorResponse.i18n[currentLanguageId] ?? errorResponse.i18n.en;
  if (message) {
    showNotification({severity: 'error', message, id: message});
  } else {
    Sentry.captureException(error, {extra: {response: error.response}});
  }
}
