import {createSelector} from '@reduxjs/toolkit';
import {AnyAction} from 'redux';

import {ReduxState} from '~redux/index';

import {MONITOR_TOOLBAR_FILTERS, RESET} from '../types';
import {ReduxStateMonitor} from '../types/monitor';
import {MonitorToolbarFilters} from '../types/toolbarFilters';

export const initialState: ReduxStateMonitor = {
  toolbarFilters: {
    isOkHidden: true,
    format: 'absolute',
    timeframe: '24h',
    shifts: undefined,
    productTypes: undefined,
    devices: undefined,
    binSize: undefined,
  },
};

export interface MonitorAction extends AnyAction {
  payload:
    | MonitorCount
    | MonitorCountsByShift[]
    | MonitorCountsByDevice[]
    | MonitorCountsByProductType[]
    | MonitorToolbarFilters
    | MonitorSummary
    | boolean // isLoading
    | string // reportFormat
    | null; // for resetting fetched state (e.g. monitorCount)
}

const monitorReducer = (state: ReduxStateMonitor = initialState, action: MonitorAction): ReduxStateMonitor => {
  switch (action.type) {
    case RESET:
      return initialState;
    case MONITOR_TOOLBAR_FILTERS:
      return {...state, toolbarFilters: {...state.toolbarFilters, ...(action.payload as MonitorToolbarFilters)}};
    default:
      return state;
  }
};

export default monitorReducer;

export const reducerName = 'monitor';

export const getMonitorToolbarFilters = (state: ReduxState): MonitorToolbarFilters => state[reducerName].toolbarFilters;
export const getMonitorStateIsOkHidden = createSelector(
  [getMonitorToolbarFilters],
  (toolbarFilters) => toolbarFilters.isOkHidden,
);
