import {Coordinate} from '~redux/types/images';
import {PolygonItem} from '~redux/reducers/annotatorReducer';

export const COORDINATE_SEPARATOR = '__';

export interface GroupedAnnotationItems {
  [groupId: string]: PolygonItem[];
}

/**
 * @private Only exported for testing purposes.
 */
export function groupPolygonItemsByProximity(polygonItems: PolygonItem[]): GroupedAnnotationItems {
  return polygonItems.reduce((groups, polygonItem, polygonIndex) => {
    // Machine prediction polygons are grouped together if the coordinates are identical.
    if (polygonItem.isMachinePrediction) {
      const coordinateString = getCoordinateStringFromPoints(polygonItem.coordinates);
      const polygonGroup = assignCoordinateStringToPolygonGroup(coordinateString, Object.keys(groups));
      return {
        ...groups,
        [polygonGroup]: [...(groups[polygonGroup] || []), polygonItem],
      };
    } else {
      // Human polygons are never grouped together and therefore always have a unique group id.
      return {
        ...groups,
        [`user-polygon-${polygonIndex}`]: [polygonItem],
      };
    }
  }, {} as GroupedAnnotationItems);
}

/**
 * This function determines whether a string of coordinates describes a new polygon group or if it is part of an existing polygon group.
 * @param {string} coordinateString - The string that should be assigned to a group.
 * @param {string[]} existingGroups - The groups that should be checked for equality with `coordinateString`
 * @returns {string} - The group that `coordinateString` was put into, either the value of `coordinateString` itself or one of the entries in `existingGroups`
 *
 * */
function assignCoordinateStringToPolygonGroup(coordinateString: string, existingGroups: string[]): string {
  for (const groupKey of existingGroups) {
    if (groupKey.length !== coordinateString.length) {
      // Only polygons with the same amount of coordinates can be identical. This is assumptions is not 100% true, but covers 99% of our use cases.
      continue;
    }
    // To check whether coordinateString is part of an existing group we need to compare both strings. A simple equality
    // check would only return true, if the coordinates are provided in the exact same order, but would fail, if
    // identical polygons are defined from a different starting coordinate.
    // To give an example, the following polygons are identical but differ in their coordinates' definition order:
    // Polygon A: x10y10 - x15y15 - x20y10
    // Polygon B: x15y15 - x20y10 - x10y10
    // In order to still catch their equality, polygon A's coordinate are repeated and concatenated, followed by a check
    // if polygon B's coordinate sequence exists within the repeated coordinates of polygon A.
    // Example:
    // Polygon A repeated:            x10y10 - x15y15 - x20y10 - x10y10 - x15y15 - x20y10
    // Polygon B is a substring now:           x15y15 - x20y10 - x10y10
    // Using this check, polygon groups are rotation invariant.
    const doubledGroupKey = groupKey + COORDINATE_SEPARATOR + groupKey;
    if (doubledGroupKey.includes(coordinateString)) {
      return groupKey;
    }
  }
  // Create a new group if no other group matches
  return coordinateString;
}

export function getCoordinateStringFromPoints(points: Coordinate[] | undefined, decimalsToKeep = 2): string {
  if (!points || points.length === 0) {
    return '';
  }
  const roundedCoords = points.reduce((aggregate, point) => {
    return [...aggregate, `${point.x.toFixed(decimalsToKeep)}-${point.y.toFixed(decimalsToKeep)}`];
  }, [] as string[]);
  return roundedCoords.join(COORDINATE_SEPARATOR);
}
