import {Box} from '@mui/system';
import * as Sentry from '@sentry/nextjs';
import {useCallback, useMemo} from 'react';

import {useModelsQuery} from '~api/models.queries';
import {LabelItem, LabelType} from '~redux/types/images';

import {ClosableDialog} from '~components/common/ClosableDialog';
import {ToolbarPopper} from '~components/common/toolbar/ToolbarPopper';
import {ChooseLabelDialogContent} from '~components/images/toolbar/ChooseLabelDialogContent';
import {useCurrentImageOverviewMode} from '~components/images/utils/image-mode-mixins';
import {useCurrentModel} from 'src/contexts/ModelContext';

export interface ChooseLabelDialogProps {
  type: LabelType;
  open: boolean;
  onClose: () => void;
  onLabelSelect: (labelItem: LabelItem, modelId: string) => Promise<void> | void;
  showAllLabels?: boolean;
  anchorEl?: HTMLButtonElement | null;
}

export function ChooseLabelDialog({
  anchorEl,
  open,
  onClose,
  onLabelSelect,
  type,
}: ChooseLabelDialogProps): JSX.Element | null {
  const modelsQuery = useModelsQuery();
  const {currentModel} = useCurrentModel();
  const imageOverviewMode = useCurrentImageOverviewMode();

  const models = useMemo(() => {
    // In the archive, we're not in a model context, so we need to show labels from all models
    if (imageOverviewMode === 'archive') {
      return modelsQuery.data || [];
    } else if (currentModel) {
      return [currentModel];
    }
    return [];
  }, [imageOverviewMode, currentModel, modelsQuery.data]);

  const handleLabelSelect = useCallback(
    (labelItem: LabelItem, modelId: string) => {
      const asyncCallback = async () => {
        onLabelSelect(labelItem, modelId);
      };
      asyncCallback().catch((err) => Sentry.captureException(err));
    },
    [onLabelSelect],
  );

  const chooseLabelDialogContent = (
    <ChooseLabelDialogContent models={models} onLabelSelect={handleLabelSelect} type={type} />
  );

  return anchorEl ? (
    <ToolbarPopper open={open} onClose={onClose} anchorEl={anchorEl}>
      <Box
        sx={{
          '& .MuiDialogTitle-root': {display: 'none'},
          '& .MuiDialogContent-root': {padding: 3, border: 'none'},
        }}
      >
        {chooseLabelDialogContent}
      </Box>
    </ToolbarPopper>
  ) : (
    <ClosableDialog
      sx={{
        '& .MuiDialog-container': {alignItems: 'flex-start'},
        '& .MuiPaper-root': {margin: 12, maxHeight: 'calc(100% - 192px)'},
      }}
      open={open}
      onClose={onClose}
      fullWidth
    >
      {chooseLabelDialogContent}
    </ClosableDialog>
  );
}
