import * as Sentry from '@sentry/nextjs';

import * as authApi from '~api/auth';
import {loginRedirectQueryParam, loginStatusQueryParam, replaceRoute, Route} from '~utils/routeUtil';
import {resetToInitialState} from '~redux/actions/globalActions';
import {store} from '~redux/index';

import {queryClient} from '~pages/_app';
import {abortAllRequests} from './api';

const TOKEN_ID = 'token';

function getToken(): string | null {
  return localStorage.getItem(TOKEN_ID);
}

function setToken(token: string): void {
  localStorage.setItem(TOKEN_ID, token);
}

function removeToken(): void {
  localStorage.removeItem(TOKEN_ID);
}

/**
 * @throws {Error} if the login fails
 */
async function login(email: string, password: string): Promise<void> {
  try {
    const {accessToken} = await authApi.login(email, password);
    if (!accessToken) {
      throw new Error('Login failed');
    }
    setToken(accessToken);
  } finally {
    Sentry.setUser({email});
  }
}

async function logout(options: {loginPageStatus?: string; redirectUrl?: string} = {}): Promise<void> {
  const token = getToken();
  if (token !== null) {
    try {
      await authApi.logout(token);
      await abortAllRequests();
    } catch (e: any) {
      // An error occurs, when the token is invalid or expired.
      // There is nothing to handle here, we just catch to avoid throwing the error further.
    } finally {
      removeToken();
    }
  }

  // Cleanup local data
  queryClient.removeQueries();
  store.dispatch(resetToInitialState());

  if (window.location.href.includes('503')) {
    Sentry.setUser(null);
    return;
  }
  // Redirect to login page
  await replaceRoute(Route.login, {
    query: {
      [loginStatusQueryParam]: options.loginPageStatus ?? 'logout',
      [loginRedirectQueryParam]: options.redirectUrl,
    },
    keepProjectIdQueryParam: false,
    keepModelIdQueryParam: false,
  });

  Sentry.setUser(null);
}

function isLoggedIn(): boolean {
  return getToken() !== null;
}

export const AuthService = {
  login,
  logout,
  isLoggedIn,
  getToken,
};
