import {
  formatDistanceToNow as dateFnsFormatDistanceToNow,
  formatRelative as dateFnsFormatRelative,
  format,
  set,
  setMilliseconds,
  subDays,
  subHours,
} from 'date-fns';
import {i18n, localeMap, TFunction} from 'i18n';

import {RelativeTimeframe} from '~redux/types/toolbarFilters';

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

const getFormatter = (
  options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  },
): Intl.DateTimeFormat => new Intl.DateTimeFormat(i18n.language, options);

export const timestampToLocale = (value?: number): string => {
  if (!value) {
    return '';
  }

  const formatter = getFormatter();
  const date = new Date(value * 1000);
  return formatter.format(date);
};

export interface FormatNumberOptions {
  decimalPrecision?: number;
  suffix?: string;
}

/**
 * Formats a number to its localized string representation.
 */
export function formatNumber(number: number, options?: FormatNumberOptions): string {
  const {decimalPrecision = undefined, suffix = ''} = options || {};
  return (
    new Intl.NumberFormat(i18n.language, {
      maximumFractionDigits: decimalPrecision,
      minimumFractionDigits: decimalPrecision,
    }).format(number) + suffix
  );
}

/**
 * Returns a localized DateRange for a given absolute timeframe.
 * @param timeframe Must be formatted like this: `"<utcTimestamp>-<utcTimestamp>"`, e.g. `"1650279992-1652871992"`
 */
export function absoluteTimeframeToLocalDateRange(timeframe: string): DateRange {
  const [startTimestamp, endTimestamp] = timeframe.split('-').map((timestamp) => parseInt(timestamp) * 1000);
  return {
    startDate: new Date(startTimestamp),
    endDate: new Date(endTimestamp),
  };
}

export function relativeTimeframeToDateRange(timeframe: RelativeTimeframe): DateRange {
  const endDate = new Date();
  const startDate: {[key in RelativeTimeframe]: Date} = {
    '6h': subHours(endDate, 6),
    '12h': subHours(endDate, 12),
    '24h': subHours(endDate, 24),
    '7d': subDays(endDate, 7),
    '14d': subDays(endDate, 14),
    '30d': subDays(endDate, 30),
  };
  return {
    startDate: startDate[timeframe] || set(endDate, {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}),
    endDate,
  };
}

export function dateRangeToUTCTimeframe(dateRange: DateRange): string {
  const from = setMilliseconds(dateRange.startDate, 0);
  const to = setMilliseconds(dateRange.endDate, 0);
  return `${from.getTime() / 1000}-${to.getTime() / 1000}`;
}

export function isAbsoluteTimeframe(timeframe: string | RelativeTimeframe | undefined): boolean {
  return timeframe?.includes('-') || false;
}

function timestampRangeToLocale(value: string, formatOptions?: Intl.DateTimeFormatOptions): string {
  const formatter = getFormatter(formatOptions);
  const split = value.split('-');
  const startTimestamp = parseInt(split[0]);
  const endTimestamp = parseInt(split[1]);
  const startDate = new Date(startTimestamp * 1000);
  const endDate = new Date(endTimestamp * 1000);
  // Note: returned string uses en-dash between dates
  return `${formatter.format(startDate)} – ${formatter.format(endDate)}`;
}

export const formatTimeToLocale = (time: number | Date): string => {
  if (i18n.language === 'en') {
    return format(time, 'hh:mm a');
  } else {
    return format(time, 'HH:mm');
  }
};

export const timeframeToLocale = (
  value: string,
  t: TFunction,
  rangeFormatOptions?: Intl.DateTimeFormatOptions,
): string => {
  if (isAbsoluteTimeframe(value)) {
    return timestampRangeToLocale(value, rangeFormatOptions);
  }

  const unit = value[value.length - 1];
  const time = value.substring(0, value.length - 1);
  const timeAsNumber = Number.parseInt(time);

  const i18nUnits: {[key: string]: string} = {h: t('hour', {count: timeAsNumber}), d: t('day', {count: timeAsNumber})};
  return t('last', {value: `${time} ${i18nUnits[unit]}`});
};

/**
 * @default "de-DE" Locale
 */
export const getCurrentLocale = (): Locale => {
  return getDateFnsLocale(i18n.language) || localeMap['de-DE'];
};

export const getDateFnsLocale = (locale: string): Locale | undefined => {
  return localeMap[locale];
};

export const formatDistanceToNow = (ts: number, includeSeconds = false): string => {
  return dateFnsFormatDistanceToNow(new Date(ts * 1000), {locale: getCurrentLocale(), addSuffix: true, includeSeconds});
};

export const formatRelative = (ts: number, baseDate = new Date()) => {
  return dateFnsFormatRelative(new Date(ts * 1000), baseDate, {locale: getCurrentLocale()});
};

/**
 * A map with timeframe keys and their respective length in minutes.
 * E.g. timeInSeconds.hour => 3600 (60 seconds * 60 minutes)
 */
export const timeInSeconds: {[key: string]: number} = {
  // seconds * minutes * hours * days
  hour: 60 * 60, // 60 sec * 60 min
  day: 60 * 60 * 24, // timeInSeconds.hour * 24
  month: 60 * 60 * 24 * 29, // timeInSeconds.day * 29
};

/**
 * Takes a RelativeTimeframe (e.g. "30d") or a string in the format "fromUTC-toUTC" (e.g. "1652104797-1642596738")
 * and returns the time difference in seconds.
 */
export function timeDiffInSeconds(timeframe: RelativeTimeframe | string): number {
  switch (timeframe) {
    case RelativeTimeframe.hours6:
      return timeInSeconds.hour * 6;
    case RelativeTimeframe.hours12:
      return timeInSeconds.hour * 12;
    case RelativeTimeframe.hours24:
      return timeInSeconds.day;
    case RelativeTimeframe.days7:
      return timeInSeconds.day * 7;
    case RelativeTimeframe.days14:
      return timeInSeconds.day * 14;
    case RelativeTimeframe.days30:
      return timeInSeconds.day * 30;
    default: {
      const [from, to] = timeframe.split('-').map((val) => parseInt(val));
      return to - from;
    }
  }
}

interface updateDateArgs {
  dateToUpdate: Date;
  newDate: Date;
  /**
   * Update "datetime" (both, time and date) or only "time" or "date".
   */
  partsToUpdate: 'date' | 'time' | 'datetime';
}

/**
 * Clone and update a given Date with some or all parts ("time", "date" or "datetime") of
 * another given Date.
 */
function updateDate({dateToUpdate, newDate, partsToUpdate}: updateDateArgs) {
  const updatedDate = new Date(dateToUpdate.getTime());
  if (partsToUpdate === 'datetime' || partsToUpdate === 'date') {
    updatedDate.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
  }
  if (partsToUpdate === 'datetime' || partsToUpdate === 'time') {
    updatedDate.setHours(newDate.getHours(), newDate.getMinutes());
    updatedDate.setSeconds(0, 0);
  }
  return updatedDate;
}

interface updateDateRangeArgs {
  dateRange: DateRange;
  newStartDate?: Date;
  newEndDate?: Date;
  /**
   * Update "datetime" (both, time and date) or only "time" or "date".
   */
  partsToUpdate: 'date' | 'time' | 'datetime';
}

/**
 * Clone and update a given DateRange by providing a new start date and/or a new end date.
 */
export function updateDateRange({
  dateRange,
  newStartDate,
  newEndDate,
  /**
   * Update "datetime" (both, time and date) or only "time" or "date".
   */
  partsToUpdate = 'datetime',
}: updateDateRangeArgs): DateRange {
  let startDate = dateRange.startDate;
  if (newStartDate) {
    startDate = updateDate({dateToUpdate: dateRange.startDate, newDate: newStartDate, partsToUpdate});
  }

  let endDate = dateRange.endDate;
  if (newEndDate) {
    endDate = updateDate({dateToUpdate: dateRange.endDate, newDate: newEndDate, partsToUpdate});
  }

  return {
    startDate,
    endDate,
  };
}
