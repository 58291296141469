import * as Sentry from '@sentry/nextjs';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';

import {ReduxState} from '~redux/index';

export type ErrorAction = {
  type: string;
  subtype?: string;
  payload: string;
};

/**
 * Note: this action creator is not exported to enforce use of the {@link setError} thunk.
 * @private
 */
const setReduxError = (type: string, error: Error, subtype = ''): ErrorAction => {
  return {type, subtype, payload: `${error.name}: ${error.message}`};
};

export const resetError = (type: string, subtype = ''): ErrorAction => {
  return {type, subtype, payload: ''};
};

/**
 * **Note: If possible use React Query when adding new endpoints and make use of
 * the error handling that React Query provides!**
 *
 * Sets redux error state and ignores AbortErrors.
 * To be used in redux thunks that fetch data.
 *
 * @param type An error type name as defined in /~redux/types.ts
 * @param error The Error thrown in the thunk.
 * @param subtype An error subtype that identifies the action type that caused the error.
 */
export const setError =
  (type: string, error: Error, subtype = ''): ThunkAction<Promise<void>, ReduxState, unknown, ErrorAction> =>
  async (dispatch: ThunkDispatch<ReduxState, unknown, ErrorAction>): Promise<void> => {
    if (error.name === 'AbortError' || error.constructor.name === 'CancelledError') {
      return;
    }
    Sentry.captureException(error);
    dispatch(setReduxError(type, error, subtype));
  };
