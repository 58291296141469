import {TooltipProps, Zoom} from '@mui/material';

import {Tooltip} from '~components/common/Tooltip';

export function ToolbarTooltip(props: TooltipProps) {
  return (
    <Tooltip placement='top-start' arrow TransitionComponent={Zoom} disableInteractive {...props}>
      {props.children}
    </Tooltip>
  );
}
