import {UnsavedLabelItem} from '~redux/types/images';
import {InspectionType, Model} from '~redux/types/models';

import {ANOMALY, CLASS_EMPTY, CLASS_OK, LABEL_ID_SEPARATOR} from 'src/constants/constants';
import {theme} from 'src/constants/theme';
import {
  AnomalyIcon,
  ClassificationIcon,
  LegacyModelIcon,
  ModelIcon,
  ObjectDetectionIcon,
  SurfaceInspectionIcon,
} from 'src/icons';

/**
 * Return the icon for a given InspectionType. Defaults to the generic model icon.
 */
export function getInspectionTypeIcon(type: InspectionType | undefined): JSX.Element {
  switch (type) {
    case InspectionType.surfaceInspection:
      return <SurfaceInspectionIcon />;
    case InspectionType.classification:
      return <ClassificationIcon />;
    case InspectionType.objectDetection:
      return <ObjectDetectionIcon />;
    case InspectionType.legacyModel:
      return <LegacyModelIcon />;
    case InspectionType.anomalyDetection:
      return <AnomalyIcon />;
    default:
      return <ModelIcon />;
  }
}

/**
 * Retrieve a list of predefined labels for the given inspection type.
 */
export function getPredefinedLabels(inspectionType: InspectionType | undefined): UnsavedLabelItem[] {
  switch (inspectionType) {
    case InspectionType.surfaceInspection:
      return [
        {predefined: true, alias: CLASS_OK, colorCode: theme.palette.maddox.classOk, type: 'class', condition: 'OK'},
      ];
    case InspectionType.objectDetection:
      return [
        {
          predefined: true,
          alias: CLASS_EMPTY,
          colorCode: theme.palette.maddox.classEmpty,
          type: 'class',
          condition: 'OTHER',
        },
      ];
    case InspectionType.anomalyDetection:
      return [
        {predefined: true, alias: CLASS_OK, colorCode: theme.palette.maddox.classOk, type: 'class', condition: 'OK'},
        {
          predefined: true,
          alias: ANOMALY,
          colorCode: '#f44336',
          type: 'polygon',
          condition: 'NOK',
        },
      ];
    case InspectionType.classification:
    default:
      return [];
  }
}

/**
 * Determine if a given label is a predefined label of the given inspection type
 * @see {@link getPredefinedLabels} to see the predefined labels.
 */
export function isPredefinedLabel(labelId: string | undefined, inspectionType: InspectionType) {
  if (labelId === undefined) {
    return false;
  }

  const labelAliasPart = labelId.split(LABEL_ID_SEPARATOR)[0];
  const predefinedLabels = getPredefinedLabels(inspectionType);
  return predefinedLabels.some((predefinedLabel) => predefinedLabel.alias === labelAliasPart);
}

export function getInspectionType(labelId: string, models: Model[]): InspectionType | undefined {
  const model = models.find((model) => {
    return model.labels.find((label) => label.id === labelId);
  });
  return model?.inspectionType;
}

export function getModelName(modelId?: string, models?: Model[]): string | undefined {
  const model = models?.find((model) => model.id === modelId);
  return model?.name;
}

/**
 * Calculates the performance metrics from a multi-class confusion matrix.
 * Note:
 *  - Hits and misses are only computed for classification and return -1 for the other inspection types.
 *  - some metrics may return NaN if the given matrix is invalid and/or division by zero occurs.
 */
export function calculateClassificationMetrics(confusionCells?: ConfusionMatrix['confusionCells']) {
  let hits = 0,
    misses = 0,
    total = 0;

  if (confusionCells) {
    confusionCells.forEach((cell) => {
      const count = cell.count ?? 0;
      total += count;

      if (cell.actualClass === cell.predictedClass) {
        // Hit
        hits += count;
      } else {
        // Miss
        misses += count;
      }
    });
  }

  return {hits, misses, total};
}
