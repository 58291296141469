import {createContext, useContext} from 'react';

import {Model} from '~redux/types/models';

interface ModelContextValue {
  currentModel: Model | undefined;
  setCurrentModel: (model: Model | undefined) => void;
}

/**
 * @private Do not use this context directly. Use the `useCurrentModel` hook instead.
 */
export const ModelContext = createContext<ModelContextValue>({
  currentModel: undefined,
  setCurrentModel: () => undefined,
});

/**
 * Provides the currently active model via context.
 *
 * @see ModelProvider.ts for the implementation of the context's state handling
 */
export function useCurrentModel(): ModelContextValue {
  const currentModelContext = useContext(ModelContext);
  return currentModelContext as ModelContextValue;
}
