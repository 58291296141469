import ky from 'ky';

import {ReduxStateApplicableAPIFilters} from '~redux/types/filters';

import {getOptions} from './api';

export const getFilters = (projectID: string): Promise<Partial<ReduxStateApplicableAPIFilters>> =>
  ky
    .get(`${process.env.AUTH_API}/filters/${projectID}`, getOptions({apiVersion: 3, signalId: 'getFilters'}))
    .json<Partial<ReduxStateApplicableAPIFilters>>();
