import {createSelector} from '@reduxjs/toolkit';
import {AnyAction} from 'redux';

import {GLOBAL_LOADING, RESET} from '~redux/types';
import {ReduxStateGlobal} from '~redux/types/utils';
import {ReduxState} from '~redux/index';

export const initialState: ReduxStateGlobal = {
  loadingCounter: 0,
  loadingMessage: undefined,
};

export interface GlobalLoadingPayload {
  counter: number;
  message?: string;
}
export interface UtilAction extends AnyAction {
  payload: number | boolean | string | GlobalLoadingPayload;
}

const globalReducer = (state: ReduxStateGlobal = initialState, action: UtilAction): ReduxStateGlobal => {
  switch (action.type) {
    case RESET:
      localStorage.clear();
      return initialState;
    case GLOBAL_LOADING: {
      const payload = action.payload as GlobalLoadingPayload;
      const newLoadingCounter = Math.max(0, state.loadingCounter + payload.counter);

      let newMessage = payload.message || state.loadingMessage;
      if (newLoadingCounter == 0) {
        newMessage = undefined;
      }

      return {...state, loadingCounter: newLoadingCounter, loadingMessage: newMessage};
    }
    default:
      return state;
  }
};

export default globalReducer;

export const reducerName = 'global';

const selectLoadingCounter = (state: ReduxState): number => state[reducerName].loadingCounter;
export const getUtilStateIsLoading = createSelector([selectLoadingCounter], (loadingCounter) => loadingCounter > 0);
