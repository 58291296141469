import {Stack} from '@mui/material';
import Button, {ButtonProps} from '@mui/material/Button';

import {textShortener} from '~utils/miscUtils';
import {LabelItem} from '~redux/types/images';

import {useLabelAlias} from '~components/models/model.hooks';
import {CircleColorIcon} from '~components/settings/CircleColorIconButton';
import {DEFAULT_SHORT_LABEL_CHAR_LENGTH} from 'src/constants/constants';
import {Tooltip} from '../../common/Tooltip';

const maxChars = DEFAULT_SHORT_LABEL_CHAR_LENGTH;

interface LabelButtonProps extends ButtonProps {
  item: LabelItem;
}

export function LabelButton({item, ...rest}: LabelButtonProps): JSX.Element {
  const labelAlias = useLabelAlias(item.id);
  const isLabelAliasTooLong = labelAlias.length > maxChars;

  return (
    <Button
      sx={{
        width: '100%',
        padding: 0,
        border: 0,
        '& + &': {
          marginTop: 1,
        },
        '&:hover, &:focus-visible': {
          backgroundColor: 'maddox.hover',
        },
      }}
      variant='text'
      size='small'
      {...rest}
    >
      <Stack
        sx={{
          color: 'maddox.black80',
          width: '100%',
          border: 1,
          borderColor: 'maddox.black20',
          padding: 1,
          borderRadius: 1,
          textTransform: 'none',
        }}
        direction='row'
        spacing={1}
        justifyContent='flex-start'
      >
        <CircleColorIcon color={item.colorCode} circleSize={25} />
        <Tooltip title={labelAlias} arrow disableInteractive disableHoverListener={!isLabelAliasTooLong}>
          <span style={{textAlign: 'left'}}>
            {isLabelAliasTooLong ? textShortener(labelAlias, maxChars) : labelAlias}
          </span>
        </Tooltip>
      </Stack>
    </Button>
  );
}
