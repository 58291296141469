import {AnalyticsBrowser} from '@june-so/analytics-next';
import {useCallback, useEffect, useState} from 'react';

import {selectUser} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

const includeTelemetry = process.env.DEPLOYMENT_ENV === 'production';
const writeKey = process.env.JUNE_ANALYTICS_WRITE_KEY;

export function useJuneAnalytics() {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser | undefined>(undefined);

  useEffect(() => {
    if (!includeTelemetry || !writeKey) return;

    const loadAnalytics = async () => {
      const response = AnalyticsBrowser.load({writeKey});
      setAnalytics(response);
    };

    loadAnalytics();
  }, []);

  return analytics;
}

type EventParams = Parameters<AnalyticsBrowser['track']>[2];

export function useJuneTrackEvent() {
  const user = useAppSelector(selectUser);
  const juneAnalytics = useJuneAnalytics();

  return useCallback(
    (event: string, properties?: Record<string, any>, options?: EventParams) => {
      if (juneAnalytics)
        juneAnalytics.track(event, properties, {
          context: {
            groupId: user?.email.endsWith('maddox.ai') ? `${user?.customer}_internal` : user?.customer,
          },
          ...options,
        });
    },
    [juneAnalytics, user],
  );
}
