import {useQuery} from '@tanstack/react-query';

import {IMAGE_OVERVIEW_ITEMS_PER_PAGE} from '~constants/constants';
import {splitImageOrderBy} from '~utils/miscUtils';
import {ImageOrderBy} from '~redux/types/images';
import {selectCurrentProjectId} from '~redux/reducers/userReducer';
import {decreaseGlobalLoadingIndicator, increaseGlobalLoadingIndicator} from '~redux/actions/globalActions';
import {useAppDispatch, useAppSelector} from '~redux/index';

import {useImageFilters} from '~components/images/image-overview/imageOverview.hooks';
import {ImageOverviewMode} from '~components/images/utils/image-mode-mixins';
import {useLabelIds, useLabelItems} from '~components/models/model.hooks';
import {useLabelInstances} from 'src/contexts/LabelInstancesContext';
import {useCurrentModel} from 'src/contexts/ModelContext';
import {ImageFiltersPayload, ImagesDataPayload, SortAndCountArgs} from './images';
import {getLabelInstancesData} from './label-instances';

type LabelInstancesPayload = ImagesDataPayload;

export const LabelInstancesQueryKeys = {
  all: ['labelInstances'] as const,
  labelInstancesDataFilterPaginated: (
    projectId: string,
    filters: ImageFiltersPayload | undefined,
    sorting: SortAndCountArgs | undefined,
    targetPage: number | undefined,
  ) => [...LabelInstancesQueryKeys.all, projectId, filters, sorting, targetPage] as const,
};

export function useLabelInstancesQuery(mode: ImageOverviewMode = 'model-defect-book', enabled: boolean = true) {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const labelIds = useLabelIds({from: 'current-model-or-project'});
  const modelLabelItems = useLabelItems({from: 'current-model'});
  const [imageFilters] = useImageFilters(mode, modelLabelItems);
  const areMachinePredictions = mode === 'archive';
  const {currentPage, currentPagination} = useLabelInstances();
  const {currentModel} = useCurrentModel();

  const isModelDefectBook = mode === 'model-defect-book';

  const payload: LabelInstancesPayload = {
    filters: {...imageFilters, ...(isModelDefectBook && {modelId: currentModel?.id})},
    pagination: {
      ...splitImageOrderBy(imageFilters?.orderBy),
      action: currentPagination,
      count: IMAGE_OVERVIEW_ITEMS_PER_PAGE,
    },
  };

  return useQuery(
    LabelInstancesQueryKeys.labelInstancesDataFilterPaginated(
      projectId,
      payload.filters,
      {sortBy: payload.pagination?.sortBy, sortOrder: payload.pagination?.sortOrder, count: payload.pagination?.count},
      currentPage,
    ),
    async ({signal}) => {
      dispatch(increaseGlobalLoadingIndicator());
      try {
        const defaultSortedPayload = payload?.pagination?.sortBy
          ? payload
          : {
              ...payload,
              pagination: {...payload.pagination, ...splitImageOrderBy(ImageOrderBy.TIMESTAMP_DESC)},
            };
        const data = await getLabelInstancesData(projectId, defaultSortedPayload, areMachinePredictions, signal);
        return data;
      } finally {
        dispatch(decreaseGlobalLoadingIndicator());
      }
    },
    {
      enabled: labelIds?.length > 0 && enabled,
    },
  );
}
