import {Box} from '@mui/material';
import Zoom from '@mui/material/Zoom';

import {formatDistanceToNow, timestampToLocale} from '~utils/localeUtil';
import {selectCurrentLatestMachinePrediction} from '~redux/reducers/imageReducer';
import {useAppSelector} from '~redux/index';

import {Tooltip} from '~components/common/Tooltip';

export function LiveSessionText(): JSX.Element | null {
  const latestMLAnnotation = useAppSelector(selectCurrentLatestMachinePrediction);

  if (!latestMLAnnotation) {
    return null;
  }

  const tooltip = timestampToLocale(latestMLAnnotation.createdAt);
  const label = formatDistanceToNow(latestMLAnnotation.createdAt);
  return (
    <Tooltip title={tooltip} placement='top' arrow TransitionComponent={Zoom}>
      <Box display='inline-block' sx={(theme) => ({margin: 1, padding: theme.spacing(1, 1.5, 1, 1.5)})}>
        {label}
      </Box>
    </Tooltip>
  );
}
