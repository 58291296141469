import ky, {ResponsePromise} from 'ky';

import {LoginResponse} from '~redux/types/user';

import {getOptions} from './api';

export const login = async (email: string, password: string): Promise<LoginResponse> => {
  const data = {email: email, password: password};
  return ky
    .post(`${process.env.AUTH_API}/auth/login`, getOptions({signalId: 'login', data, checkAuth: false}))
    .json<LoginResponse>();
};

export const logout = (token: string): ResponsePromise => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);

  return ky.delete(`${process.env.AUTH_API}/auth/logout`, {headers: headers, timeout: 120 * 1000});
};
