import MuiChip from '@mui/material/Chip';
import React from 'react';

import {textShortener} from '~utils/miscUtils';

import {DEFAULT_SHORT_LABEL_CHAR_LENGTH} from 'src/constants/constants';
import {Tooltip} from './Tooltip';

type Chip = {
  withTooltip?: boolean;
  label?: React.ReactNode;
  [key: string]: any;
};

export function Chip({label, withTooltip, ...rest}: Chip): JSX.Element {
  return typeof label === 'string' && withTooltip && label.length > DEFAULT_SHORT_LABEL_CHAR_LENGTH ? (
    <Tooltip title={label} arrow>
      <MuiChip label={textShortener(label)} {...rest} />
    </Tooltip>
  ) : (
    <MuiChip label={label} {...rest} />
  );
}
