export const RESET = 'RESET';

export const GLOBAL_LOADING = 'GLOBAL_LOADING';

export const PROJECT_DATA_ERROR = 'PROJECT_DATA_ERROR';
export const PROJECT_DELETE_TAG = 'PROJECT_DELETE_TAG';
export const PROJECT_ADD_TAG = 'PROJECT_ADD_TAG';

export const SET_API_FILTERS = 'SET_API_FILTERS';
export const FETCH_USER = 'FETCH_USER';
export const USER_PROJECT = 'USER_PROJECT';
export const USER_ERROR = 'USER_ERROR';

export const FETCH_SESSIONS = 'FETCH_SESSIONS';
export const SESSION_ERROR = 'SESSION_ERROR';
export const DELETE_SESSION = 'DELETE_SESSION';
export const REASSIGN_SESSION = 'REASSIGN_SESSION';
export const START_SESSION = 'START_SESSION';
export const FINISH_SESSION = 'FINISH_SESSION';

export const IMAGES_FETCH_ERROR = 'IMAGES_FETCH_ERROR';

export const MONITOR_STATE = 'MONITOR_STATE';
export const MONITOR_TOOLBAR_FILTERS = 'MONITOR_TOOLBAR_FILTERS';
export const API_FILTERS_ERROR = 'FILTERS_ERROR';

export const CONFIG_REPORTS = 'CONFIG_REPORTS';
export const CONFIG_REPORTS_ERROR = 'CONFIG_REPORTS_ERROR';
export const CONFIG_ALERTS = 'CONFIG_ALERTS';
export const CONFIG_ALERTS_ERROR = 'CONFIG_ALERTS_ERROR';
export const CONFIG_LABELS = 'CONFIG_LABELS';
export const CONFIG_LABELS_ERROR = 'CONFIG_LABELS_ERROR';
export const CONFIG_SHIFTS = 'CONFIG_SHIFTS';
export const CONFIG_SHIFTS_ERROR = 'CONFIG_SHIFTS_ERROR';
export const CONFIG_DEVICES = 'CONFIG_DEVICES';
export const CONFIG_DEVICES_ERROR = 'CONFIG_DEVICES_ERROR';

export const CREATE_PERSPECTIVE = 'CREATE_PERSPECTIVE';
export const UPDATE_PERSPECTIVE = 'UPDATE_PERSPECTIVE';

export const PIPELINE_LIST = 'PIPELINE_LIST';
export const PIPELINE_PROGRESS = 'PIPELINE_PROGRESS';
