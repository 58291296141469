import {Avatar, AvatarProps, IconButton, IconButtonProps} from '@mui/material';
import React from 'react';

interface CircleColorProps {
  /**
   * A valid MUI sx width/height value
   */
  circleSize: number | string;
  color?: string;
  children?: React.ReactNode;
}

export function CircleColorIcon({
  circleSize,
  color,
  sx,
  children,
  ...rest
}: CircleColorProps & Omit<AvatarProps, 'color'>): JSX.Element {
  return (
    <Avatar
      sx={[
        {cursor: 'inherit', width: circleSize, height: circleSize, background: color},
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children ?? ''}
    </Avatar>
  );
}

export function CircleColorIconButton({
  circleSize,
  color,
  sx,
  ...rest
}: CircleColorProps & Omit<IconButtonProps, 'color'>): JSX.Element {
  return (
    <IconButton
      data-testid={`CircleColorIconButton-${color}`}
      disableRipple={true}
      sx={[
        (theme) => ({
          padding: 0,
          width: circleSize,
          height: circleSize,
          borderRadius: `${circleSize}px`,
          background: color,
          border: '1px inset',
          borderColor: color?.toUpperCase() === '#FFFFFF' ? 'maddox.black20' : 'transparent',
          '&:hover, &:focus': {
            outline: '2px solid',
            outlineColor: theme.palette.primary.dark,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    />
  );
}
