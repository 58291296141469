import * as Sentry from '@sentry/nextjs';
import {useTranslation} from 'i18n';
import {useRouter} from 'next/router';
import {useEffect, useMemo} from 'react';

import {useModelsQuery} from '~api/models.queries';
import {replaceRoute, Route} from '~utils/routeUtil';
import {Model} from '~redux/types/models';
import {selectCurrentSession, selectLatestHumanAnnotationsForImageAtIndex} from '~redux/reducers/imageReducer';
import {useAppSelector} from '~redux/index';

import {showNotification} from '~components/common/Notification';
import {ToolbarDivider} from '~components/common/toolbar/ToolbarDivider';
import {ClassifyToolbarButton} from '~components/images/toolbar/classified-toolbar-button/ClassifyToolbarButton';
import {LiveSessionText} from '~components/images/toolbar/LiveSessionText';
import {useLabelAlias} from '~components/models/model.hooks';
import {useCurrentModel} from 'src/contexts/ModelContext';

/**
 * This file contains all components, hooks and methods related to the existing modes the image annotator and image overview can be in.
 * All changes regarding these modes should be made in this file.
 * This file serves as the single source of truth regarding all differences between the modes.
 */

export enum ImageAnnotatorMode {
  default = 'default',
  regularTeachSession = 'regularTeachSession',
  smartTeachSession = 'smartTeachSession',
  liveSession = 'liveSession',
  anomalyTeachSession = 'anomalyTeachSession',
}

export function useImageAnnotatorMode(): ImageAnnotatorMode {
  const router = useRouter();

  const isLive = router.pathname === Route.tasksLiveSession;
  const isSession = router.pathname === Route.tasksSession;

  const session = useAppSelector(selectCurrentSession);

  if (isLive) {
    return ImageAnnotatorMode.liveSession;
  } else if (isSession && session?.type === 'error') {
    return ImageAnnotatorMode.smartTeachSession;
  } else if (isSession && session?.type === 'image') {
    return ImageAnnotatorMode.regularTeachSession;
  } else {
    return ImageAnnotatorMode.default;
  }
}

export type ImageOverviewMode =
  | 'archive'
  | 'model-defect-book'
  | 'model-archive'
  | 'image-browser'
  | 'heatmap'
  | 'model-finetune';
export function useCurrentImageOverviewMode(): ImageOverviewMode | undefined {
  const pathname = useRouter().pathname;

  if (pathname.includes(Route.archive)) {
    return 'archive';
  } else if (pathname.includes(Route.modelSamples)) {
    return 'model-defect-book';
  } else if (pathname.includes(Route.modelAddSamples)) {
    return 'model-archive';
  } else if (pathname.includes(Route.modelFinetune)) {
    return 'image-browser';
  } else if (pathname.includes(Route.modelFinetuneImage)) {
    return 'model-finetune';
  }
  return undefined;
}

export const useTeachSessionPageTitle = ({fallback}: {fallback: string}) => {
  const {t} = useTranslation('review');
  const mode = useImageAnnotatorMode();
  const currentSession = useAppSelector(selectCurrentSession);
  const sessionLabel = useLabelAlias(currentSession?.meta?.label);

  let title: string;
  if (mode === ImageAnnotatorMode.liveSession) {
    title = t('live-session');
  } else if (mode === ImageAnnotatorMode.smartTeachSession && currentSession?.meta?.label) {
    title = t('checkAndMarkAllInstances', {value: sessionLabel});
  } else if (mode === ImageAnnotatorMode.regularTeachSession && currentSession?.description) {
    title = currentSession.description;
  } else {
    title = fallback;
  }

  return title;
};

export function useIsImageAnnotatedForCurrentImageMode(imageIndex: number): boolean {
  const annotatorMode = useImageAnnotatorMode();
  const options = useMemo(() => ({imageIndex, annotatorMode}), [imageIndex, annotatorMode]);
  const latestAnnotations = useAppSelector((state) => selectLatestHumanAnnotationsForImageAtIndex(state, options));
  return !!latestAnnotations && latestAnnotations.length > 0;
}

export function useSetCurrentModelForSession(sessionModelId: string | undefined | null) {
  const {t} = useTranslation('error');
  const modelsQuery = useModelsQuery();
  const imageAnnotatorMode = useImageAnnotatorMode();
  const {setCurrentModel} = useCurrentModel();

  useEffect(() => {
    if (!modelsQuery.data) {
      return;
    }

    // Set a current model if the annotator is in smart teach mode, so that only a single label can be used to annotate images
    if (imageAnnotatorMode === ImageAnnotatorMode.smartTeachSession && sessionModelId) {
      const models = modelsQuery.data;
      const activeModel = models?.find((model) => model.id === sessionModelId);

      // There should always be a model that includes the label of the smart teach session
      if (!activeModel || activeModel.labels.length === 0) {
        showNotification({severity: 'error', message: t('errorCouldNotFindModelForSmartTeachSession')});
        Sentry.captureException(
          new Error(`Could not find model for smart teach session with modelId ${sessionModelId}`),
        );
        replaceRoute(Route.tasks);
        return;
      }

      setCurrentModel(activeModel);
    }
  }, [imageAnnotatorMode, modelsQuery.data, sessionModelId, setCurrentModel, t]);
}

export function useSelectableModels(): Model[] {
  const imageAnnotatorMode = useImageAnnotatorMode();
  const imageOverviewMode = useCurrentImageOverviewMode();
  const modelsQuery = useModelsQuery();
  const {currentModel, setCurrentModel} = useCurrentModel();

  const selectableModels = useMemo((): Model[] => {
    // When the annotator is opened as part of a model page or when we're in a
    // smart teach session, the model select needs to be fixed to the current model,
    // i.e. users should NOT be able to select a model by themselves
    if (
      currentModel &&
      (imageOverviewMode === 'model-archive' ||
        imageOverviewMode === 'model-defect-book' ||
        imageAnnotatorMode === ImageAnnotatorMode.smartTeachSession)
    ) {
      return [currentModel];
    }
    return modelsQuery.data || [];
  }, [currentModel, imageAnnotatorMode, imageOverviewMode, modelsQuery.data]);

  useEffect(() => {
    // If only one model exists, set it as the current model
    if (selectableModels.length === 1 && !currentModel) {
      setCurrentModel(selectableModels[0]);
    }
  }, [currentModel, selectableModels, setCurrentModel]);

  return selectableModels;
}

export function ClassifyToolbarButtonWithImageMode(): JSX.Element | null {
  const mode = useImageAnnotatorMode();
  if (mode === ImageAnnotatorMode.smartTeachSession) {
    return null;
  }

  return <ClassifyToolbarButton />;
}

export function LiveSessionTextWithImageMode(): JSX.Element | null {
  const mode = useImageAnnotatorMode();

  if (mode !== ImageAnnotatorMode.liveSession) {
    return null;
  }

  return (
    <>
      <LiveSessionText />
      <ToolbarDivider />
    </>
  );
}
