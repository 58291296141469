import {Action, combineReducers, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {createWrapper} from 'next-redux-wrapper';
// eslint-disable-next-line no-restricted-imports
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';

import annotatorSlice, {annotatorActions} from '~redux/reducers/annotatorReducer';
import configsReducer, {reducerName as configsReducerName} from '~redux/reducers/configsReducer';
import filtersReducer, {reducerName as filtersReducerName} from '~redux/reducers/filtersReducer';
import globalReducer, {reducerName as globalReducerName} from '~redux/reducers/globalReducer';
import imageDataSlice, {imageDataActions} from '~redux/reducers/imageReducer';
import monitorReducer, {reducerName as monitorReducerName} from '~redux/reducers/monitorReducer';
import userReducer, {reducerName as userReducerName} from '~redux/reducers/userReducer';

const enableDevTools =
  process.env.NODE_ENV === 'development' ||
  ['development', 'staging', 'preview'].includes(process.env.DEPLOYMENT_ENV || '');

// Create a singleton store so that we can use the same store instance outside of React components
// by importing it directly from this file.
export const store = configureStore({
  reducer: combineReducers({
    [userReducerName]: userReducer,
    [monitorReducerName]: monitorReducer,
    [configsReducerName]: configsReducer,
    [globalReducerName]: globalReducer,
    [filtersReducerName]: filtersReducer,
    [imageDataSlice.name]: imageDataSlice.reducer,
    [annotatorSlice.name]: annotatorSlice.reducer,
  }),
  devTools: enableDevTools && {
    actionCreators: {
      ...imageDataActions,
      ...annotatorActions,
    },
  },
});

export type ReduxStore = typeof store;
export type ReduxState = ReturnType<ReduxStore['getState']>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkAction<ReturnType = void> = ThunkAction<ReturnType, ReduxState, unknown, Action>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector;

const makeStore = () => store;
export const reduxWrapper = createWrapper<ReduxStore>(makeStore);
