import {Dialog, DialogTitle, LinearProgress} from '@mui/material';
import {Trans, useTranslation} from 'i18n';

import {rainbow} from '~constants/theme';

interface LoadingOverlayProps {
  show: boolean;
  loadingMessage?: string;
}

export function LoadingOverlay({show, loadingMessage}: LoadingOverlayProps) {
  const {t} = useTranslation('common');

  if (!show) {
    return null;
  }

  return (
    <Dialog open>
      <DialogTitle sx={{fontSize: '20px', paddingX: 3, paddingY: 2}}>
        <Trans defaults={loadingMessage || t('contentIsLoading')} />
      </DialogTitle>
      <LinearProgress style={{backgroundImage: rainbow}} />
    </Dialog>
  );
}
