// import type {} from '@mui/lab/themeAugmentation';
import {KeyboardArrowDown} from '@mui/icons-material';
import {alpha} from '@mui/material';
import {common, cyan, grey, indigo} from '@mui/material/colors';
import {createTheme} from '@mui/material/styles';
import {createStyled, SxProps as MUISxProps, Theme as MUITheme} from '@mui/system';

import {CheckboxCheckedIcon, CheckboxIcon, CheckboxIndeterminateIcon} from 'src/icons';

// REFACTOR use MUI theme for all colors
export const greyExtraLight = grey[100];
export const greyLight = grey[200];
export const greyDark = grey[700];
export const white = common.white;

export const raisedUIShadow = '0px 5px 10px rgba(0, 0, 0, 0.05)';

interface MaddoxColors {
  accent: string;
  hover: string;
  globalblack: string;
  globalwhite: string;
  black80: string;
  black60: string;
  black40: string;
  black20: string;
  black10: string;
  black5: string;
  black2: string;
  diffBackgroundRed: string;
  diffBackgroundGreen: string;
  dotBackground: string;
  dotBackgroundDot: string;
  userComparisonYellow: string;
  userComparisonMagenta: string;
  classOk: string;
  classEmpty: string;
  unlabeled: string;
  overkill: string;
  escape: string;
  similarityLabel: string;
  milkyWhite: string;
  similaritySearchGrid: string;
  similaritySearchHover: string;
  successBackground: string;
}

// module augmentation is needed to add custom variables to the theme in typescript
// see https://mui.com/customization/palette/#adding-new-colors
declare module '@mui/material/styles' {
  interface Palette {
    maddox: MaddoxColors;
  }

  interface PaletteOptions {
    maddox: MaddoxColors;
  }

  interface TypographyVariants {
    table: React.CSSProperties;
    chips: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    table?: React.CSSProperties;
    chips?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    table: true;
    chips: true;
  }
}

export const MuiTabsStyles = (theme: MUITheme) => ({
  paddingX: theme.spacing(2),
  backgroundColor: theme.palette.maddox.globalwhite,
  boxShadow: `inset 0 -1px 0 0 ${theme.palette.maddox.black20}`,
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
});

export const theme = createTheme({
  typography: {
    fontFamily: '"Inter Var", Roboto, "Helvetica Neue", Helvetica, sans-serif',
    fontSize: 14,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontSize: 24,
      lineHeight: '30px',
      fontWeight: 500,
    },
    h2: {
      fontSize: 20,
      lineHeight: '26px',
      fontWeight: 600,
    },
    h3: {
      fontSize: 18,
      lineHeight: '22px',
      fontWeight: 500,
    },
    h4: {
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 500,
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '16px',
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      lineHeight: '22px',
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 400,
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '22px',
    },
    table: {
      fontSize: 11,
      fontWeight: 500,
      lineHeight: '14px',
    },
    chips: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '24px',
    },
  },
  shape: {
    borderRadius: 4,
  },
  palette: {
    primary: {
      main: '#461177',
      light: '#7768D7',
      dark: '#240442',
    },
    secondary: {
      main: '#5829A7',
      light: '#858AE3',
      dark: '#250775',
    },
    error: {
      main: '#E8002A',
      light: '#EF5C76',
      dark: '#B50021',
    },
    warning: {
      main: '#DF6825',
      light: '#F5922F',
      dark: '#B53D10',
    },
    info: {
      main: '#5388D8',
      light: '#8EB5F0',
      dark: '#306BC3',
    },
    success: {
      main: '#2FC97B',
      light: '#48CD8A',
      dark: '#0B8F4C',
    },
    background: {
      default: '#F2F2F2',
    },
    text: {
      primary: '#000', // maddox.globalblack
      secondary: '#333333', // maddox.black80
      disabled: '#A7A7A7', // maddox.black40
    },
    maddox: {
      accent: '#09D3FD',
      hover: '#F3F3FC',
      globalblack: '#000',
      globalwhite: '#fff',
      black80: '#333333',
      black60: '#565656',
      black40: '#A7A7A7',
      black20: '#D8D8D8',
      black10: '#EAEAEA',
      black5: 'rgba(0,0,0,0.05)',
      black2: 'rgba(0,0,0,0.02)',
      diffBackgroundRed: 'rgba(255, 99, 97, 0.1)',
      diffBackgroundGreen: 'rgba(106, 226, 166, 0.1)',
      dotBackground: '#e6e6e6',
      dotBackgroundDot: '#b7b7b7',
      userComparisonYellow: '#FFFF00',
      userComparisonMagenta: '#FF00FF',
      classOk: '#00d581',
      classEmpty: '#b0b0b0',
      unlabeled: '#b0b0b0',
      overkill: '#D2D2FA',
      escape: '#A092F5',
      similarityLabel: '#E43CFF',
      milkyWhite: 'rgba(255, 255, 255, 0.5)',
      similaritySearchGrid: '#efefef',
      similaritySearchHover: '#B064FF',
      successBackground: '#F3FDF7',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      /* using Inter's variable font, see https://web.dev/variable-fonts/#variable-fonts-in-css */
      @font-face {
        font-family: 'Inter Var';
        font-weight: 100 900;
        font-style: normal;
        font-display: swap;
        src:
          url(/fonts/Inter.var.woff2) format('woff2 supports variations'),
          url(/fonts/Inter.var.woff2) format('woff2-variations');
      }

      /* custom CSS resets & improvements */

      html,
      body,
      #__next {
        height: 100%;
        position: relative;
      }

      body {
        overscroll-behavior: none;
      }
      
      img {
        max-width: 100%;
        display: block;
      }
      `,
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          '.MuiAvatar-root': {
            marginLeft: '-6px',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            '&.MuiLoadingButton-root.MuiLoadingButton-loading.Mui-disabled': {
              color: 'transparent',
            },
            '.MuiLoadingButton-loadingIndicator': {
              color: 'maddox.globalwhite',
            },
          }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            typography: 'button',
            minWidth: '80px',
            paddingY: 1,
            paddingX: 2,
            flexShrink: 0,
            boxShadow: 'none',
            // add a transparent border to keep width/height consistent between non-bordered and bordered buttons
            border: '1px solid transparent',
            borderRadius: 1,
            whiteSpace: 'nowrap',
            '&:hover, &:focus, &:active': {
              boxShadow: 'none',
            },

            '&.Mui-disabled': {
              color: 'maddox.black40',
            },

            // PrimaryButton.tsx
            '&.MuiButton-containedPrimary': {
              color: 'maddox.globalwhite',
              backgroundColor: 'primary.light',
              '&:hover, &:active': {
                backgroundColor: 'primary.main',
              },
              '&:focus': {
                backgroundColor: 'primary.dark',
              },
              '&.Mui-disabled': {
                color: 'maddox.globalwhite',
                backgroundColor: 'maddox.black40',
              },
            },

            // SecondaryButton.tsx
            '&.MuiButton-outlinedSecondary': {
              color: 'primary.light',
              backgroundColor: 'maddox.globalwhite',
              borderColor: 'primary.light',
              '&:hover, &:active': {
                backgroundColor: 'maddox.hover',
                color: 'primary.main',
                borderColor: 'primary.main',
              },
              '&:focus': {
                color: 'maddox.globalwhite',
                backgroundColor: 'primary.dark',
                borderColor: 'primary.dark',
              },
              '&.Mui-disabled': {
                borderColor: 'maddox.black40',
                color: 'maddox.black40',
                backgroundColor: 'maddox.globalwhite',
              },
            },
          }),
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        exclusive: true,
        size: 'small',
      },
      styleOverrides: {
        grouped: ({theme}) =>
          theme.unstable_sx({
            typography: 'body2',
            textTransform: 'none',
            lineHeight: '24px',
            paddingY: 0.25,
            paddingX: 0.75,
            color: 'maddox.globalblack',
            backgroundColor: 'maddox.black20',
            borderColor: 'transparent',
            '&.Mui-selected': {
              backgroundColor: 'primary.light',
              color: 'maddox.globalwhite',
            },
            '&:hover, &.Mui-selected:hover': {
              backgroundColor: 'maddox.hover',
              color: 'primary.light',
              borderColor: 'maddox.black20',
              '&:not(:first-of-type)': {
                borderLeftColor: 'transparent',
              },
            },
            '&.Mui-disabled': {
              backgroundColor: 'maddox.black20',
              color: 'maddox.black40',
              borderColor: 'transparent',
            },
          }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            '&.Mui-disabled': {
              color: 'maddox.black40',
            },
          }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({theme}) => ({
          borderColor: theme.palette.maddox.black20,
        }),
        head: ({theme}) => ({
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.maddox.black60,
          backgroundColor: theme.palette.maddox.globalwhite,
        }),
      },
    },
    MuiTabs: {
      // remember to change styles in ProjectSettings if you change this as it copies this styles
      styleOverrides: {
        root: ({theme}) => MuiTabsStyles(theme),
        indicator: {
          height: 1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            typography: 'body2',
            textTransform: 'capitalize',
            paddingY: 3,
            marginX: 2,
            minWidth: 0,
            paddingX: 0,
            '&.Mui-selected': {
              typography: 'h5',
            },
          }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: ({theme}) =>
          theme.unstable_sx({
            '&.MuiMenu-list': {
              paddingY: 0,
            },
          }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            typography: 'body2',
            '&:hover, &:focus-visible, &:active, &.Mui-selected, &.Mui-selected:hover, &.Mui-focusVisible, &.Mui-focusVisible:hover':
              {
                backgroundColor: 'maddox.hover',
              },
            '&.Mui-selected, &.Mui-selected.Mui-focusVisible': {
              color: 'secondary.main',
              backgroundColor: alpha(theme.palette.secondary.light, 0.1),
            },
            '& > .MuiCheckbox-root': {
              // leads to equal horizontal spacing of the MuiMenuItem
              marginLeft: -1,
            },
          }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({theme}) => ({
          '&:hover, &:focus-visible, &:active, &.Mui-selected, &.Mui-selected:hover, &.Mui-focusVisible, &.Mui-focusVisible:hover':
            {
              backgroundColor: theme.palette.maddox.hover,
            },
          '&.Mui-selected, &.Mui-selected.Mui-focusVisible': {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.maddox.hover,
          },
          '& > .MuiCheckbox-root': {
            // leads to equal horizontal spacing of the MuiMenuItem
            marginLeft: theme.spacing(-1),
          },
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: () => ({
          color: 'currentColor',
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
        indeterminateIcon: <CheckboxIndeterminateIcon />,
      },
      styleOverrides: {
        root: ({theme, ownerState}) =>
          theme.unstable_sx({
            color: 'maddox.black40',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:hover svg, &:focus svg': {
              color: 'primary.main',
              fill: !(ownerState.checked || ownerState.indeterminate) ? theme.palette.maddox.hover : 'none',
            },
          }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: raisedUIShadow,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: ({theme}) => ({
          backgroundColor: '#ECF8F2',
          color: theme.palette.maddox.black80,
        }),
        filledError: ({theme}) => ({
          backgroundColor: '#FDEDED',
          color: theme.palette.maddox.black80,
        }),
        filledWarning: ({theme}) => ({
          backgroundColor: '#FFF4E5',
          color: theme.palette.maddox.black80,
        }),
        filledInfo: ({theme}) => ({
          backgroundColor: '#F3F3FC',
          color: theme.palette.maddox.black80,
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            typography: 'body1',
          }),
        input: ({theme}) =>
          theme.unstable_sx({
            paddingX: 2,
            paddingY: 1,
            height: 'auto',
          }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: ({theme}) =>
          theme.unstable_sx({
            typography: 'body1',
            paddingX: 2,
            paddingY: 1,
          }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: ({theme}) =>
          theme.unstable_sx({
            '&:focus-visible': {
              backgroundColor: 'maddox.hover',
              borderRadius: 1,
            },
            '&.Mui-disabled': {
              color: 'maddox.black40',
            },
          }),
      },
      defaultProps: {
        IconComponent: KeyboardArrowDown,
        SelectDisplayProps: {
          // HACK: this overrides the "combobox" role that MUI Select sets by default since v5.14.12 which breaks our tests
          // here we reset it to the previous "button" role that was used before
          // see https://github.com/mui/material-ui/releases/tag/v5.14.12
          // and https://github.com/mui/material-ui/pull/38785
          // and https://github.com/mui/material-ui/issues/39383
          role: 'button',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            marginLeft: '1px',
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 500,
            marginTop: 1,
          }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            typography: 'h1',
            color: 'maddox.black80',
            paddingX: 5,
            paddingTop: 4,
            paddingBottom: 3,
          }),
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true,
      },
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({paddingX: 5, paddingTop: 3, paddingBottom: 2, '&:last-child': {paddingBottom: 3}}),
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: ({theme}) => theme.unstable_sx({color: 'maddox.globalblack'}),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            padding: 2,
          }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            typography: 'body2',
            paddingBottom: 1,
            color: 'maddox.black80',
          }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ownerState}) =>
          ownerState.textAlign === 'left' &&
          ownerState.orientation !== 'vertical' && {
            color: theme.palette.maddox.black60,
            '&::before': {
              width: 0,
            },
            '&::after': {
              width: '100%',
            },
          },
        wrapper: ({ownerState}) =>
          ownerState.textAlign === 'left' &&
          ownerState.orientation !== 'vertical' && {
            paddingLeft: 0,
            paddingRight: 20,
          },
      },
    },
    MuiSlider: {
      styleOverrides: {
        colorPrimary: ({theme}) =>
          theme.unstable_sx({
            color: 'primary.light',
          }),
        thumb: ({theme}) => {
          const hoverColor = alpha(theme.palette.primary.light, 0.2);
          return theme.unstable_sx({
            backgroundColor: 'maddox.globalwhite',
            border: '3px solid',
            borderColor: 'primary.light',
            '&::before': {
              boxShadow: 0,
            },
            '&:hover, &.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 5px ${hoverColor}`,
            },
            '&.Mui-active': {
              boxShadow: `0px 0px 0px 8px ${hoverColor}`,
            },
          });
        },
        track: {
          height: 4,
          border: 0,
        },
        rail: ({theme}) =>
          theme.unstable_sx({
            backgroundColor: 'maddox.black20',
          }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: ({theme}) =>
          theme.unstable_sx({
            '.MuiTooltip-tooltip': {
              typography: 'body2',
              maxWidth: '300px',
              whiteSpace: 'pre-line',
              backgroundColor: 'maddox.globalblack',
            },
            '.MuiTooltip-arrow::before': {
              color: 'maddox.globalblack',
            },
          }),
      },
    },
  },
});

export const styled = createStyled({defaultTheme: theme});
export type Theme = typeof theme;
export type SxProps = MUISxProps<Theme>;

export const rainbow = `linear-gradient(95deg,${cyan.A100} 0%,${indigo.A100} 50%,${theme.palette.primary.main} 100%)`;
export const sitesTemplatePadding = theme.spacing(3);
