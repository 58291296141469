import {Divider} from '@mui/material';

export function ToolbarDivider(): JSX.Element {
  return (
    <Divider
      sx={(theme) => ({
        height: theme.spacing(2.5),
        display: 'block',
        verticalAlign: 'middle',
        borderRightColor: 'maddox.black20',
        marginX: 0.5,
      })}
      component='span'
      orientation='vertical'
    />
  );
}
