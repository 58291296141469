import {CSSInterpolation, Theme} from '@mui/material';

import {
  CheckboxLabelItem,
  CheckboxState,
  ItemState,
} from '~components/common/toolbar/selects/class-select/NestedClassListParent';

/**
 * Common props for all toolbar elements. Every toolbar element
 * should extend this interface and implement its properties.
 */
export interface ToolbarElementProps {
  /**
   * Add a <ToolbarDivider /> after this element.
   */
  hasDivider?: boolean;
  /**
   * Show a tooltip when hovering over the toolbar element.
   */
  tooltip?: string;
  /**
   * If true, the toolbar element is disabled.
   */
  disabled?: boolean;
}

export const getCommonToolbarElementStyles = (theme: Theme) => ({
  margin: theme.spacing(1, 0.5),
  ...theme.typography.body2,
  borderRadius: theme.shape.borderRadius,
  border: '1px solid transparent',
  height: theme.spacing(4),
});

export const getCommonToolbarButtonStyles: (props: {theme: Theme}) => CSSInterpolation = ({theme}) => ({
  ...getCommonToolbarElementStyles(theme),
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 1),
  transition: 'none',
  fontWeight: 'unset',
  minWidth: 'unset',
  ':has(.MuiButton-startIcon)': {
    paddingLeft: theme.spacing(0),
  },
  '.MuiButton-startIcon': {
    marginLeft: theme.spacing(0.5),
  },
  '&:hover': {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.maddox.hover,
  },
  '&:active': {
    color: theme.palette.secondary.dark,
  },
  '&:focus-visible': {
    border: `1px solid ${theme.palette.secondary.light}`,
    backgroundColor: theme.palette.maddox.hover,
  },
});

export const getToolbarElementHoverStyles = (theme: Theme) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.maddox.hover,
  '&.Mui-disabled': {
    color: 'currentColor',
    backgroundColor: 'transparent',
  },
});

/**
 * Immutabliy updates the given "old" item state and toggles the checked/unchecked state of the given item IDs.
 *
 * @param oldState The old item states that will be cloned to create the new state
 * @param items The current list of CheckboxLabelItems
 * @param idsToUpdate The list of item IDs that should be toggled between checked/unchecked, depending on the state defined in oldState.
 * @returns The new item states
 */
export function updateItemStates(
  oldState: ItemState[],
  items: CheckboxLabelItem[],
  idsToUpdate: string[],
): ItemState[] {
  // clone old state
  const newState = oldState.map((i) => ({...i}));

  function getItemState(id: string) {
    return newState.find((item) => item.id === id)!.state;
  }

  function updateParent(id: string) {
    const currentItem = items.find((item) => item.id === id)!;
    const parent = items.find((item) => item.id === currentItem.parentId);
    if (!parent) {
      return;
    }

    // Get the state of all siblings of the current item
    const siblingStates = items
      .filter((item) => item.parentId === parent.id && !parent.isLeaf)
      .map((sibling) => getItemState(sibling.id));

    // Use sibling state to determine the new state of the current item's parent
    const newStateParent = newState.find((item) => item.id === parent.id)!;
    if (siblingStates.every((state) => state === CheckboxState.CHECKED)) {
      newStateParent.state = CheckboxState.CHECKED;
    } else if (siblingStates.every((state) => state === CheckboxState.UNCHECKED)) {
      newStateParent.state = CheckboxState.UNCHECKED;
    } else {
      newStateParent.state = CheckboxState.INDETERMINATE;
    }

    // Recurse for every parent
    updateParent(parent.id);
  }

  function updateItemState(id: string, updatedState: CheckboxState) {
    // Set the current item state
    newState.find((item) => item.id === id)!.state = updatedState;

    // If the current item has children, update their state recursively
    items.filter((item) => item.parentId === id).forEach((childItem) => updateItemState(childItem.id, updatedState));

    // Update the state of the current item's parents
    updateParent(id);
  }

  idsToUpdate.forEach((id) => {
    const oldItem = oldState.find((item) => item.id === id);
    if (!oldItem) {
      return;
    }
    const newItemState = oldItem.state === CheckboxState.CHECKED ? CheckboxState.UNCHECKED : CheckboxState.CHECKED;
    updateItemState(id, newItemState);
  });

  return newState;
}
