import CloseIcon from '@mui/icons-material/Close';
import {Dialog, DialogProps, IconButton} from '@mui/material';

export interface ClosableDialogProps extends Omit<DialogProps, 'onClose'> {
  fixedToTop?: boolean;
  hasTabs?: boolean;
  hasCloseButton?: boolean;
  onClose?: () => void;
}

export function ClosableDialog({
  fixedToTop,
  hasTabs,
  hasCloseButton = true,
  onClose,
  children,
  open,
  sx,
  ...rest
}: ClosableDialogProps): JSX.Element {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={[
        fixedToTop && {
          '& .MuiDialog-container': {
            paddingY: 5,
            alignItems: 'flex-start',
          },
        },
        hasTabs && {
          '& .MuiTabs-root': {
            paddingX: 3,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {hasCloseButton && (
        <IconButton
          data-testid='dialog-close-button'
          size='small'
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            color: 'inherit',
            zIndex: 1,
          })}
        >
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </Dialog>
  );
}
